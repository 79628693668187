@font-face {
    font-family: 'Söhne Buch';
    src: url('fonts/soehne/soehne-buch.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback
}

@font-face {
    font-family: 'Söhne Halbfett';
    src: url('fonts/soehne/soehne-halbfett.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: fallback
}

@font-face {
    font-family: 'prestigeelitestd-bd';
    src: url('fonts/prestige/f623b11260a5a5bb68c90a1be50eeea4.woff2') format('woff2'),
         url('fonts/prestige/f623b11260a5a5bb68c90a1be50eeea4.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback
}

