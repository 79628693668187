/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=swiper (=slider)

*/

// flags
$swiper-default-pagination-hidden-sm-max: false;

// overlay
$swiper-default-overlay-styles-enabled: true;
$swiper-default-overlay-gradient-height: 60%; // percent value recommended to keep it responsive

// background
$swiper-default-bg: $color-default-secondary;

// pagination
$swiper-default-pagination-position-top-xs: $grid-gutter-width/4;
$swiper-default-pagination-position-bottom-xs: auto;
$swiper-default-pagination-position-top-md: auto;
$swiper-default-pagination-position-bottom-md: $grid-gutter-width/4;

// bullets
$swiper-default-bullet-size: 15px;
$swiper-default-bullet-border-width: 2px;
$swiper-default-bullet-border-style: solid;
$swiper-default-bullet-border-color: $color-default-secondary;
$swiper-default-bullet-border-color-active: $color-default-secondary;
$swiper-default-bullet-bg: transparent;
$swiper-default-bullet-bg-active: $color-default-secondary;

// arrows
$swiper-default-arrows-transition-property: all;
$swiper-default-arrows-transition-duration: $link-transition-duration;
$swiper-default-arrows-transition-timing-function: $link-transition-duration;

$swiper-default-arrows-icon-previous: '\e90b';
$swiper-default-arrows-icon-next: '\e90d';
$swiper-default-arrows-icon-size-xs: 1.2em;
$swiper-default-arrows-icon-size-md: 1.2em;
$swiper-default-arrows-icon-size-xl: 1.2em;
$swiper-default-arrows-opacity: 1;
$swiper-default-arrows-opacity-hover: 0.8;
$swiper-default-arrows-color: $body-color;
$swiper-default-arrows-color-hover: $brand-gray-40;
$swiper-default-arrows-button-bg-xs: transparent;
$swiper-default-arrows-button-width-xs: 50px;
$swiper-default-arrows-button-height-xs: 50px;
$swiper-default-arrows-button-width-md: 50px;
$swiper-default-arrows-button-height-md: 50px;
$swiper-default-arrows-button-width-xl: 50px;
$swiper-default-arrows-button-height-xl: 50px;

// border & content color
$swiper-border-color: $color-line-secondary;
$swiper-content-color: $color-muted-primary;
