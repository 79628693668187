/* Layout */


// HTML/Body

* {
    box-sizing: border-box;

    // removes tap highlight on touch devices
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;

    &::before,
    &::after {
        box-sizing: border-box;
    }
}

html {
    position: relative;
    height: 100%;
    min-height: 100%;

    // Default browser setting of 16px. Meaning 1rem ~= 16px
    font-size: 100%;

    // IE and Edge Scrollbar Fixes
    -ms-overflow-style: -ms-autohiding-scrollbar;

    // momentum-based scrolling
    -webkit-overflow-scrolling: touch;

    &.scrolling-disabled {
        overflow: hidden;
    }

    &.tooltip--active {
        position: fixed;

        @include breakpoint(large) {
            & {
                margin-right: var(--scroll-bar-width);
            }

            .nav-toggle__container {
                right: var(--scroll-bar-width);
            }
        }
    }

    //&.overlay-menu-enabled {
    //    overflow-y: hidden;
    //    position: fixed;
    //
    //    @include breakpoint(large) {
    //        overflow-y: hidden;
    //        position: relative;
    //    }
    //}

    &.offcanvas--open, &.overlay-menu-enabled, &.softpage-visible {
        overflow-y: hidden;
        overflow-x: hidden;


        @include breakpoint(large) {
            & {
                margin-right: var(--scroll-bar-width);
            }

            .nav-toggle__container {
                right: var(--scroll-bar-width);
            }
        }
    }

    @include breakpoint(small only) {
        &.overlay-menu-enabled {
            position: fixed;
        }
    }
}

body {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: $body-background-color;
    color: $body-color;

    @include font-default($font-cropping-disabled: true);

    @include print() {
        color: $body-color-print;
    }

    &.overlay--open, .tooltip--active & {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $brand-black;
            opacity: 0.45;
            z-index: z('filter-overlay-bg');
        }

        @include breakpoint(medium down) {
            position: fixed;
        }
    }
}

.site-content {
    position: relative;
    flex: 1 0 auto;
    z-index: z('site-content');
    overflow: hidden;

    // Fix overflow crop of trailing text
    padding-bottom: 0.4em;
    margin-bottom: -0.4em;
    pointer-events: all;

    .tooltip--active & {
        z-index: auto;
    }
}

/* Make sure embedded media fit their containers */
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

canvas,
video {
    max-width: 100%;
    height: auto;
}

audio {
    max-width: 100%;
}

.contact-item__container {
    width: fit-content;
}

.contact-item__number {
    display: flex;
    justify-content: space-between;
}
