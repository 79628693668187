


p strong {
    @include font-family-bold();
    @extend .text--tighter;
    display: inline-block;
}

em {
    @include font-family-italic();
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    font-size: inherit;
    color: unset;
    margin: 0;
    padding: 0;
    hyphens: manual;
}

p, .p {
    // reset any margins, they are applied through the parent .text-container or on the spot
    margin: 0;

    @include font-default();

    &.small {
        @include font-small();
    }

    &.lead {
        @include font-lead();
    }

}

a[href^='tel'] {
    white-space: nowrap;
}

h1,
.h1 {
    //color: $h1-color;

    &,
    strong,
    em {
        @include font-h1();
    }
}

h2,
.h2 {
    //color: $h2-color;

    &,
    strong,
    em {
        @include font-h2();
    }
}

h3,
.h3 {
    //color: $h3-color;

    &,
    strong,
    em {
        @include font-h3();
    }
}

h4,
.h4 {
    //color: $h4-color;

    &,
    strong,
    em {
        @include font-h4();
    }
}

h5,
.h5 {
    //color: $h5-color;

    &,
    strong,
    em {
        @include font-h5();
    }
}

h6,
.h6 {
    //color: $h6-color;

    &,
    strong,
    em {
        @include font-h6();
    }
}

.text-container,
.editor-body {
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Spacing between elements

    */

    p,
    p,
    .p,
    ul,
    ol,
    table,
    figure {
        padding: 0.001em; // Fix font crop

        @include fluid-size(
            (
                min: $text-container-element-default-margin-bottom-min,
                max: $text-container-element-default-margin-bottom-max,
            ),
            margin-bottom
        );
        @include fluid-size(
            (
                min: $text-container-element-default-margin-top-min,
                max: $text-container-element-default-margin-top-max,
            ),
            margin-top
        );
    }

    a:not(.button) {
        padding: 0.001em; // Fix font crop
    }

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Paragraphs

    */

    .text--lead,
    .text--lead-2 {
        @include spacings-size('y-scale-5', margin-top);
        @include spacings-size('y-scale-5', margin-bottom);
    }

    .text--small,
    .text--small-2,
    .text--small-3,
    .text--small-4 {
        @include spacings-size('y-scale-8', margin-top);
        @include spacings-size('y-scale-8', margin-bottom);
    }

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Lists

    */

    ul,
    ol {
        padding: 0;
        @include font-default-tighter();
        @include spacings-size('y-equal-3', margin);

        li {
            list-style: none;
            @include spacings-size($list-item-spacing, padding-bottom);

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    ul {
        li {
            position: relative;
            padding-left: $unordered-list-item-padding;

            &::before {
                content: '';
                position: absolute;
                transform: translate(0, -50%);
                left: $unordered-list-item-icon-spacing-left;
                top: $unordered-list-item-icon-spacing-top;
                width: $unordered-list-item-icon-size;
                height: $unordered-list-item-icon-size;
                border-radius: $unordered-list-item-icon-border-radius;
                background-color: $unordered-list-item-icon-color;
            }
        }
    }

    ol {
        counter-reset: custom-counter;

        li {
            display: flex;
            padding-left: $ordered-list-item-padding;

            &::before {
                content: counter(custom-counter) '.';
                counter-increment: custom-counter;
                margin-right: $ordered-list-item-icon-spacing-left;
            }
        }
    }


    .link--default {
        white-space: normal;
    }

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Headings

    */

    h1,
    .h1 {
        @include spacings-size('y-scale-3', margin-top);
        @include spacings-size('y-scale-4', margin-bottom);

        a {
            &.text {
                @include font-h1();
            }
        }
    }

    h2,
    .h2 {
        @include spacings-size('y-scale-3', margin-top);
        @include spacings-size('y-scale-5', margin-bottom);

        a {
            &.text {
                @include font-h2();
            }
        }
    }

    h3,
    .h3 {
        @include spacings-size('y-scale-3', margin-top);
        @include spacings-size('y-scale-5', margin-bottom);
    }

    h4,
    .h4 {
        @include spacings-size('y-scale-3', margin-top);
        @include spacings-size('y-scale-5', margin-bottom);
    }

    h5,
    .h5 {
        @include spacings-size('y-scale-3', margin-top);
        @include spacings-size('y-scale-5', margin-bottom);
    }

    h6,
    .h6 {
        @include spacings-size('y-scale-3', margin-top);
        @include spacings-size('y-scale-5', margin-bottom);
    }

    ::selection {
        .plugin--inverted & {
            background: $white;
            color: $selection-bg-color;
        }
    }

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    First/Last element, forcefully remove unnecessary spacing

    Hint: Should you need "that" extra spacing for some reason, then the .text-container
    is not the right parent for your case.

    */

    & > *:first-child {
        margin-top: 0;
    }
    & > *:last-child {
        margin-bottom: 0;
    }

   .inverted-colors & {
        color: $body-inverted-color;
    }
}

.text-container > p:last-child {
    &:after {
        //margin-bottom: 0 !important;
    }
}

a {
    color: inherit;
    text-decoration: $link-decoration;

    &:hover,
    &:focus {
        text-decoration: $link-decoration-hover;
    }
}

::selection {
    background: rgba(137, 127, 108, 0.3);

    .plugin--inverted & {
        background: $white;
        color: $selection-bg-color;
    }
}

img::selection {
    background: transparent;
    color: inherit;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0.001em; // Fix font crop

    .hero--inverted & {
        @include spacings-size(y-equal-4, margin-top);
    }

}

p {
    .hero--inverted & {
        margin-bottom: 0;
    }
}

