// spacing between list items
$button-link-container-item-spacing-xs: 1em;
$button-link-container-item-spacing-md: 1em;
$button-link-container-item-spacing-xl: 1em;

.button-link-plugin {
    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-top: -$button-link-container-item-spacing-xs;

        @include media-breakpoint-up(md) {
            margin-top: -$button-link-container-item-spacing-md;
        }

        @include media-breakpoint-up(xl) {
            margin-top: -$button-link-container-item-spacing-xl;
        }
    }

    &__list-item {
        display: inline-block;
        margin-top: $button-link-container-item-spacing-xs;
        margin-right: $button-link-container-item-spacing-xs;

        @include media-breakpoint-up(md) {
            margin-top: $button-link-container-item-spacing-md;
            margin-right: $button-link-container-item-spacing-md;
        }

        @include media-breakpoint-up(xl) {
            margin-top: $button-link-container-item-spacing-xl;
            margin-right: $button-link-container-item-spacing-xl;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        &.text {
            .btn__text--mask {
                display: none !important;
                overflow: hidden !important;
            }
        }
    }

    &.unstyled-links {
        a,
        a.text {
            &,
            &:hover,
            &:focus,
            &:active {
                .link-text {
                    box-shadow: none;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }

}
