$bs-dropdown-toggle-color: $color-default-primary !default;
$bs-dropdown-toggle-border-color-hover: $color-line-secondary !default;
$bs-dropdown-toggle-color-hover: $color-default-primary !default;
$bs-dropdown-toggle-open-color-hover: $color-default-primary !default;
$bs-dropdown-toggle-open-border-color-hover: $color-line-secondary !default;
$bs-dropdown-toggle-open-color-before: $color-default-primary !default;
$dropdown-menu-list-item-color: $color-default-primary !default;
$dropdown-menu-list-item-color-hover: $color-default-primary !default;
$dropdown-menu-selected-link-color-hover: $color-default-primary !default;
$dropdown-menu-selected-link-color: $color-default-primary !default;
$filter-container-border-color: $color-line-secondary !default;
$filter-container-label-control-color: $color-line-primary !default;

.bootstrap-select {
    > .dropdown-menu {
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05),
        0 2px 20px rgba(0, 0, 0, 0.05);
        max-height: to-rem(700px);
        min-width: 0 !important;
        width: to-rem(210px);
        margin-top: to-rem(8px);
        padding: 0;
        border: 0;

        a {
            @include spacings-size('y-equal-6', 'padding-top');
            @include spacings-size('y-equal-6', 'padding-bottom');

            padding-left: to-rem(20px);
            padding-right: to-rem(20px);
        }

        li:first-child a {
            @include spacings-size('y-equal-3', 'padding-top');
        }

        li:last-child a {
            @include spacings-size('y-equal-3', 'padding-bottom');
        }

        .selected {
            a {
                background-color: transparent;
                color: $dropdown-menu-selected-link-color;

                &:hover,
                &:active,
                &:focus {
                    color: $dropdown-menu-selected-link-color-hover;
                }
            }

            .text {
                text-decoration: none;
                font-weight: 700;
                color: inherit;
            }
        }
    }

    > .dropdown-toggle {
        font-size: 1rem;
        min-width: unset;
        width: to-rem(210px);
        padding-top: to-rem(4px); // has to be same as label, in case there is one
        padding-bottom: to-rem(4px); // has to be same as label, in case there is one
        padding-right: to-rem(18px);
        padding-left: 0;
        border: 0;

        &::before {
            content: '';
            right: 0;
            width: to-rem(10px);
            height: to-rem(5px);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='5'%3E%3Cpath fill='%23000' d='M10 0L5 5 0 0z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }

        &,
        &:hover,
        &:active,
        &:focus {
            background-color: transparent !important;
            color: $bs-dropdown-toggle-color !important;
        }

        &:hover,
        &:active {
            color: $bs-dropdown-toggle-color-hover !important;

            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='5'%3E%3Cpath fill='%23000' d='M10 0L5 5 0 0z'/%3E%3C/svg%3E");
            }
        }

        @include media-breakpoint-down(xs) {
            margin-left: 0 !important;
        }
    }


}

.dropdown-menu {
    > li > a {
        @include font-small-2();

        color: $dropdown-menu-list-item-color;
        transition: all 150ms ease-in-out;

        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
            color: $dropdown-menu-list-item-color-hover;
        }
    }
}

.filter-container {

    .dropdown-filter__list {
        z-index: 1000;

    }

    & + div {
        @include spacings-size('y-equal-6', 'margin-top');
    }

    &-inner {

        > * {
            margin-top: 0 !important;
        }

        .label-control {
            @include font-small-2();

            display: block;
            position: relative;
            top: to-rem(-2px);
            margin-right: map-deep-get(
                    $spacings-sizes,
                    'x-equal-3',
                    'min'
            ) !important;
            color: $filter-container-label-control-color;
        }

        .form-control {
            @include spacings-size('y-equal-7', 'padding-top');
        }

        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: center;

            .form-control {
                padding-top: 0;
            }
        }
    }
}
