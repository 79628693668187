/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=buttons

Usage: In rare cases, you might need a specific value of a
specific button variant. This is how to acces map values:

color: map-deep-get($btn-variations,'default','bg');

*/

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                normal !default;
$btn-default-color:              #333 !default;
$btn-default-bg:                 #fff !default;
$btn-default-border:             #ccc !default;

$btn-primary-color:              #fff !default;
$btn-primary-bg:                 $brand-color-primary !default;
$btn-primary-border:             darken($btn-primary-bg, 5%) !default;

$btn-success-color:              #fff !default;
$btn-success-bg:                 $color-success !default;
$btn-success-border:             darken($btn-success-bg, 5%) !default;

$btn-info-color:                 #fff !default;
$btn-info-bg:                    $color-info !default;
$btn-info-border:                darken($btn-info-bg, 5%) !default;

$btn-warning-color:              #fff !default;
$btn-warning-bg:                 $color-warning !default;
$btn-warning-border:             darken($btn-warning-bg, 5%) !default;

$btn-danger-color:               #fff !default;
$btn-danger-bg:                  $color-danger !default;
$btn-danger-border:              darken($btn-danger-bg, 5%) !default;

$btn-link-disabled-color:        $gray-light !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         $border-radius-base !default;
$btn-border-radius-large:        $border-radius-large !default;
$btn-border-radius-small:        $border-radius-small !default;

$btn-transition-duration: 150ms;
$btn-transition-timing-function: ease-in;
$btn-transition: color $btn-transition-duration $btn-transition-timing-function,
    background-color $btn-transition-duration $btn-transition-timing-function,
    border-color $btn-transition-duration $btn-transition-timing-function;

// Global Button and Input Paddings
// (important: TOP and BOTTOM will be applied to .form-control, too, because we sometimes combine inputs and button, so they need to be the exact same height)
$btn-padding-top: 0.575em; // important: has to be in em's
$btn-padding-bottom: 0.575em; // important: has to be in em's
$btn-padding-right: 1.45em; // important: has to be in em's
$btn-padding-left: 1.45em; // important: has to be in em's
$btn-line-height: 1.25;

// Global Button and Input Borders
$button-border-style: $form-field-border-style;
$button-border-width: 1px;
$button-border-color: $form-field-border-color;

// disabled button
$btn-link-disabled-color: $color-muted-primary;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base: 0;
$btn-border-radius-large: 0;
$btn-border-radius-small: 0;

// .btn-container (spacing between buttons)
$btn-container-btn-spacing-xs: 0.5em;
$btn-container-btn-spacing-md: 0.5em;
$btn-container-btn-spacing-xl: 0.5em;

// button icons (arrow, plus)
$btn-icon-size: 1.4em;
$btn-icon-spacing: 0.5em;
$btn-icon-transition-duration: 300ms;
$btn-icon-transition-timing-function: ease-in-out;

// mask animation
$btn-mask-transition-duration: ease;
$btn-mask-transition-timing-function: 400ms;
$btn-mask-transition-direction-starting-point: 'bottom'; // top, right, bottom or left

// button variation config
$btn-disabled-lighten: 20%;

$btn-variations: (
    'default': (
        // default
        'color': $color-default-secondary,
        'bg': $color-default-primary,
        'border': true,
        'border-top-color': $color-default-primary,
        'border-right-color': $color-default-primary,
        'border-bottom-color': $color-default-primary,
        'border-left-color': $color-default-primary,
        // hover
        'bg-hover': $color-default-secondary,
        'color-hover': $color-default-primary,
        'border-hover': true,
        'border-top-color-hover': $color-default-primary,
        'border-right-color-hover': $color-default-primary,
        'border-bottom-color-hover': $color-default-primary,
        'border-left-color-hover': $color-default-primary,
        // disabled
        'color-disabled': $color-default-secondary,
        'bg-disabled': lighten($color-default-primary, $btn-disabled-lighten),
        'border-disabled': false,
        'border-top-color-disabled':
            lighten($color-default-primary, $btn-disabled-lighten),
        'border-right-color-disabled':
            lighten($color-default-primary, $btn-disabled-lighten),
        'border-bottom-color-disabled':
            lighten($color-default-primary, $btn-disabled-lighten),
        'border-left-color-disabled':
            lighten($color-default-primary, $btn-disabled-lighten),
    ),
    'primary': (
        // default
        'color': $color-default-primary,
        'bg': transparent,
        'border': true,
        'border-top-color': $color-default-primary,
        'border-right-color': $color-default-primary,
        'border-bottom-color': $color-default-primary,
        'border-left-color': $color-default-primary,
        // hover
        'bg-hover': $color-default-primary,
        'color-hover': $color-default-secondary,
        'border-hover': true,
        'border-top-color-hover': $color-default-primary,
        'border-right-color-hover': $color-default-primary,
        'border-bottom-color-hover': $color-default-primary,
        'border-left-color-hover': $color-default-primary,
        // disabled
        'color-disabled': $color-default-primary,
        'bg-disabled': transparent,
        'border-disabled': true,
        'border-top-color-disabled': transparent,
        'border-right-color-disabled': transparent,
        'border-bottom-color-disabled': transparent,
        'border-left-color-disabled': transparent,
    ),
    'inverted': (
        // default
        'color': $color-default-primary,
        'bg': $color-default-secondary,
        'border': true,
        'border-top-color': $color-default-secondary,
        'border-right-color': $color-default-secondary,
        'border-bottom-color': $color-default-secondary,
        'border-left-color': $color-default-secondary,
        // hover
        'bg-hover': $color-default-primary,
        'color-hover': $color-default-secondary,
        'border-hover': true,
        'border-top-color-hover': $color-default-primary,
        'border-right-color-hover': $color-default-primary,
        'border-bottom-color-hover': $color-default-primary,
        'border-left-color-hover': $color-default-primary,
        // disabled
        'color-disabled': $color-default-secondary,
        'bg-disabled': transparent,
        'border-disabled': true,
        'border-top-color-disabled': transparent,
        'border-right-color-disabled': transparent,
        'border-bottom-color-disabled': transparent,
        'border-left-color-disabled': transparent,
    ),
    'link': (
        'color': $color-default-primary,
        'bg': transparent,
        // default
        'border-top-color': transparent,
        'border-right-color': transparent,
        'border-bottom-color': transparent,
        'border-left-color': transparent,
        // hover
        'bg-hover': transparent,
        'color-hover': $color-default-primary,
        'border-top-color-hover': transparent,
        'border-right-color-hover': transparent,
        'border-bottom-color-hover': transparent,
        'border-left-color-hover': transparent,
        // disabled
        'color-disabled': $color-default-primary,
        'bg-disabled': transparent,
        'border-top-color-disabled': transparent,
        'border-right-color-disabled': transparent,
        'border-bottom-color-disabled': transparent,
        'border-left-color-disabled': transparent,
    ),
);
