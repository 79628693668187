.partner-plugin__item {
    display: block;
    background-color: $brand-gray-95;
    @include spacings-size('y-equal-5', padding);
    @include spacings-size('y-equal-5', padding-left);
    @include spacings-size('y-equal-5', padding-right);

    @include breakpoint(medium) {
        max-height: to-rem(118px);
        @include spacings-size('y-equal-7', padding);
        @include spacings-size('y-equal-7', padding-left);
        @include spacings-size('y-equal-7', padding-right);
    }

    .partner-plugin--awards & {
        background: none;
        padding: 0;
    }
}

