.language-list {
    display: flex;

    li {
        @include spacings-size('y-equal-5', margin-right);
    }
}

.language-list {
    @include clearfix;

    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
        float: left;

        &--active {
            .language-list__link {
                cursor: default;

                &:link,
                &:visited {
                    color: $languagechooser-text-color-active;
                }
            }
        }
    }

    &__link {
        display: block;
        text-transform: uppercase;
        padding: 0.3em 0.5em;

        @include font-small();
        line-height: 20px;

        &:link,
        &:visited {
            color: $languagechooser-text-color-default;
        }

        &:hover,
        &:focus {
            color: $languagechooser-text-color-hover;
        }
    }
}
