/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=modal (global)

*/

$global-color-transition-duration: 300ms;
$global-icon-transition-duration: 300ms;

$global-color-timing-function: ease;
$global-icon-timing-function: ease-in-out;

$modal-global-bg:                              #fff;
$modal-global-overlay-transition: all 400ms ease-in-out;
$modal-global-content-bg: $body-background-color;

// close button style
$modal-global-close-icon: '\e910';
$modal-global-close-icon-color: $brand-gray-15;
$modal-global-close-icon-color-hover: $color-icon-hover;
$modal-global-close-icon-size: 18px;
$modal-global-close-icon-transition-property: all;
$modal-global-close-icon-transition-duration: $global-color-timing-function;
$modal-global-close-icon-transition-timing-function: $global-color-transition-duration;

// close button size
$modal-global-close-btn-width-xs: 60px;
$modal-global-close-btn-height-xs: 60px;

$modal-global-close-btn-width-md: 70px;
$modal-global-close-btn-height-md: 70px;

$modal-global-close-btn-width-xl: 70px;
$modal-global-close-btn-height-xl: 70px;

$modal-global-close-btn-width-xxl: 70px;
$modal-global-close-btn-height-xxl: 70px;

// content padding
$modal-global-spacing-xs: 60px $grid-gutter-width/2 $grid-gutter-width/2;
$modal-global-spacing-md: $grid-gutter-width;

// header
$modal-header-bg-color: $brand-black;
$modal-header-height-xs: to-rem(80px);
$modal-header-height-md: to-rem(80px);
$modal-header-height-xxl: to-rem(80px);
$modal-header-width: $max-width-xxl;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=default =modal

*/

$default-modal-transition-duration: 150ms;
$default-modal-transition-timing-function: ease-in;
$default-modal-transition: visibility $default-modal-transition-duration
        $default-modal-transition-timing-function,
    opacity $default-modal-transition-duration
        $default-modal-transition-timing-function;

// close button size
$default-modal-close-btn-width-xs: $modal-global-close-btn-width-xs;
$default-modal-close-btn-height-xs: $modal-global-close-btn-height-xs;

$default-modal-close-btn-width-md: $modal-global-close-btn-width-md;
$default-modal-close-btn-height-md: $modal-global-close-btn-height-md;

$default-modal-close-btn-width-xl: $modal-global-close-btn-width-xl;
$default-modal-close-btn-height-xl: $modal-global-close-btn-height-xl;

$default-modal-close-btn-width-xxl: $modal-global-close-btn-width-xxl;
$default-modal-close-btn-height-xxl: $modal-global-close-btn-height-xxl;

// max width setting
$default-modal-max-width-md: none;
$default-modal-max-width-xxl: to-rem(1040px);

// content padding
$default-modal-spacing-xs: 0;
$default-modal-spacing-md: 0;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=image =modal

*/

// transition
$image-modal-transition-duration: 150ms;
$image-modal-transition-timing-function: ease-in;
$image-modal-transition: visibility $image-modal-transition-duration
        $image-modal-transition-timing-function,
    opacity $image-modal-transition-duration
        $image-modal-transition-timing-function;

// close button style
$image-modal-close-icon-color: $color-default-secondary;
$image-modal-close-icon-color-hover: #eee;

// close button size
$image-modal-close-btn-width-xs: $modal-global-close-btn-width-xs;
$image-modal-close-btn-height-xs: $modal-global-close-btn-height-xs;

$image-modal-close-btn-width-md: $modal-global-close-btn-width-md;
$image-modal-close-btn-height-md: $modal-global-close-btn-height-md;

$image-modal-close-btn-width-xl: $modal-global-close-btn-width-xl;
$image-modal-close-btn-height-xl: $modal-global-close-btn-height-xl;

$image-modal-close-btn-width-xxl: $modal-global-close-btn-width-xxl;
$image-modal-close-btn-height-xxl: $modal-global-close-btn-height-xxl;

// max width setting
$image-modal-max-width: map-get($grid-breakpoints, xl);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=form =modal

*/

$form-modal-transition-duration: 150ms;
$form-modal-transition-timing-function: ease-in;
$form-modal-transition: visibility $form-modal-transition-duration
        $form-modal-transition-timing-function,
    opacity $form-modal-transition-duration
        $form-modal-transition-timing-function;

// close button size
$form-modal-close-btn-width-xs: $modal-global-close-btn-width-xs;
$form-modal-close-btn-height-xs: $modal-global-close-btn-height-xs;

$form-modal-close-btn-width-md: $modal-global-close-btn-width-md;
$form-modal-close-btn-height-md: $modal-global-close-btn-height-md;

$form-modal-close-btn-width-xl: $modal-global-close-btn-width-xl;
$form-modal-close-btn-height-xl: $modal-global-close-btn-height-xl;

$form-modal-close-btn-width-xxl: $modal-global-close-btn-width-xxl;
$form-modal-close-btn-height-xxl: $modal-global-close-btn-height-xxl;

// fade in duration
$form-modal-fade-in-duration: 500ms;

// content padding
$form-modal-spacing-xs: 60px $grid-gutter-width/2 $grid-gutter-width/2;
$form-modal-spacing-md: $grid-gutter-width;

// max width settings
$form-modal-max-width-md: 850px;
$form-modal-max-width-xxl: 950px;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=softpage

*/

$softpage-content-transition: $modal-global-overlay-transition;
$softpage-content-offset: 100%; // y coordinates, any unit is fine
$softpage-max-width-sm: none;
$softpage-max-width-xxl: 1000px;

// softpage positioning
$softpage-site-header-visible-when-opened: true;
$softpage-offset-top-xs: $modal-header-height-xs;
$softpage-offset-top-md: $modal-header-height-md;
$softpage-offset-top-xxl: $modal-header-height-xxl;


// close button
$softpage-close-btn-width-xs: 60px;
$softpage-close-btn-height-xs: 60px;
$softpage-close-btn-position-right-xs: 0;

$softpage-close-btn-width-md: 80px;
$softpage-close-btn-height-md: 105px;
$softpage-close-btn-position-right-md: 0;

$softpage-close-btn-width-xl: 80px;
$softpage-close-btn-height-xl: 105px;
$softpage-close-btn-position-right-xl: 0;

$softpage-close-btn-width-xxl: 80px;
$softpage-close-btn-height-xxl: 105px;
$softpage-close-btn-position-right-xxl: 0;
