/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Form modal

*/

.default-modal {
    overflow-x: hidden;
    z-index: z('default-modal');
    transition: $default-modal-transition;

    .tingle-modal-box {
        align-self: center;
        top: 0;
        width: 100%;

        @include media-breakpoint-up(md) {
            max-width: $default-modal-max-width-md;
        }

        @include media-breakpoint-up(xxl) {
            max-width: $default-modal-max-width-xxl;
        }
    }

    .tingle-modal-box__content {
        padding: $default-modal-spacing-xs;

        @include media-breakpoint-up(md) {
            padding: $default-modal-spacing-md;
        }
    }

    // close button hidden per default
    .tingle-modal__close {
        display: none;
        position: absolute;
        top: 0 !important;
        right: 0;
        left: auto;
        line-height: 1;

        &:focus {
            outline: none;
        }

        width: $default-modal-close-btn-width-xs;
        height: $default-modal-close-btn-height-xs;

        @include media-breakpoint-up(md) {
            width: $default-modal-close-btn-width-md;
            height: $default-modal-close-btn-height-md;
        }

        @include media-breakpoint-up(xl) {
            width: $default-modal-close-btn-width-xl;
            height: $default-modal-close-btn-height-xl;
        }

        @include media-breakpoint-up(xxl) {
            width: $default-modal-close-btn-width-xxl;
            height: $default-modal-close-btn-height-xxl;
        }
    }
    // close button enabled
    &.tingle-modal--ButtonClose {
        .tingle-modal__close {
            display: block;
        }
    }

    // overlay close enabled?
    cursor: default;

    &.tingle-modal--OverlayClose {
        cursor: pointer;
    }
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Default Modal

*/

.default-modal {
    .tingle-modal-box {
        //@include spacings-size('x-gutter-width', 'padding');
        @include spacings-size('y-gutter-width', 'padding');
        max-width: $default-modal-max-width-xxl;
        background-color: $modal-global-content-bg;

        .content-plugin + .content-plugin {
            //@include spacings-size('y-scale-4', 'margin-top');
        }
    }

      .tingle-modal__close {
        transform: none;


        &:before {
            color: $modal-global-close-icon-color;
            left: 70%;
        }

        &:hover:before,
        &:focus:before {
            color: $modal-global-close-icon-color-hover;
        }
    }
}

// Background hidden plugin option
.default-modal[data-default-modal-variation~='infobox--background-hidden'] {
    @include spacings-size('x-page-padding', 'padding');
    @include spacings-size('y-page-padding', 'padding');

    background-color: transparent;
}

.tingle-modal-box__content {

    .softpage & {
        @include spacings-size('y-section-4', 'padding-bottom');
    }
}
