/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=Color Definitions

Note: If possible, only use these colors in this settings-file

*/

$gray-dark: #373a3c;
$gray: #55595c;
$gray-light: #818a91;
$gray-lighter: #eceeef;
$gray-lightest: #f7f7f9;
$white: #fff;



/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Color variables names can be hard to remember.
I find the following approach worth trying:
https://davidwalsh.name/sass-color-variables-dont-suck

It's simple: Give a variable a unique but memorable name.

Examples:

// Red Color Variables
$blood:     #68121a;
$brick:     #581207;
$apple:     #fa041b;
$coral:     #e47f88;

// Blue Color Variables
$midnight:  #2e3e5c;
$blueberry: #0067ac;
$dusk:      #5f6f8e;

Looking for names? Simply type in the HEX-Code on the following site:
http://chir.ag/projects/name-that-color/#C8E2F9

*/

$brand-color-primary-light: lighten($brand-color-primary, 15%);
$brand-color-primary-dark: darken($brand-color-primary, 15%);
$brand-color-secondary-light: lighten($brand-color-secondary, 15%);
$brand-color-secondary-dark: darken($brand-color-secondary, 15%);

