/* Header */

$selection-bg-color:            $color-default-primary;
$selection-color:               $color-default-primary;


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Animation

*/

$global-color-transition-duration: 300ms;
$global-icon-transition-duration: 300ms;

$global-color-timing-function: ease;
$global-icon-timing-function: ease-in-out;




