.hero {
    overflow: hidden;
    position: relative;

    .image-svg-plugin {
        display: none;

        @include breakpoint(large) {
            display: block;
        }
    }

    &.hero--contact {
        overflow: visible;
        @include breakpoint(medium) {
            //overflow: hidden;
        }
    }

    .medium-12 {
        .video-file-plugin {
            @include fluid-size((min: to-rem(170), max: to-rem(400)), 'min-height');
            @include viewport-fluid-size(60vh, max-height);
            height: 70vh;

            video {
                display: block;
                height: 100%;
                max-height: 100%;
                object-fit: cover;
                padding-bottom: 0 !important;
            }

            @include breakpoint(large) {
                @include fluid-size((min: to-rem(340), max: to-rem(816)), 'max-height');
                height: 82vh;
            }

            @include breakpoint(medium down) {
                picture {
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding-bottom: 0 !important;

                    img {
                        height: auto;
                    }
                }
            }
            picture img {
                object-fit: cover;
            }
        }
    }


    .section {
        margin-top: 0;

        @include breakpoint(large) {
            margin-top: $site-header-height-xxl;
        }
    }
}

.grid-container--hero-full, .grid-container--media-full-width-large {
    @include breakpoint(xxlarge) {
        @include spacings-size('y-menu-height', padding-top);

        .softpage-visible & {
            @include spacings-size('y-menu-height', padding-top);

        }
    }

    @include breakpoint(xlarge down) {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}

.grid-container--media-full-width-medium:not(.grid-container--media-full-width-large) {
    @include breakpoint(large) {
        @include spacings-size('y-menu-height', padding-top);

        .softpage-visible & {
            @include spacings-size('y-menu-height', padding-top);

        }

        //.softpage-visible & {
        //    padding-top: 0;
        //}
    }

    @include breakpoint(medium down) {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}

.hero-image {


      picture {
            padding-bottom: 0 !important;
            height: 100%;
        }

        img {
            height: 100%;
            object-fit: cover;
        }

    &.hero-image--half {
       @extend %hero-fluid-container;

    }


    &.hero-image--full {
         @extend %hero-fluid-container-full;
    }

    &.hero-image--people {
           @include breakpoint(medium down) {
            height: 80vh;
            @include viewport-fluid-size(90vh, max-height);

            picture {
                padding-bottom: 0 !important;
                height: 100%;
            }
        }

        img {
            height: 100%;
            object-fit: cover;
        }
    }
}



.hero--default,
.hero--home,
.hero--contact {
    @include breakpoint(medium down) {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 5rem;
            width: 100%;
            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 99%);
            pointer-events: none;
            z-index: 10;

            .softpage-visible & {
                display: none;
            }

            @include breakpoint(large) {
                width: 0;
            }
        }
    }
}

.hero__contact-container {
    @include breakpoint(large) {
        @include spacings-size('y-section-3', padding-top);

        .softpage-visible & {
            @include spacings-size('y-scale-2', padding-top);
        }
    }
}

.hero-content__background {
    position: relative;
    background: $brand-color-primary;

    @include breakpoint(large) {
        height: 85vh;
        min-height: to-rem(650px);
    }
}

.hero-media__image-container {
    position: relative;

    .hero--default & {
        @include breakpoint(medium) {
            height: 50vh;
            overflow: hidden;
        }

        @include breakpoint(large) {
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            overflow: hidden;
        }

        .content-plugin picture {
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-height: 100%;

            img,
            source {
                max-width: 100%;
                object-fit: cover;
                object-position: 50% 50%;
                height: 100%;
                min-height: 100%;
            }
        }
    }

    .content-plugin {
        position: relative;
        height: 100%;
    }
}

.hero-media__video-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .video-file-plugin {
        position: relative;
        overflow: hidden;
        height: 100%;
        flex-grow: 1;
        background: none;
        padding: 0 !important;

        video {
            height: 100%;
            object-position: 50% 50%;
            object-fit: cover;
        }
    }
}

.hero-content__container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    z-index: 10;
    height: 100%;
    width: 100%;
    max-width: $global-width;
    margin: 0 auto;
    @include fluid-size(
        (
            min: $page-padding-small,
            max: $page-padding-medium,
        ),
        'padding-right'
    );
    @include spacings-size('y-scale-5', 'padding-bottom');
    @include fluid-size(
        (
            min: $page-padding-small,
            max: $page-padding-medium,
        ),
        'padding-left'
    );
    pointer-events: none;

    @include breakpoint(medium) {
        padding-top: 2rem;
        justify-content: space-between;
        align-content: center;
    }

    @include breakpoint(large) {
        padding-top: 5rem;
    }
}

.hero-media__logo,
.hero-media__claim {
    display: flex;
    height: 100%;
    z-index: 10;
}

.hero-media__logo {
    display: none;
    @include breakpoint(medium) {
        display: block;
    }
    img {
        @include fluid-size(
            (
                min: to-rem(170px),
                max: to-rem(610px),
            ),
            'width'
        );
    }
}

.hero-media__claim {
    align-items: flex-end;
    justify-content: flex-end;

    img {
        @include fluid-size(
            (
                min: to-rem(200px),
                max: to-rem(500px),
            ),
            'width'
        );
    }
}

.hero-media__content-container {
    display: flex;
    align-items: flex-end;
    @include spacings-size('y-scale-2', 'padding-bottom');

    @include breakpoint(large) {
        height: 85vh;
        min-height: to-rem(650px);
        @include fluid-size(
            (
                min: $gutter-width-small / 2,
                max: $gutter-width-medium / 2,
            ),
            'padding-left'
        );
    }
}

// Default

.hero-media__menu-wrapper {
    position: fixed;
    @include set-site-header-height();
    width: 100%;
    z-index: 100;
    top: 0;
    //background-color: $brand-white;
    //
    //.hero--inverted & {
    //   background-color: $brand-black;
    //}
    //.hero--home & {
    //   background: none;
    //}

    .tpl-hero &, .tpl-home &, .tpl-contact & {
        .cms-toolbar-expanded & {
            top: to-rem(46);
        }
    }


    @include breakpoint(medium down) {
        background: none!important;
    }
    .brand-logo {
        display: block;
        .hero--home & {
            @include breakpoint(large) {
                display: none;
            }
        }
    }

    .softpage & {
        display: none;
    }
    //
    //.nav-toggle__line {
    //    .hero--home & {
    //        background: $brand-color-primary;
    //    }
    //
    //    .hero--default &,
    //    .hero--contact & {
    //        @include breakpoint(medium down) {
    //            background: $brand-color-primary;
    //        }
    //    }
    //}
    //
    //.nav-toggle {
    //    &:hover,
    //    &:focus {
    //        .nav-toggle__line {
    //            .hero--default &,
    //            .hero--contact & {
    //                @include breakpoint(small only) {
    //                    opacity: 1;
    //                    background: $brand-white;
    //                }
    //            }
    //        }
    //    }
    //}
}


.news__story-hero, .hero-image--people {


    @extend %hero-fluid-container-full;
    //height: 60vh;
    //@include breakpoint(large) {
    //    height: 70vh;
    //}
    //@include media-breakpoint-up(19) {
    //    max-height: 51rem;
    //}
}
