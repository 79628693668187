// Horizontal dividers
//
// Dividers (basically an hr) within dropdowns and nav lists

@mixin nav-divider($color: #e5e5e5) {
    height: 1px;
    margin: (($line-height-computed / 2) - 1) 0;
    overflow: hidden;
    background-color: $color;
}

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-border-focus` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
@mixin form-control-focus($color: $input-border-focus) {
    $color-rgba: rgba(red($color), green($color), blue($color), 0.6);

    &:focus {
        border-color: $color;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $color-rgba;
    }
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
    ::placeholder {
        color: $color;
        opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    }
}

// Single side border-radius

@mixin border-top-radius($radius) {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
}

@mixin border-right-radius($radius) {
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
}
