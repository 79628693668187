.products {
    display: flex;
    flex-direction: column;
}

.product {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    background: #ececec;
    margin-bottom: 2rem;
}

.product__additionals {
    display: flex;
}

.product__additional {
    margin-right: 0.5rem;
}

.main-filter {
    margin-bottom: 0.5rem;
}

.subfilter {
    display: flex;
    margin-bottom: 1rem;
}

.filter-group__item {
    width: 100%;

    &.filter-group__item--is_empty {
        cursor: not-allowed;
    }
}

.featured-filters {
    display: flex;
    flex-wrap: wrap;
}

.filter {
    display: block;
    text-align: center;
    cursor: pointer;

    &, .icon {
        transition-property: color, background-color, border-color, mask;
        transition-duration: $link-transition-duration;
        transition-timing-function: $link-transition-timing-function;
    }
}

div[role="button"] {
    cursor: pointer;
}

.filter--default {
    display: flex;
    @include spacings-size("x-equal-3", padding-left);
    @include spacings-size("x-equal-3", padding-right);
    background-color: $brand-gray-95;
    min-width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    span {
        width: 100%;
    }

    @include breakpoint(medium) {
        min-width: rem-calc(206px);
    }

    &:hover {
        background-color: $brand-gray-90;
    }

    &.is_selected {
        background-color: $white;
        outline: rem-calc(2px) solid $brand-color-primary;
        color: $brand-color-primary;
        outline-offset: rem-calc(-2px);

        &:hover {
            background-color: $brand-primary-color-93;
        }

    }

    &.is_empty {
        background: none;
        outline: rem-calc(1px) solid $brand-gray-80;
        outline-offset: rem-calc(-1px);
        color: $brand-gray-70;
        pointer-events: none;
        cursor: not-allowed;
    }
}


$filter-variations: (
    'seasonal': (
        'default': (
            'label-color': $brand-green-color-50,
            'icon-color': $brand-green-color-50,
            'background-color': $brand-white,
            'border-color': $brand-green-color-50,
            'hover': (
                'background-color': $brand-green-color-93,
            )
        ),
        'active': (
            'label-color': $brand-white,
            'icon-color': $brand-white,
            'background-color': $brand-green-color-50,
            'border-color': $brand-green-color-50,
            'hover': (
                'background-color': $brand-green-color-40,
            ),
        ),
        'empty': (
            'label-color': $brand-green-color-80,
            'icon-color': $brand-green-color-80,
            'background-color': transparent,
            'border-color': $brand-green-color-80,
        ),
    ),
    'action': (
        'default': (
            'label-color': $brand-blue-color-40,
            'icon-color': $brand-blue-color-40,
            'background-color': $brand-white,
            'border-color': $brand-blue-color-40,
            'hover': (
                'background-color': $brand-blue-color-93,
            )
        ),
        'active': (
            'label-color': $brand-white,
            'icon-color': $brand-white,
            'background-color': $brand-blue-color-40,
            'border-color': $brand-blue-color-40,
            'hover': (
                'background-color': $brand-blue-color-30,
            ),
        ),
        'empty': (
            'label-color': $brand-blue-color-70,
            'icon-color': $brand-blue-color-70,
            'background-color': transparent,
            'border-color': $brand-blue-color-70,
        ),
    ),
    'speciality': (
        'default': (
            'label-color': $brand-orange-color-50,
            'icon-color': $brand-orange-color-50,
            'background-color': $brand-white,
            'border-color': $brand-orange-color-50,
            'hover': (
                'label-color': $brand-orange-color-50,
                'icon-color': $brand-orange-color-50,
                'background-color': $brand-orange-color-93,
                'border-color': $brand-orange-color-50,
            )
        ),
        'active': (
            'label-color': $brand-white,
            'icon-color': $brand-white,
            'background-color': $brand-orange-color-50,
            'border-color': $brand-orange-color-50,
            'hover': (
                'label-color': $brand-white,
                'icon-color': $brand-white,
                'background-color': $brand-orange-color-40,
                'border-color': $brand-orange-color-50,
            )
        ),
        'empty': (
            'label-color': $brand-orange-color-80,
            'icon-color': $brand-orange-color-80,
            'background-color': transparent,
            'border-color': $brand-orange-color-80,
        ),
    ),
);


@each $filter, $type in $filter-variations {
    .filter--#{$filter} {
        color: map-deep-get($type, "default", 'label-color');
        background: map-deep-get($type, "default", 'background-color');
        border-color: map-deep-get($type, "default", 'border-color');


        .icon--#{$filter} {
            background-color: map-deep-get($type, "default", 'label-color');
            mask: url('icons/#{$filter}.svg') no-repeat 50% 50%;
        }

        &:hover {
            background-color: map-deep-get($type, "default", "hover", 'background-color');
        }

        &.is_empty {
            color: map-deep-get($type, "empty", 'label-color');
            background: map-deep-get($type, "empty", 'background-color');
            border-color: map-deep-get($type, "empty", 'border-color');
            pointer-events: none;
            user-select: none;

            .icon--#{$filter} {
                background-color: map-deep-get($type, "empty", 'label-color');
            }
        }

        &.is_selected {
            color: map-deep-get($type, "active", 'label-color');
            background: map-deep-get($type, "active", 'background-color');
            border-color: map-deep-get($type, "active", 'border-color');

            .icon--#{$filter} {
                background-color: map-deep-get($type, "active", 'label-color');
            }

            &:hover {
                background-color: map-deep-get($type, "active", "hover", 'background-color');
            }
        }
    }
}


.filter--featured {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: rem-calc(28px);
    border-radius: 14px;
    @include spacings-size("x-equal-4", padding-left);
    @include spacings-size("x-equal-4", padding-right);
    border-width: 1px;
    border-style: solid;

    @include breakpoint(small only) {
        @include spacings-size("y-equal-6", margin-bottom);
    }

    &:not(:last-child) {
        @include spacings-size("x-equal-4", margin-right);
    }

}


.filter-overlay__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.icon {
    height: rem-calc(24px);
    width: rem-calc(24px);

    &.icon--small {
        height: rem-calc(16px);
        width: rem-calc(16px);
    }

    &.icon--medium {
        height: rem-calc(18px);
        min-width: rem-calc(18px);
        max-width: rem-calc(20px);
    }

    &.icon--tiny {
        height: rem-calc(12px);
        width: rem-calc(12px);
    }
}

.addition {
    margin-right: 0.5rem;

    &.addition--seasonal {
        color: green;
    }

    &.addition--action {
        color: darkblue;
    }

    &.addition--speciality {
        color: darkorange;
    }
}


.color-1 {
    color: red;
}

.color-2 {
    color: orange;
}

.color-3 {
    color: blue;
}

.color-4 {
    color: green;
}


.disabled {
    cursor: not-allowed;
    pointer-events: none;
}

.filter-group__header--top, .filter-overlay__header--left {
    display: flex;
    align-items: flex-end;
}


.filter-group__counter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem-calc(15px);
    width: rem-calc(20px);
    border-radius: rem-calc(15px);
    user-select: none;
}


// Overlay

.filter-overlay {
    position: fixed;
    background-color: $brand-gray-98;
    display: none;
    transform: translateX(-50%);
    left: 50%;
    box-shadow: $assortment-box-shadow;
    z-index: z('filter-overlay');
    min-width: 100%;
    bottom: 0;
    top: 0;

    @include breakpoint(large) {
        min-width: rem-calc(1046px);
        @include spacings-size('y-scale-4', top);
        @include spacings-size('y-scale-4', bottom);
    }

    &.overlay--open {
        display: block;
    }
}

.filter-group__header--bottom {
    display: flex;
}

.filter-overlay__wrapper {
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.filter-overlay__body-wrapper {
    display: flex;
    overflow-y: auto;
    height: 100%;
    margin-right: rem-calc(-12px);
    @extend %d-panel-scrollbar-vertical;
    -webkit-overflow-scrolling: touch;


    @include breakpoint(medium) {
        margin-right: rem-calc(-23px);
    }
}

.filter-overlay__body {
    height: 100%;
    width: 100%;
    padding-right: 12px;


    @include breakpoint(medium) {
        padding-right: 20px;
    }

}


%d-panel-scrollbar-vertical {
    &::-webkit-scrollbar-track {
        border-radius: 3px;
        background-color: $brand-gray-90;
    }

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: $brand-gray-80;
    }
}


// Filter Controls

.filter-controls {
    position: relative;
}

.filter-controls__filter {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &:after, &:before {
        content: "";
        position: relative;
        display: block;
        width: 100%;
        height: 1px;
        background-color: $brand-gray-80;
    }

    &:before {
        @include spacings-size("x-equal-4", right)
    }

    &:after {
        @include spacings-size("x-equal-4", left)
    }

}

.filter-controls__open {
    display: flex;
    align-items: center;
    border: 1px solid $brand-gray-80;
    min-width: rem-calc(230px);
    height: rem-calc(50px);
    border-radius: rem-calc(25px);
    background-color: $brand-gray-98;
    transition-property: background-color, transform;
    transition-duration: $link-transition-duration;
    transition-timing-function: $link-transition-timing-function;
    z-index: 5;

    &:hover {
        background-color: $brand-gray-95;
    }

    .tooltip--active & {
        z-index: z('filter-overlay');
    }
}

.filter-control__sticky-container {
    position: sticky;
    display: block;
    width: 100%;
    height: 6rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    pointer-events: none;

}

.filter-control__footer {
    //@extend .filter-controls__filter;
    //bottom: 0;
    //@include make-container();
    //@include max-width();
}

.filter-controls__open--bottom {
    position: absolute;
    transform: translate(-50%, 100%);
    left: 50%;
    bottom: 0;
    opacity: 0;
    transition: all 300ms ease;
    pointer-events: none;

    .appear & {
        transform: translate(-50%, rem-calc(-15px));
        opacity: 1;
        pointer-events: all;
    }
}

.filter-controls__open--left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 0 0 78%;
}

.filter-controls__open--right {
    display: flex;
    height: 100%;
    justify-content: center;
    padding-right: rem-calc(5px);
    align-items: center;
    flex: 0 0 22%;
    border-left: 1px solid $brand-gray-80;
    border-bottom-right-radius: rem-calc(25px);
    border-top-right-radius: rem-calc(25px);
    transition-property: background-color;
    transition-duration: $link-transition-duration;
    transition-timing-function: $link-transition-timing-function;

    &:hover {
        background-color: $brand-gray-90;
    }
}


// Search

.search-field {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    border: none;

    &:hover,
    &:focus {
        input {
            background-color: $brand-white;
            border-color: $brand-gray-40;
        }
    }


    label {
        position: relative;
        width: 100%;
    }

    input {
        width: 100%;
        border: 1px solid $brand-gray-60;
        transition: border, background-color $global-color-transition-duration ease;
        outline: none;
        color: $brand-gray-24;
        height: rem-calc(54px);
        @include spacings-size("x-equal-3", padding-left);
        background-color: $brand-gray-98;

        &::placeholder {
            color: $brand-gray-60;
        }
    }

    button {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        background-color: $brand-primary-color-50;
        width: rem-calc(54px);
        min-width: auto;
        min-height: 100%;
        line-height: 0;
        height: 100%;
        padding: 0;
        transition-property: background-color;
        transition-duration: $link-transition-duration;
        transition-timing-function: $link-transition-timing-function;
        outline: none;
        border: none;
        cursor: pointer;

        &:hover {
            background-color: $brand-primary-color-40;
        }
    }
}

.search__close {
    position: absolute;
    display: flex;
    height: 100%;
    width: rem-calc(50);
    align-items: center;
    justify-content: center;
    right: rem-calc(59px);
    top: 50%;
    transform: translateY(-50%);

    svg {
        transition-property: fill, color;
        transition-duration: $link-transition-duration;
        transition-timing-function: $link-transition-timing-function;
    }

    &:hover {
        svg {
            color: $brand-gray-40;
        }
    }
}

input:placeholder-shown {
    background-color: $brand-gray-98;
}


// Product Cards

.product-card {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    .product-cards & {
        flex-direction: column;

        @include breakpoint(medium) {
            flex-direction: row;
        }
    }

    &:last-child {
        border-bottom: 1px solid $brand-gray-60;
    }

}

.product-card__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.product-card--right {
    display: flex;
    justify-content: flex-end;
    z-index: 1;

    @include breakpoint(small only) {
        position: absolute;
        right: 0;
        bottom: rem-calc(25px);
    }
}

.product-card__icons {
    display: flex;
    align-items: center;
    order: 1;
    @include spacings-size("y-equal-6", margin-bottom);


    @include breakpoint(medium) {
        justify-content: flex-end;
        margin-bottom: 0;
        order: 2;
    }
}

.product-card--left {
    display: flex;
    flex: auto;
    flex-direction: column;
    width: 100%;
    @include breakpoint(medium) {
        width: auto;
        flex-direction: row;
        justify-content: space-between;

    }
}

.product-card__content-wrapper {
    display: flex;
    flex-direction: column;
    order: 2;

    @include breakpoint(medium) {
        flex-direction: row;
        order: 1;
    }
}

.product-card__save {
    display: flex;
    order: 2;
    @include spacings-size("y-equal-6", margin-top);

    @include breakpoint(medium) {
        order: 1;
        margin-top: 0;
    }
}

.product-card__body {
    display: flex;
    flex-direction: column;
    order: 1;
    max-width: 80%;

    .product-cards & {
        max-width: 100%;
    }

    @include breakpoint(medium) {
        max-width: 80%;
        order: 2;
    }
}


.card-interaction {
    position: relative;
    border: 1px solid $brand-gray-80;
    color: $brand-gray-60;

    &.card-interaction--inverted {
        color: $brand-white;
        border: 1px solid $brand-gray-98;
        background-color: $brand-primary-color-50;

        &:hover {
            background-color: $brand-primary-color-40;

        }
    }

    &, svg {
        transition-property: color, border-color, background-color;
        transition-duration: $link-transition-duration;
        transition-timing-function: $link-transition-timing-function;
    }

    &:hover {
        background-color: $brand-gray-95;
    }


    &.highlight {
        background-color: $brand-primary-color-93;
        border: 1px solid transparent;
        color: $brand-primary-color-50;

        &:hover {
            background-color: $brand-primary-color-90;
        }
    }
}

.counter-badge {
    position: absolute;
    top: rem-calc(-5px);
    right: rem-calc(-5px);
    border: 1px solid $body-background-color;
    background-color: $brand-red-color-40;
}

.product-card__open {
    background-color: $brand-gray-90;
    color: $brand-gray-60;


    &, svg {
        transition-property: color, border-color, background-color;
        transition-duration: $link-transition-duration;
        transition-timing-function: $link-transition-timing-function;
    }

    svg {
        transform: rotate(180deg);
    }

    &:hover {
        background-color: $brand-primary-color-85;
    }
}

.counter--seasonal {
    color: $brand-green-color-50;
    border: 1px solid $brand-green-color-50;
}

.counter--action {
    color: $brand-blue-color-40;
    border: 1px solid $brand-blue-color-40;
}

.counter--speciality {
    border: 1px solid $brand-orange-color-50;

    &, svg {
        color: $brand-orange-color-50;
        fill: $brand-orange-color-50;
    }
}

.teaser__content {
    .product-card__teaser-title {
        flex: 1 1 80%;
    }

    .product-card__teaser-button {
        display: flex;
        justify-content: flex-end;
        flex: 1 1 20%;
    }
}

// Sorting

.assortment-list__header--left {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.sorting {
    z-index: 1500;
}

.dropdown-filter__wrapper {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
        flex-direction: row;
    }
}

.dropdown-filter__container {
    display: flex;
    align-items: center;
    align-content: center;
    @include spacings-size('y-equal-2', 'margin-bottom');
    z-index: 10;

    &:last-child {
        z-index: 9;
    }
}

.dropdown-filter {
    position: relative;
    min-width: to-rem(150px);

    &.dropdown-filter--large {
        min-width: 14.125rem;
    }
}

.dropdown-filter--toggle {
    display: flex;
    align-items: center;
    cursor: pointer;

    @include breakpoint(medium) {
        justify-content: flex-end;
    }
}

.dropdown-filter--link-name {
    display: block;
}

.dropdown-filter--trigger-icon {
    display: inline-flex;
    width: 10px;
    height: 5px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='5'%3E%3Cpath fill='%23595955' d='M10 0L5 5 0 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    transition: transform 0.15s ease-in-out 0s;
    transform: rotate(180deg);

    .toggle--collapsed & {
        transform: rotate(0);
    }
}

.dropdown-filter__list {
    display: none;
    margin: 0;
    list-style: none;
    position: absolute;
    top: 2.5rem;
    left: 0;
    background-color: $brand-white;
    width: fit-content;
    box-shadow: $assortment-box-shadow;
    transform: translateY(-1rem);
    @include spacings-size('y-equal-7', 'padding-top');
    @include spacings-size('y-equal-7', 'padding-bottom');
    @include spacings-size('x-equal-5', 'padding-right');
    @include spacings-size('x-equal-5', 'padding-left');

    .filter-container {
        display: block;
    }

    @include breakpoint(medium) {
        left: auto;
        right: 0;
    }

    .toggle--collapsed & {
        display: block;
    }
}

.tooltip-message {
    display: none;
    position: absolute;
    background-color: $brand-white;
    left: 50%;
    bottom: 4.5rem;
    transform: translateX(-50%);
    max-width: rem-calc(400px);
    z-index: z('filter-overlay');

    .tooltip--active & {
        display: block;
    }
}

.tooltip-message__cta {
    max-width: rem-calc(163);
}


.dropdown-filter__item {
    transition: background-color 300ms ease;
    @include spacings-size('y-equal-8', 'margin-bottom');

    &:hover {
        background-color: $brand-gray-95 !important;
    }

    &.dropdown-filter__item--active, &.selected {
        background-color: $brand-primary-color-93 !important;

        &:hover {
            background-color: $brand-primary-color-90 !important;
        }

        label {
            color: $body-color !important;
        }
    }

    input {
        height: 0;
        width: 0;
    }

    label {
        display: block;
        white-space: nowrap;
        width: 100%;
        cursor: pointer;
        @include spacings-size('y-equal-6', 'padding-top');
        @include spacings-size('y-equal-6', 'padding-bottom');
        @include spacings-size('x-equal-4', 'padding-right');
        @include spacings-size('x-equal-4', 'padding-left');

    }


}
