.css-grid {
    display: grid;
    grid-auto-flow: dense;
    @include spacings-size('x-gutter-width', 'column-gap');
    @include spacings-size('y-scale-2', 'row-gap');
    justify-content: space-between;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    margin: 0;

    .items-per-row-5 &, .items-per-row-4 & {
        @include spacings-size('x-gutter-width', 'row-gap');
    }

    &.gy-equal-5 {
        column-gap: map-deep-get($spacings-sizes, 'x-equal-3', 'min');
        row-gap: map-deep-get($spacings-sizes, 'y-equal-5', 'min');
    }

    @include breakpoint(small only) {
        .items-per-row-5-small & {
            grid-template-columns: repeat(10, minmax(0, 1fr));
        }
    }

    @include breakpoint(medium only) {
        .items-per-row-5-medium & {
            grid-template-columns: repeat(10, minmax(0, 1fr));
        }
    }

    @include breakpoint(large) {
        .items-per-row-5 & {
            grid-template-columns: repeat(10, minmax(0, 1fr));
        }
    }

    &.css-grid--partner {
        @include spacings-size('x-gutter-width', 'row-gap');
        @include breakpoint(medium) {
            grid-template-columns: repeat(auto-fit, minmax(25px, 1fr));
        }
    }

    &.css-grid--product {
        grid-template-columns: repeat(1, 1fr);
        @include spacings-size('y-scale-5', 'row-gap');
        grid-template-areas:
                       "order-1"
                       "order-4"
                       "order-5"
                       "order-6"
                       "order-7"
                       "order-3"
                       "order-2"
                       "order-8"
                       "order-9";

        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas:
                       "order-1 order-3"
                       "order-4 order-2"
                       "order-5 order-8"
                       "order-6 order-9"
                       "order-7 order-10";
        }

        @include breakpoint(large) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-areas:
                       "order-1 order-6 order-2"
                       "order-4 order-7 order-8"
                       "order-5 order-3 order-9";
        }

        @for $i from 1 through 10 {
            #order-#{$i} {
                grid-area: order-#{$i};
            }
        }

    }
}


.gy-section-4 {
    @include spacings-size('y-section-4', 'row-gap');
}

.gy-equal-6 {
    @include spacings-size('y-equal-6', 'row-gap');
}

.gy-equal-3 {
    @include spacings-size('y-equal-3', 'row-gap');
}

.css-grid__item {
    grid-column: span 12;

    .items-per-row-1-small & {
        grid-column: span 12;
    }

    .items-per-row-2-small & {
        grid-column: span 6;
    }

    .items-per-row-3-small & {
        grid-column: span 4;
    }

    .items-per-row-4-small & {
        grid-column: span 3;
    }

    .items-per-row-5-small & {
        grid-column: span 2.5;
    }

    @include breakpoint(medium) {
        grid-column: span 12;

        .items-per-row-3 & {
            grid-column: span 12;
        }

        .items-per-row-4 & {
            grid-column: span 4;
        }

        .items-per-row-1-medium & {
            grid-column: span 12;
        }

        .items-per-row-2-medium & {
            grid-column: span 6;
        }

        .items-per-row-3-medium & {
            grid-column: span 4;
        }

        .items-per-row-4-medium & {
            grid-column: span 3;
        }

        .items-per-row-5-medium & {
            grid-column: auto / span 2;
        }
    }

    @include breakpoint(large) {
        .items-per-row-1 & {
            grid-column: span 12;
        }

        .items-per-row-2 & {
            grid-column: span 6;
        }

        .items-per-row-3 & {
            grid-column: span 4;
        }

        .items-per-row-4 & {
            grid-column: span 3;
        }

        .items-per-row-5 & {
            grid-column: auto / span 2;
        }
    }

    &.css-grid__item--newsletter {
        grid-column: span 12;

        @include breakpoint(large) {
            grid-column: 3 / span 8;
        }
    }
}

.css-grid__item--last {
    .items-per-row-2-small & {
        @include breakpoint(small only) {
            grid-column: 4 / span 6;
        }
    }
}
