/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Project specific classes defined in CONTENT_CSS_CLASSES in the settings.py

*/

.content-section {
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    project specific template

    */

    // &__template__col-3-variation {
    //     @include content-plugin-col-mobile-flexbox($flex-bp: breakpoint-max(xs));
    //     @include content-plugin-col-mobile-flexbox-spacing-fix($flex-bp: breakpoint-max(sm));
    //     .content-section__col {
    //         @include media-breakpoint-up(xs) {
    //             @include make-col(24);
    //         }
    //         @include media-breakpoint-down(sm) {
    //             @include spacings-size($content-section-col-stack-spacing, margin-top);
    //         }
    //     }
    //     .content-section__col-1,
    //     .content-section__col-3 {
    //         @include media-breakpoint-up(md) {
    //             @include make-col(6);
    //         }
    //     }
    //     .content-section__col-2 {
    //         @include media-breakpoint-up(md) {
    //             @include make-col(12);
    //         }
    //     }
    // }

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    CSS CLASSES

    */

    .content-section__template__col-1-1 .col-order-mobile-1 {
        @include media-breakpoint-down(sm) {
            @include spacings-size(
                $content-section-1-1-col-order-mobile-1-spacing,
                margin-top
            );
        }
    }

    &.hidden-on-desktop {
        @include media-breakpoint-up(md) {
            display: none;
            // when logged it, show in any case
            .cms-ready & {
                display: block;
                opacity: 0.4;
            }
        }
    }

    &.hidden-on-mobile {
        @include media-breakpoint-down(sm) {
            display: none;
            // when logged it, show in any case
            .cms-ready & {
                display: block;
                opacity: 0.4;
            }
        }
    }

    &.spacings-disabled {
        margin: 0 !important;
        padding: 0 !important;
    }

    &.custom-container-width-1 {
        // 8/12 col width
        .content-section {
            // no styles on this level
            &__col-container {
                @include media-breakpoint-up(5) {
                    @include make-col(16);
                    @include make-col-offset(4);
                }
            }
        }
    }

    &.custom-container-width-2 {
        // 10/12 col width
        .content-section {
            // no styles on this level
            &__col-container {
                @include media-breakpoint-up(5) {
                    @include make-col(20);
                    @include make-col-offset(2);
                }
            }
        }
    }

    &.custom-container-width-1.custom-container-width-2 {
        // dynamic sizing from 8/12 on lg screens to 10/12 on md screen
        .content-section {
            // no styles on this level
            &__col-container {
                @include media-breakpoint-up(5) {
                    @include make-col(20);
                    @include make-col-offset(2);
                }

                @include media-breakpoint-up(12) {
                    @include make-col(16);
                    @include make-col-offset(4);
                }
            }
        }
    }

    &.grid-container--media-full-width {
        .content-section__main-col-container {
            .image-plugin,
            .video-file-plugin,
            .video-embed-plugin,
            .gallery-plugin {
                @include spacings-size(
                    (
                        min: $page-padding-width-xs,
                        max: $page-padding-width,
                    ),
                    margin-left,
                    $negative: true
                );
                @include spacings-size(
                    (
                        min: $page-padding-width-xs,
                        max: $page-padding-width,
                    ),
                    margin-right,
                    $negative: true
                );

                @include media-breakpoint-up(5) {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .gallery-plugin {
                @include breakpoint(small only) {
                    .swiper-optional-container,
                    .swiper-content-container {
                        @include spacings-size(
                            (
                                min: $page-padding-width-xs,
                                max: $page-padding-width,
                            ),
                            margin-left
                        );
                        @include spacings-size(
                            (
                                min: $page-padding-width-xs,
                                max: $page-padding-width,
                            ),
                            margin-right
                        );
                    }
                }
            }
        }

        .content-section__template__col-1 {
            .image-plugin,
            .video-file-plugin,
            .video-embed-plugin,
            .gallery-plugin {
                @include media-breakpoint-up(5) {
                    // We have to peacefully fight the content section containers padding by applying a negative margin resulting its padding-width
                    // container-padding / container-width = negative-margin -> = (at viewportwidth: 1199px) 90 / 902 = 0.0997
                    margin-left: -9.97%;
                    margin-right: -9.97%;
                }

                @include media-breakpoint-up(12) {
                    // We have to peacefully fight the content section containers padding by applying a negative margin resulting in half of its padding-width
                    // container-padding / container-width / 2 = negative-margin -> (at viewportwidth: 1200px) 181 / 722 / 2 = 0.1253
                    margin-left: -12.53%;
                    margin-right: -12.53%;
                }
            }
        }

        .content-section__template__col-1-1,
        .content-section__template__col-1-2,
        .content-section__template__col-2-1,
        .content-section__template__col-3,
        .content-section__template__col-4 {
            @include media-breakpoint-down(11) {
                .content-section__col-container {
                    max-width: 100%;
                    margin-left: 0;
                }
            }
        }

        .content-section__template__col-4 {
            @include media-breakpoint-up(4) {
                .image-plugin,
                .video-file-plugin,
                .video-embed-plugin,
                .gallery-plugin {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Content Plugin Border Top & Bottom
    Additional markup added here: templates/allink_content/default/column.html

    */

    &__border {
        height: 0.0625rem;
        background-color: $content-section-border-bg-color;

        &--top {
            @include spacings-size('y-scale-4', margin-bottom);

            display: none;

            .border-top & {
                display: block;
            }
        }

        &--bottom {
            @include spacings-size('y-scale-4', margin-top);

            display: none;

            .border-bottom & {
                display: block;
            }
        }
    }

    // reset container max-width within modal
    .tingle-modal & {
        .content-section__container {
            @include max-width();
        }
    }
}

.grid-container--media-full-width-small {
    @include breakpoint(small only) {
        .swiper-optional-container {
            @include spacings-size(
                (
                    min: $page-padding-width-xs,
                    max: $page-padding-width,
                ),
                margin-left
            );
            @include spacings-size(
                (
                    min: $page-padding-width-xs,
                    max: $page-padding-width,
                ),
                margin-right
            );
        }

        .swiper-button-prev {
            @include spacings-size(
                (
                    min: $page-padding-width-xs,
                    max: $page-padding-width,
                ),
                margin-left
            );
        }

        .swiper-button-next {
            @include spacings-size(
                (
                    min: $page-padding-width-xs,
                    max: $page-padding-width,
                ),
                margin-right
            );
        }
    }
}


.grid-container--media-full-width {
    @include breakpoint(medium down) {
        .swiper-optional-container {
            @include spacings-size(
                (
                    min: $page-padding-width-xs,
                    max: $page-padding-width,
                ),
                margin-left
            );
            @include spacings-size(
                (
                    min: $page-padding-width-xs,
                    max: $page-padding-width,
                ),
                margin-right
            );
        }

        .swiper-button-prev {
            @include spacings-size(
                (
                    min: $page-padding-width-xs,
                    max: $page-padding-width,
                ),
                margin-left
            );
        }

        .swiper-button-next {
            @include spacings-size(
                (
                    min: $page-padding-width-xs,
                    max: $page-padding-width,
                ),
                margin-right
            );
        }
    }
}
