/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=alerts

*/

$alert-border-width: 1px;
$alert-border-style: solid;
$alert-border-color: transparent;
$alert-padding: 1em 1em 1em 3em; // Important: Leave some space for the icon!
$alert-icon-position-left: 1em;

$alert-variations: (
    'success': (
        'icon': '\e206',
        'icon-color': $color-success-dark,
        'border-color': $color-success,
        'bg': $color-success-light,
        'color': $color-success-dark,
    ),
    'danger': (
        'icon': '\e000',
        'icon-color': $color-danger-dark,
        'border-color': $color-danger,
        'bg': $color-danger-light,
        'color': $color-danger-dark,
    ),
);
