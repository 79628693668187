$editor-font-family: 'Helvetica Neue', 'Arial', 'Tahoma', 'Verdana',
    'Sans-Serif';

.editor-body {
    box-sizing: border-box;
    max-width: 900px;
    margin: 0;
    padding: 15px;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding: 15px 7px 7px 7px !important;
        margin: 15px 0 !important;
    }
}

.cke_panel_grouptitle {
    font-family: $editor-font-family;
}

.cke_panel_list {
    h1 {
        @include font-h1($font-cropping-disabled: true);
    }
    h2 {
        @include font-h2($font-cropping-disabled: true);
    }
    h3 {
        @include font-h3($font-cropping-disabled: true);
    }
    h4 {
        @include font-h4($font-cropping-disabled: true);
    }
    h5 {
        @include font-h5($font-cropping-disabled: true);
    }
    h6 {
        @include font-h6($font-cropping-disabled: true);
    }

    > li {
        > a {
            text-decoration: none;
        }
    }

    // making sure that default dropdowns have a standard font family
    [role='cmsplugins'] & {
        font-family: $editor-font-family !important;
    }
}
