.image-plugin {
    &.menu-image {
        .lazyloader {
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }
}

.responsive-image {
    margin: 0;
    position: relative;
    height: 100%;
    overflow: hidden;

    picture {
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 100%;

        .teaser--action & {
            @include breakpoint(large) {

                padding-bottom: 0 !important;
            }
        }

        img,
        source {
            max-width: 100%;
            object-fit: cover;
            height: 100%;
            min-height: 100%;
        }
    }

    &.responsive-image--contain {
        img, source {
            object-fit: contain;
        }
    }
}

figcaption {
    margin-top: $grid-gutter-width/2;

    @include font-small();
}

.image-svg-plugin {
    &--fullwidth {
        img,
        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.image--gif {
    width: 100%;
}
