/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=seo accordion plugin

*/

$seo-accordion-border-width: 1px;
$seo-accordion-border-color: $color-line-secondary;
$seo-accordion-title-color: $color-default-primary;
$seo-accordion-title-color-hover: $color-muted-primary;
$seo-accordion-icon-size: to-rem(22px);
$seo-accordion-icon-color-hover: $color-muted-primary;
$seo-accordion-content-spacing: 'y-equal-3';
$seo-accordion-transition: transform 150ms ease-in, color 300ms ease;
