
.watchlist {
    bottom: rem-calc(128px);
    right: 0;
    @include make-container();
    @include max-width();
    position: fixed;
    //@include fluid-size((min: $page-padding-width-xs - ($nav-toggle-line-width / 2), max: $page-padding-width - ($nav-toggle-line-width / 2)), 'right');
    //left: rem-calc(25px);
    z-index: z('watchlist');
    opacity: 0;
    pointer-events: none;

    &.watchlist--hidden {
        bottom: rem-calc(80px);
    }

    .tpl-products & {
        opacity: 1;
    }

    @include breakpoint(medium) {
        top: 30vh;
        bottom: auto;
        left: 0;
    }

    @media screen and(max-height: 400px) {
        top: 50vh;
    }

    @media print {
        display: none;
    }
}

.watchlist--hasitems {
    opacity: 1;

    .overlay-menu-enabled &,
    .offcanvas--open & {
        opacity: 0;
    }
}


.watchlist-toggle {
    margin-left: auto;
    pointer-events: all;
    @include breakpoint(small only) {
        right: 2rem;
        bottom: rem-calc(-50px);
        position: absolute;


        .watchlist--hidden & {
            top: rem-calc(-40px);
            bottom: auto;
        }
    }

    @include breakpoint(medium) {
        top: -1rem;
        right: 0;
    }
}

.watchlist-panel {
    display: flex;
    flex-direction: column;
    box-shadow: $assortment-box-shadow;
    background-color: $brand-white;
    height: rem-calc(350px);
    width: auto;
    margin-left: auto;
    pointer-events: all;

    @include breakpoint(medium) {
        height: rem-calc(520px);
        width: rem-calc(480px);
    }

    &.watchlist-panel--grow {

        @include breakpoint(medium) {
            height: 100%;
        }
    }

    .watchlist--hidden & {
        display: none;
    }

    .sharing-button-list {
        margin: 0;
        padding: 0;
    }
}

.watchlist-body-wrapper {
    position: relative;
    height: 100%;
    @extend %d-panel-scrollbar-vertical;
    margin-right: rem-calc(12px);
    margin-top: rem-calc(5px);

    @include breakpoint(small only) {
        overflow-y: auto;
        margin-right: rem-calc(12px);
        margin-bottom: rem-calc(0);
    }

    &.show-scrollbar {
        overflow-y: auto;
        margin-right: rem-calc(8px);
        margin-bottom: rem-calc(5px);
    }

    &.scrollable-top {
        &::before {
            opacity: 1;
            visibility: visible;
        }
    }

    &.scrollable-bottom {
        &::after {
            opacity: 1;
            visibility: visible;
        }
    }
}

.watchlist-body {
    height: 100%;
    //& {
    //  max-height: calc(70vh - 10rem);
    //  overflow-y: scroll;
    //  scrollbar-width: none;
    //}

    &::-webkit-scrollbar {
        display: none;
    }

    .card-list {
        padding-right: rem-calc(8px);

        .product-card:last-child {
            border: none;
        }
    }
}

.watchlist-list-title {
    margin-bottom: 2rem;
    margin-top: 3rem;

    &:first-child {
        margin-top: 0;
    }
}

.watchlist-email-form {
    text-align: center;

    .form-control {
        display: inline-block;
        max-width: 15rem;
        margin-bottom: 1rem;
    }

    .button {
        margin-right: 1rem;
        margin-left: 1rem;
    }
}

.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.watchlist-footer {
    display: flex;
    justify-content: space-between;
}

.watchlist-footer__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0 0 50%;

    &.watchlist-footer__item--border {
        border-right: 1px solid $brand-gray-80;
    }
}

.sharing-button-list {
    position: relative;
    list-style: none;
    display: flex;
    justify-content: center;
}

.inter-tooltip__parent {
    &:hover .inter-tooltip {
        opacity: 1;
    }
}

.inter-tooltip {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    top: -2.5rem;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    padding: 0 rem-calc(10);
    border-radius: rem-calc(15px);
    height: rem-calc(29);
    min-width: rem-calc(130);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.16);
    transition-property: opacity;
    transition-duration: $link-transition-duration;
    transition-timing-function: $link-transition-timing-function;

    @include breakpoint(large) {
        display: flex;

    }
}
