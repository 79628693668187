/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Form Modal

*/

.form-modal {
    // optional styles
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Form modal

*/

.form-modal {
    overflow-x: hidden;
    z-index: z('form-modal');
    transition: $form-modal-transition;

    &.tingle-modal--visible {
        .tingle-modal-box {
            animation-name: fadeIn;
            animation-duration: $form-modal-fade-in-duration;
            animation-timing-function: ease-in;
            animation-fill-mode: forwards;
        }
    }

    .tingle-modal-box {
        align-self: center;
        top: 0;
        width: 100%;
        opacity: 0;

        @include media-breakpoint-up(md) {
            max-width: $form-modal-max-width-md;
        }

        @include media-breakpoint-up(xxl) {
            max-width: $form-modal-max-width-xxl;
        }
    }

    .tingle-modal-box__content {
        padding: $form-modal-spacing-xs;

        @include media-breakpoint-up(md) {
            padding: $form-modal-spacing-md;
        }
    }

    .tingle-modal__close {
        display: none;
        position: absolute;
        top: 0 !important;
        right: 0;
        left: auto;
        line-height: 1;

        &:focus {
            outline: none;
        }

        width: $form-modal-close-btn-width-xs;
        height: $form-modal-close-btn-height-xs;

        @include media-breakpoint-up(md) {
            width: $form-modal-close-btn-width-md;
            height: $form-modal-close-btn-height-md;
        }

        @include media-breakpoint-up(xl) {
            width: $form-modal-close-btn-width-xl;
            height: $form-modal-close-btn-height-xl;
        }

        @include media-breakpoint-up(xxl) {
            width: $form-modal-close-btn-width-xxl;
            height: $form-modal-close-btn-height-xxl;
        }
    }
    // close button enabled
    &.tingle-modal--ButtonClose {
        .tingle-modal__close {
            display: block;
        }
    }

    // overlay close enabled?
    cursor: default;

    &.tingle-modal--OverlayClose {
        cursor: pointer;
    }

    // variations
}
