.show-breakpoint {
    position: fixed;
    opacity: 0.7;
    z-index: 10;
    right: 0.5rem;
    bottom: 4rem;
    color: #fff;
    font-weight: bold;
    cursor: pointer;

    display: none;

    .cms-toolbar-expanded &, .is-debug & {
        display: block;
    }

    > div {
        padding: 0.5em;
        border-radius: 0.6rem;
        @include font-default();
        font-weight: bold;
    }

    .show-for-small-only {
        background: lightseagreen;
    }

    .show-for-medium-only {
        background: orange;
    }

    .show-for-large-only {
        background: red;
    }

    .show-for-xlarge-only {
        background: indianred;
    }

    .show-for-xxlarge {
        background: goldenrod;
    }
}

.show-columns {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    height: 120%;
    width: 100%;
    user-select: none;
    pointer-events: none;
    z-index: 10000;

    &.active {
        display: block;
    }

    .grid-container,
    .grid-x {
        height: 100%;
    }

    .cell {
        height: 100%;
        background-color: rgba(255, 192, 203, 0.3);
        background-clip: content-box;
    }
}

.cms-toolbar {
    .softpage & {
        display: none;
    }
}

.browser-suggestion__logo {
    max-width: to-rem(300);
    margin: 0 0 2rem;
}

.edit-tools {
    position: fixed;
    z-index: 500;
    left: 0.5rem;
    bottom: 2.5rem;
    background: $brand-color-primary;
    display: flex;
    align-items: center;
    padding: 0 0 0 8px;
    font-size: 0.9rem;
    border-radius: 0.6rem;
    color: $brand-white;
    width: fit-content;
    margin: 0;
    height: 0;

    .softpage & {
        position: sticky;
    }

    .button {
        display: flex;
        align-items: center;
        background: $brand-gray-35;
        font-size: 1em;
        margin: 0.25rem;
        padding: 0.7rem 0.6rem;
        min-width: auto;
        border-radius: 0.6rem;
        min-height: auto;
    }
}
