.cms {
    strong {
        font-family: 'Arial', 'Helvetica Neue', 'Tahoma', 'Verdana',
            'Sans-Serif';
        font-weight: bold;
    }

    .cms-dragitem-text {
        > strong {
            margin-right: 1em;
        }
    }
}
