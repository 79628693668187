/* Project Spacing Variables */

$page-padding-small: 1.5625rem; // rem-calc(25); // 1.5625rem
$page-padding-medium: 5rem; // rem-calc(80); // 5rem
$gutter-width-small: 1.25rem; // rem-calc(20); // 1.25rem
$gutter-width-medium: 3.75rem; // rem-calc(60); // 3.75rem
$project-width: 100rem; // rem-calc(1600); // 100rem

// calc(1.5625rem + 5 * ((100vw - 800px) / 800));

@import 'node_modules/foundation-sites/scss/util/util';

$global-width: $project-width + ($page-padding-medium * 2); // Page Width + Page Padding * 2
$grid-margin-gutter-small: $page-padding-small;
$grid-margin-gutter-medium: $gutter-width-medium;
$grid-padding-gutter-small: $page-padding-small;
$grid-padding-gutter-medium: $gutter-width-medium;
$grid-container-padding-small: $page-padding-small * 2;
$grid-container-padding-medium: $page-padding-medium * 2;
//$grid-container-padding-medium: calc(1.5625rem + 10 * ((100vw - 800px) / 800));

@import 'node_modules/foundation-sites/scss/foundation';

$global-flexbox: true;

$breakpoints: (
    small: 0,
    medium: 640px,
    large: 1040px,
    xlarge: 1280px,
    xxlarge: $global-width // 1760px
);

$grid-columns: 12;

$breakpoint-classes: (small medium large xlarge xxlarge);

// The amount of margin between cells at different screen sizes when using the margin grid.
// To use just one size, set the variable to a number instead of a map.

$grid-margin-gutters: (
    small: $grid-margin-gutter-medium,
    medium: $grid-margin-gutter-medium
);

// The amount of padding in cells at different screen sizes when using the padding grid.
// To use just one size, set the variable to a number instead of a map.
$grid-padding-gutters: (
    small: $grid-padding-gutter-small,
    medium: $grid-padding-gutter-medium,
);

$grid-container-padding: (
    small: $grid-container-padding-small,
    medium: $grid-container-padding-medium,
);

@function gutters($key) {
    @return map-get($grid-padding-gutters, $key);
}

@include foundation-xy-grid-classes;
@include foundation-responsive-embed;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-text-alignment;

//%grid-container-width {
//    max-width: $global-width - (($grid-container-padding-medium - $grid-margin-gutter-medium) + $grid-padding-gutter-medium);
//    margin: 0 auto;
//}
