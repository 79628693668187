.skip-link {
    @include sr-only();

    &:hover,
    &:active,
    &:focus {
        background-color: $skip-link-bg;
        border-radius: 3px;
        box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
        clip: auto !important;
        color: $skip-link-color;
        display: block;
        font-weight: bold;
        height: auto;
        left: 5px;
        line-height: normal;
        padding: 15px 23px 14px;
        text-decoration: none;
        top: 5px;
        width: auto;
        z-index: z('above-all');
        text-shadow: none;
    }
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content
.sr-only {
    @include sr-only();
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate
.sr-only-focusable {
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        clip: auto;
    }
}
