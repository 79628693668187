/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Basic styles for the newsletter-signup teaser

*/

.newsletter-signup-teaser {
    &__col-container-outer {
        @include make-container(
            $gutter: 0,
            $gutter-xs: $page-padding-width-xs/2
        );

        @include media-breakpoint-up(md) {
            @include make-col-ready();
            @include make-col(20);
            @include make-col-offset(2);
        }

        @include media-breakpoint-up(lg) {
            @include make-col(16);
            @include make-col-offset(4);
        }
    }

    &__col-container-inner {
        //border-bottom: to-rem(1px) solid $newsletter-teaser-border-color;
        border-top: to-rem(1px) solid $newsletter-teaser-border-color;

        @include spacings-size(y-scale-4, margin-top);
        @include spacings-size(y-section-4, margin-bottom);
        @include spacings-size(y-scale-4, padding-top);
        @include spacings-size(y-scale-4, padding-bottom);
    }

    &__btn-signup {
        @include spacings-size(y-equal-3, margin-top);
    }
}


.newsletter-snippet--border {
    position: relative;
    border-top: 1px solid $brand-gray-80;
    border-bottom: 1px solid $brand-gray-80;
    //
    //.grid-container--media-full-width-small & {
    //
    //    @include breakpoint(small only) {
    //         border: none;
    //    }
    //
    //    &:after, &:before {
    //        content: "";
    //        position: absolute;
    //        height: 1px;
    //        background-color: $brand-black;
    //
    //        @include breakpoint(small only) {
    //            @include fluid-size(
    //                    (
    //                        min: $page-padding-small,
    //                        max: $page-padding-medium,
    //                    ),
    //                    'right'
    //            );
    //            @include fluid-size(
    //                    (
    //                        min: $page-padding-small,
    //                        max: $page-padding-medium,
    //                    ),
    //                    'left'
    //            );
    //        }
    //    }
    //}
    //
    //&:after {
    //    bottom: 0;
    //}
    //
    //&:before {
    //    top: 0;
    //}
}
