/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=Z-Index map

When ever you use a z-index ANYWHERE (especially the core elements
such as .site-header, .site-content, ..) in a stylesheet, add it to this list.
This gives us an overview of all set z-indexes across the site.

Usage:

z-index: z("default-modal");

Note: Requires the z() function

*/

$z-layers: (
    'site-header': 900,
    'site-content': 5,
    'overlay-menu': 1100,
    'watchlist': 1250,
    'mobile-menu': 500,
    'site-overlay': 900,
    'filter-overlay': 2500,
    'filter-overlay-bg': 2450,
    'toggle': 1150,
    'site-overlay-above-all': 4000,
    'fullscreen-gallery': 5000,
    'default-modal': 5000,
    'form-modal': 5000,
    'image-modal': 5000,
    'modal': 1200,
    'browser-check': 5000,
    'teaser-image-overlay': 3,
    'teaser-content': 12,
    'below': -1,
    'below-all': -100000,
    'default': 1,
    'above': 2,
    'above-all': 2147483647,
    // highest possible z-index
);
