/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Feature detection

*/

@supports (object-fit: cover) {
    html::before {
        content: 'objectfit';
        display: none;
    }
}
