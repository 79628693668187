$brand-logo-height-min: to-rem(24px);
$brand-logo-height-max: to-rem(40px);
$brand-logo-height-home: to-rem(68px);
$brand-logo-height-max-claim: to-rem(68px);
$brand-logo-height-min-claim: to-rem(45px);

.brand-logo {
    @include fluid-size(
            (
                min: $page-padding-width-xs,
                max: $page-padding-width,
            ),
            'left'
    );
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    width: auto;
    z-index: z('above');
    transition: transform 400ms ease-in-out;
}

.brand-logo__link {
    display: block;
    border: none !important;
    text-decoration: none;

    svg {
        pointer-events: none;
    }
}

.brand-logo__image {
    display: block;
    width: auto;
    overflow: visible;
    @include fluid-size((min: $brand-logo-height-min, max: $brand-logo-height-max), 'height');

    @include breakpoint(xlarge down) {
        .tpl-hero & {
            fill: $brand-white;
        }
    }

    @include breakpoint(medium down) {
        .tpl-home &, .tpl-contact & {
            fill: $brand-white;
        }
    }

    &.brand-logo__image--large {
        @include fluid-size((min: $brand-logo-height-min-claim, max: $brand-logo-height-max-claim), 'height');
    }


    .hero--home & {
        @include breakpoint(medium down) {
            position: relative;
            margin-top: to-rem(20px);
            height: $brand-logo-height-home;
        }
    }


}

.brand-logo__inner {
    .overlay-menu & {
        fill: $brand-logo-overlay-color;
    }
}
