//.tpl-styleguide {
//    h1 {
//        @include font-h1();
//    }
//
//    p {
//        @include font-default();
//
//        &.tighter {
//            @include font-default-tighter();
//        }
//    }
//
//    // For testing
//    .border-container {
//        border: 1px solid;
//        box-shadow: inset 0 0 0 20px rgba(199, 222, 184, 0.8);
//        margin-bottom: 1.25rem;
//        padding: 1.25rem;
//    }
//}

.styleguide-content {
    .button-list__item {
        margin-bottom: 1rem;
    }
}

.styleguide__title {
    @include breakpoint(small only) {
        display: block;
        transform: rotate(-90deg) translateX(-50%);
        transform-origin: 0 0;
        font-size: 1rem;
        white-space: nowrap;
    }
}

.styleguide__color-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    grid-gap: 1rem;
}

$box-shadow-light: 5px 5px 8px rgba(0, 0, 0, 0.05);

.styleguide__color-item {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 50px;
    width: 50px;
}

.styleguide__color-gray {
    position: absolute;
    display: block;
    color: $brand-white;
    text-align: center;
}
.styleguide__color-black {
    position: absolute;
    display: block;
    color: $brand-black;
    text-align: center;
}

.styleguide__color {
    position: relative;

    .styleguide__color-value {
        &::after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2.5rem;
            width: 4.5rem;
            padding: 0.35rem 0.5rem;
            line-height: normal;
            opacity: 0;
            background-color: $brand-white;
            box-shadow: $box-shadow-light;
            color: $brand-gray-40;
            transition: opacity 0.4s cubic-bezier(0.76, 0, 0.24, 1) 0s;
            font-size: 0.7rem;
        }
    }

    &:hover {
        .styleguide__color-value {
            &:after {
                opacity: 1;
            }
        }
    }
}
