//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical: 6px !default;
$padding-base-horizontal: 12px !default;

$padding-large-vertical: 10px !default;
$padding-large-horizontal: 16px !default;

$padding-small-vertical: 5px !default;
$padding-small-horizontal: 10px !default;

$padding-xs-vertical: 1px !default;
$padding-xs-horizontal: 5px !default;

$line-height-large: 1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5 !default;

$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-small: 3px !default;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color: #fff !default;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $brand-color-primary !default;

//** Width of the `border` for generating carets that indicate dropdowns.
$caret-width-base: 4px !default;
//** Carets increase slightly in size for larger components.
$caret-width-large: 5px !default;
