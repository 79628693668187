$site-header-compact-mode-transition: all 400ms ease-in-out;
$site-header-border-height: 0.0625rem;
$site-header-fixed-buffer-bg-color: $color-default-secondary;
$site-header-bg-color-opacity: 1;
$site-header-overlay-bg-color-opacity: 1;
$nav-toggle-width: rem-calc(50);
$nav-toggle-height: rem-calc(50);
$nav-toggle-line-width: rem-calc(26);
$nav-toggle-line-height: 0.125rem;
$nav-toggle-line-spacing: rem-calc(10);

$nav-toggle-width-small: rem-calc(40);
$nav-toggle-height-small: rem-calc(40);
$nav-toggle-line-width-small: 1.25rem;
$nav-toggle-line-height-small: 0.125rem;
$nav-toggle-line-spacing-small: 0.5rem;

$nav-toggle-transition: background-color 300ms ease, opacity 300ms ease, transform 400ms ease, border 400ms ease;

$site-header-height-xs: to-rem(80px);
$site-header-height-md: to-rem(80px);
$site-header-height-xxl: to-rem(80px);

$site-header-compact-mode-height-xs: to-rem(60px);
$site-header-compact-mode-height-md: to-rem(80px);
$site-header-compact-mode-height-xl: to-rem(80px);
$site-header-compact-mode-height-xxl: to-rem(80px);

$site-header-compact-mode-enabled: false; //boolean
$site-header-fixed-use-buffer: true; //boolean

.site-header {
    position: absolute;
    //background: rgba($site-header-bg-color, $site-header-bg-color-opacity);
    width: 100%;

    // removes flicker when scrolling
    transform: translate3d(0, 0, 0);
    z-index: z('site-header');
    pointer-events: none;

    .tpl-default & {
        pointer-events: all;

    }

    .tpl-home & {
        @include breakpoint(large) {
            display: none;
        }
    }

    @if $site-header-compact-mode-enabled == true {
        transition: $site-header-compact-mode-transition;

        &.compact-mode {
            @include set-site-header-compact-mode-height();
        }
    }

    &.compact-mode, .overlay-menu-enabled & {
        pointer-events: all;
    }

    .overlay-menu & {
        // background: rgba($site-header-overlay-bg-color, $site-header-overlay-bg-color-opacity);
        // position: absolute;
        background: $site-header-overlay-bg-color;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: z('modal');
    }

    &.site-header--overlay .site-header__animated {
         @include set-site-header-height();
    }
}

.site-header__animated {
    //.tpl-home &, .tpl-hero & {
    //    height: 0;
    //    overflow: hidden;
    //    transition: $site-header-compact-mode-transition;
    //    // Fixes Safari 12 glitch https://developer.mozilla.org/de/docs/Web/CSS/will-change
    //    will-change: transform;
    //
    //    .header-compact-mode & {
    //        @include set-site-header-height();
    //    }
    //
    //}
}


.site-header__border {
    position: relative;
    background-color: $site-header-border-color;
    height: $site-header-border-height;
    width: 100%;
    top: $site-header-height-xs;
    right: 0;
    bottom: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;

    .hero--inverted & {
        display: none;
    }

    @include breakpoint(medium) {
        top: $site-header-height-md;
    }

    @include breakpoint(large) {
        top: $site-header-height-xxl;
    }

    .tpl-home &,
    .tpl-contact &,
    .tpl-hero & {
        top: $site-header-height-xs - $site-header-border-height;

        @include breakpoint(medium) {
            top: $site-header-height-md - $site-header-border-height;
        }

        @include breakpoint(large) {
            top: $site-header-height-xxl - $site-header-border-height;
        }
    }

    .overlay-menu & {
        background-color: $site-header-border-overlay-color;
    }
}

.site-header__container {
    @include make-container();
    @include max-width();
    @include set-site-header-height();
    pointer-events: auto;
    z-index: 5;
}

.site-header-buffer {
    @include set-site-header-height();

    @if $site-header-fixed-use-buffer == true {
        background: $body-background-color;
    } @else {
        display: none;
    }

    // Hide on home template if minimal header is used there
    .tpl-home &,
    .tpl-contact &,
    .tpl-hero & {
        display: none;
    }

    .tpl-app-detail &,
    .tpl-lockdown &,
    .tpl-styleguide &,
    .tpl-error & {
        display: block;
    }
}

.menu-large {
    @include breakpoint(small only) {
        position: relative;
        margin-bottom: to-rem(10px) !important;
    }
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Global overlay menu

*/

.overlay-menu {
    height: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    visibility: hidden;
    transition: height 400ms ease-in-out;
    z-index: z('overlay-menu');
    -webkit-overflow-scrolling: touch;
    background: $site-header-overlay-bg-color;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        width: 100%;
        background-image: url("images/tiles/pattern.svg");
        @include fluid-size(
                (
                    min: to-rem(320px),
                    max: to-rem(400px),
                ),
                'background-size'
        );
    }

    &.before-is-active {
        opacity: 1;
        visibility: visible;
    }

    &.is-active {
        height: 100vh;
    }
}

.overlay-menu__content {
    position: relative;
    display: flex;
    align-items: center;


    @include spacings-size('y-scale-2', 'padding-top');
    @include spacings-size('y-scale-2', 'padding-bottom');
    opacity: 0;
    transform: translateY(100vh);
    transition: opacity 500ms linear, transform 500ms ease-in-out;
    min-height: calc(100% - #{$site-header-height-xs});
    z-index: 10;

    @include breakpoint(medium) {
        min-height: calc(100% - #{$site-header-height-md});
    }

    @include breakpoint(medium down) {
        overflow-y: auto;
        height: 100%;
    }


    .section {
        width: 100%;
    }

    .is-active & {
        opacity: 1;
        transform: none;
    }
}


.overlay-menu__content {
    @include breakpoint(medium down) {
        padding-top: 0;

        .before-is-active & {
            overflow-y: auto;
        }
    }
}



/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Nav Toggle

*/

.nav-toggle {
    //@include fluid-size((min: $page-padding-width-xs - ($nav-toggle-line-width / 2), max: $page-padding-width - ($nav-toggle-line-width / 2)), 'right');
    width: $nav-toggle-width;
    height: $nav-toggle-height;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    //pointer-events: auto;
    z-index: z('site-content');
    pointer-events: all;

    &.nav-toggle--small  {
        width: $nav-toggle-height-small;
        height: $nav-toggle-width-small;
    }

    &:hover,
    &:focus {

        .nav-toggle__box {
             &:after {
                background-color: $brand-primary-color-90;
                border: 1px solid transparent;
            }
        }

        .nav-toggle__line {

            .hero--default & {
                opacity: 1;
            }

            @include breakpoint(medium down) {
                .hero--contact &,
                .hero--home & {
                    opacity: 1;
                }
            }
        }
    }

    &.is-active, &.active {
        .nav-toggle__line {
            &:first-child {
                transform: translateY(0.35rem) rotateZ(-45deg);
            }

            &:last-child {
                transform: translateY(-0.35rem) rotateZ(45deg);
            }

        }
    }

}

.nav-toggle--small {
    &.is-active, &.active {
        .nav-toggle__line {
            &:first-child {
                transform: translateY(0.3rem) rotateZ(-45deg);
            }

            &:last-child {
                transform: translateY(-0.3rem) rotateZ(45deg);
            }
        }
    }
}

.nav-toggle__container {
    @include set-site-header-height();
    @include make-container();
    @include max-width();
    position: fixed;
    display: flex;
    justify-content: flex-end;
    top: 0;
    left: 0;
    right: 0;
    z-index: z('toggle');
    pointer-events: none;
    .cms-toolbar-expanded & {
        top: $cms-toolbar-height-expanded;
    }
}


.nav-toggle__box {
    position: absolute;

    &, &:after {
        transform: translate(-50%, -50%);
        transform-origin: 50% 50%;
        top: 50%;
        left: 50%;
    }

    &:after {
        content: "";
        position: absolute;
        height: $nav-toggle-width;
        width: $nav-toggle-width;
        border-radius: 100%;
        transition: $nav-toggle-transition;
        background-color: $brand-gray-98;
        border: 1px solid $brand-gray-80;
        z-index: -1;

        .offcanvas & {
            background-color: $brand-white;
        }

        .nav-toggle--small & {
            height: $nav-toggle-width-small;
            width: $nav-toggle-width-small;
        }
    }

    //.tpl-home &, .tpl-hero & {
    //    @include breakpoint(medium down) {
    //        &:after {
    //            border: 1px solid $brand-white;
    //            background-color: rgba(0, 0, 0, 0.05);
    //        }
    //    }
    //}
}

.nav-toggle__line {
    background-color: $nav-toggle-line-color;
    height: $nav-toggle-line-height;
    width: $nav-toggle-line-width;
    transform-origin: 50% 50%;
    transform-style: preserve-3d;
    transition: $nav-toggle-transition;
    z-index: 1;

    .nav-toggle--small & {
        height: $nav-toggle-line-height-small;
        width: $nav-toggle-line-width-small;
    }

    .overlay-menu & {
        background-color: $nav-toggle-line-overlay-color;
    }


    &:last-child {
        margin-top: $nav-toggle-line-spacing;

        .nav-toggle--small &{
            margin-top: $nav-toggle-line-spacing-small;
        }
    }
}

html:not(.header-compact-mode) {
    .tpl-hero {
        .nav-toggle__line {
            @include breakpoint(xlarge down) {
                background-color: $nav-toggle-line-hero-small-color;
                opacity: 1;
            }
        }

        .nav-toggle__box {
            @include breakpoint(xlarge down) {
                &:after {
                    border: 1px solid $brand-white;
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }
        }

        .nav-toggle:hover .nav-toggle__box {
            @include breakpoint(xlarge down) {
                &:after {
                    border: 1px solid $brand-white;
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }
        }
    }

    .tpl-home,
    .tpl-contact {
        .nav-toggle__line {
            @include breakpoint(medium down) {
                background-color: $nav-toggle-line-hero-small-color;
                opacity: 1;
            }
        }

        .nav-toggle__box {
            @include breakpoint(medium down) {
                &:after {
                    border: 1px solid $brand-white;
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }
        }

        .nav-toggle:hover .nav-toggle__box {
            @include breakpoint(medium down) {
                &:after {
                    border: 1px solid $brand-white;
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}

.softpage-visible:not(.softpage-visible-compact) {
   .tpl-hero .tingle-modal-header {
        .nav-toggle__line {
            @include breakpoint(xlarge down) {
                background-color: $nav-toggle-line-hero-small-color;
                opacity: 1;
            }
        }

        .nav-toggle__box {
            @include breakpoint(xlarge down) {
                &:after {
                    border: 1px solid $brand-white;
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }
        }

        .nav-toggle:hover .nav-toggle__box {
            @include breakpoint(xlarge down) {
                &:after {
                    border: 1px solid $brand-white;
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
   .tpl-contact .tingle-modal-header {
        .nav-toggle__line {
            @include breakpoint(medium down) {
                background-color: $nav-toggle-line-hero-small-color;
                opacity: 1;
            }
        }

        .nav-toggle__box {
            @include breakpoint(medium down) {
                &:after {
                    border: 1px solid $brand-white;
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }
        }

        .nav-toggle:hover .nav-toggle__box {
            @include breakpoint(medium down) {
                &:after {
                    border: 1px solid $brand-white;
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}



.softpage-visible.softpage-visible-compact {
  .tpl-hero .tingle-modal-header {
        .nav-toggle__line {
            @include breakpoint(xlarge down) {
                background-color: $nav-toggle-line-color;
            }
        }

        .nav-toggle__box {
            @include breakpoint(xlarge down) {
                &:after {
                    background-color: $brand-gray-98;
                    border: 1px solid $brand-gray-80;
                }
            }
        }

        .nav-toggle:hover .nav-toggle__box {
            @include breakpoint(xlarge down) {
                &:after {
                    background-color: $brand-primary-color-90;
                    border: 1px solid transparent;
                }
            }
        }
    }
  .tpl-contact .tingle-modal-header {
        .nav-toggle__line {
            @include breakpoint(medium down) {
                background-color: $nav-toggle-line-color;
            }
        }

        .nav-toggle__box {
            @include breakpoint(medium down) {
                &:after {
                    background-color: $brand-gray-98;
                    border: 1px solid $brand-gray-80;
                }
            }
        }

        .nav-toggle:hover .nav-toggle__box {
            @include breakpoint(medium down) {
                &:after {
                    background-color: $brand-primary-color-90;
                    border: 1px solid transparent;
                }
            }
        }
    }
}
