/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Makes embeded element responsive by using a wrapper with the class .embed-container

*/

.embed-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    // default ratio
    @include aspect-ratio(
        $video-embed-default-ratio-width,
        $video-embed-default-ratio-height
    );
    // overwrite default
    &.ratio-3-2 {
        @include aspect-ratio(3, 2);
    }
    &.ratio-2-1 {
        @include aspect-ratio(2, 1);
    }
    &.ratio-4-3 {
        @include aspect-ratio(4, 3);
    }
    &.ratio-1-1 {
        @include aspect-ratio(1, 1);
    }
    &.ratio-16-9 {
        @include aspect-ratio(16, 9);
    }

    > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
