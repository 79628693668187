/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=Breakpoints

*/

// All breakpoints that we use are defined here
$grid-breakpoints: (
    xs: 320px,
    sm: 560px,
    md: 800px,
    lg: 1040px,
    xl: 1280px,
    xxl: 1760px,
    0: 0px,
    1: 320px,
    2: 400px,
    3: 480px,
    4: 560px,
    5: 640px,
    6: 720px,
    7: 800px,
    8: 880px,
    9: 960px,
    10: 1040px,
    11: 1120px,
    12: 1200px,
    13: 1280px,
    14: 1360px,
    15: 1440px,
    16: 1520px,
    17: 1600px,
    18: 1680px,
    19: 1760px,
);

// Min and max breakpoints for fluid-sizing
$fluid-breakpoints: (
    min: map-get($grid-breakpoints, xs),
    // 320px
    max: map-get($grid-breakpoints, xxl),
    // 1760px
);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Grid (Bootstrap)

*/

//** Number of columns in the grid.
$grid-columns: 24;

//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width-xs: 1.25rem; // 20px
$grid-gutter-width: 3.75rem; // 60px

$page-padding-width-xs: 1.5625rem; // 25px
$page-padding-width: 5rem; // 80px

// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: map-get($grid-breakpoints, md);
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);
