@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot');
  src:  url('fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf') format('truetype'),
    url('fonts/icomoon.woff') format('woff'),
    url('fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-calendar:before {
  content: "\e60e";
}
.icomoon-arrow-full-down:before {
  content: "\e201";
}
.icomoon-arrow-full-up:before {
  content: "\e203";
}
.icomoon-softpage-close:before {
  content: "\e910";
}
.icomoon-instagram:before {
  content: "\e906";
}
.icomoon-plus:before {
  content: "\e90f";
}
.icomoon-arrow_external:before {
  content: "\e90e";
}
.icomoon-slider_left:before {
  content: "\e90b";
}
.icomoon-slider_right:before {
  content: "\e90d";
}
.icomoon-softpage:before {
  content: "\e908";
}
.icomoon-arrow_left:before {
  content: "\e909";
}
.icomoon-arrow_right:before {
  content: "\e90a";
}
.icomoon-error:before {
  content: "\e000";
}
.icomoon-warning:before {
  content: "\e002";
}
.icomoon-download:before {
  content: "\e0ff";
}
.icomoon-upload:before {
  content: "\e100";
}
.icomoon-check:before {
  content: "\e206";
}
.icomoon-close:before {
  content: "\e209";
}
.icomoon-help:before {
  content: "\e29b";
}
.icomoon-info:before {
  content: "\e2a2";
}
.icomoon-keyboard_arrow_up:before {
  content: "\e900";
}
.icomoon-keyboard_arrow_right:before {
  content: "\e901";
}
.icomoon-keyboard_arrow_left:before {
  content: "\e902";
}
.icomoon-keyboard_arrow_down:before {
  content: "\e903";
}
.icomoon-facebook:before {
  content: "\e907";
}
.icomoon-youtube:before {
  content: "\e904";
}
.icomoon-linkedin:before {
  content: "\e905";
}
.icomoon-search:before {
  content: "\e986";
}
.icomoon-stop2:before {
  content: "\ea1e";
}
.icomoon-folder-open:before {
  content: "\e90c";
}
