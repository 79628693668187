
/*
=typography

Notes:

- The root (default) font-size of a browser is 16px (unless the user
  changed it in the browser's settings).
- We use the default browser setting as well, to reduce zooming issues and to respect the user's browser setting.
- By setting the font-size of the <html> to 100%, the root font-size of the <html> is now 16px.
  Every pixel value must now be divided by 16 to get the same value in rems.
- But be aware that our font-system scales fluidly. In some circumstances it may never be exactly computed to 10px.

This results in:

1rem ~= 16px, 0.625rem ~= 10px, ...

*/

// defining font families
$font-family-primary: 'Söhne Buch', Helvetica, Arial, sans-serif;
$font-family-secondary: 'prestigeelitestd-bd', Helvetica, Arial, sans-serif;
$font-family-bold: 'Söhne Halbfett', Helvetica, Arial, sans-serif;

//$font-family-heading-bold: 'LouizeNPBol', serif;
$font-family-heading: $font-family-bold;

$font-family-semibold: $font-family-bold;
$font-family-italic: $font-family-bold;

// Regular
$font-primary-case-height: 0.70;
$font-primary-shortfall: 0.80;

// Secondary
$font-secondary-case-height: 0.70;
$font-secondary-shortfall: 0.60;

// Secondary Firefox
$font-secondary-fx-case-height: 0.90;
$font-secondary-fx-shortfall: 0.35;

// Heading
$font-heading-case-height: 0.70;
$font-heading-shortfall: 0.80;

// 14px - 12px
$font-tiny-case-height: 0.90;
$font-tiny-shortfall: 0.60;

$font-btn-case-height: $font-primary-case-height;
$font-btn-shortfall: $font-primary-shortfall;

$font-weight-bold: bold;
$font-weight-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-normal: normal;

$font-weight-heading: $font-weight-medium;
$font-weight-lead: $font-weight-medium;
$font-weight-default: $font-weight-normal;

$font-default-line-height: 1.6;
$font-lead-line-height: 1.3;
$font-tighter-line-height: 1.2;
$font-heading-line-height: 1.1;

$primary-letter-spacing: 0;
$secondary-letter-spacing: 0;

$font-size-h1-max: to-rem(60px);
$font-size-h1-min: to-rem(36px);

$font-size-h2-max: to-rem(50px);
$font-size-h2-min: to-rem(33px);

$font-size-h3-max: to-rem(42px);
$font-size-h3-min: to-rem(30px);

$font-size-h4-max: to-rem(34px);
$font-size-h4-min: to-rem(27px);

$font-size-h5-max: to-rem(28px);
$font-size-h5-min: to-rem(24px);

$font-size-lead-max: to-rem(28px);
$font-size-lead-min: to-rem(24px);

$font-size-h6-max: to-rem(24px);
$font-size-h6-min: to-rem(22px);

$font-size-lead-2-max: to-rem(24px);
$font-size-lead-2-min: to-rem(22px);

$font-size-default: to-rem(20px);
$font-size-default-tighter: to-rem(20px);

$font-size-small-1: to-rem(18px);
$font-size-small-2: to-rem(16px);
$font-size-small-3: to-rem(14px);
$font-size-small-4: to-rem(12px);


$font-capital-letter-primary: (
    top: $font-primary-case-height,
    bottom: $font-primary-shortfall,
);
$font-capital-letter-secondary: (
    top: $font-secondary-case-height,
    bottom: $font-secondary-shortfall,
);
$font-capital-fx-letter-secondary: (
    top: $font-secondary-fx-case-height,
    bottom: $font-secondary-fx-shortfall,
);
$font-capital-letter-heading: (
    top: $font-heading-case-height,
    bottom: $font-heading-shortfall,
);
$font-capital-letter-tiny: (
    top: $font-tiny-case-height,
    bottom: $font-tiny-shortfall,
);

// breakpoints used for font-sizes
$font-size-breakpoints: (
    min: map-get($grid-breakpoints, xs),
    max: map-get($grid-breakpoints, xxl),
);

// h1
$font-h1-font-sizes: (
    min: (
        $font-size-h1-min,
        $font-heading-line-height,
    ),
    max: $font-size-h1-max,
);
$font-h1-text-transform: none;
$font-h1-letter-spacing: 0;
$font-h1-antialiased-enabled: true;
$font-h1-font-weight: $font-weight-heading;
$font-h1-font-family: $font-family-heading;
$font-h1-crop-top: 0em;
$font-h1-crop-bottom: 0em;
$font-h1-crop: $font-capital-letter-heading;

// h2
$font-h2-font-sizes: (
    min: (
        $font-size-h2-min,
        $font-heading-line-height,
    ),
    max: $font-size-h2-max,
);
$font-h2-text-transform: none;
$font-h2-letter-spacing: 0;
$font-h2-antialiased-enabled: true;
$font-h2-font-weight: $font-weight-heading;
$font-h2-font-family: $font-family-heading;
$font-h2-crop-top: 0em;
$font-h2-crop-bottom: 0em;
$font-h2-crop: $font-capital-letter-heading;

// h3
$font-h3-font-sizes: (
    min: (
        $font-size-h3-min,
        $font-heading-line-height,
    ),
    max: $font-size-h3-max,
);
$font-h3-text-transform: none;
$font-h3-letter-spacing: 0;
$font-h3-antialiased-enabled: true;
$font-h3-font-weight: $font-weight-heading;
$font-h3-font-family: $font-family-heading;
$font-h3-crop-top: 0em;
$font-h3-crop-bottom: 0em;
$font-h3-crop: $font-capital-letter-heading;

// h4
$font-h4-font-sizes: (
    min: (
        $font-size-h4-min,
        $font-heading-line-height,
    ),
    max: $font-size-h4-max,
);
$font-h4-text-transform: none;
$font-h4-letter-spacing: 0;
$font-h4-antialiased-enabled: true;
$font-h4-font-weight: $font-weight-heading;
$font-h4-font-family: $font-family-heading;
$font-h4-crop-top: 0em;
$font-h4-crop-bottom: 0em;
$font-h4-crop: $font-capital-letter-heading;

// h5
$font-h5-font-sizes: (
    min: (
        $font-size-h5-min,
        $font-heading-line-height,
    ),
    max: $font-size-h5-max,
);
$font-h5-text-transform: none;
$font-h5-letter-spacing: 0;
$font-h5-antialiased-enabled: true;
$font-h5-font-weight: $font-weight-heading;
$font-h5-font-family: $font-family-heading;
$font-h5-crop-top: 0em;
$font-h5-crop-bottom: 0em;
$font-h5-crop: $font-capital-letter-heading;

// text: lead
$font-lead-font-sizes: (
    min: (
        $font-size-lead-min,
        $font-lead-line-height,
    ),
    max: $font-size-lead-max,
);
$font-lead-text-transform: none;
$font-lead-letter-spacing: 0;
$font-lead-antialiased-enabled: true;
$font-lead-font-weight: $font-weight-lead;
$font-lead-font-family: $font-family-heading;
$font-lead-crop-top: 0em;
$font-lead-crop-bottom: 0em;
$font-lead-crop: $font-capital-letter-primary;

// h6
$font-h6-font-sizes: (
    min: (
        $font-size-h6-min,
        $font-heading-line-height,
    ),
    max: $font-size-h6-max,
);
$font-h6-text-transform: none;
$font-h6-letter-spacing: 0;
$font-h6-antialiased-enabled: true;
$font-h6-font-weight: $font-weight-heading;
$font-h6-font-family: $font-family-heading;
$font-h6-crop-top: 0em;
$font-h6-crop-bottom: 0em;
$font-h6-crop: $font-capital-letter-heading;

// text: lead 2
$font-lead-2-font-sizes: (
    min: (
        $font-size-lead-2-min,
        $font-lead-line-height,
    ),
    max: $font-size-lead-2-max,
);
$font-lead-2-text-transform: none;
$font-lead-2-letter-spacing: $primary-letter-spacing;
$font-lead-2-antialiased-enabled: true;
$font-lead-2-font-weight: $font-weight-lead;
$font-lead-2-font-family: $font-family-primary;
$font-lead-2-crop-top: 0em;
$font-lead-2-crop-bottom: 0em;
$font-lead-2-crop: $font-capital-letter-primary;

// the default font settings (body)
$font-default-font-sizes: (
    min: (
        $font-size-default,
        $font-default-line-height,
    ),
);
$font-default-text-transform: none;
$font-default-letter-spacing: $primary-letter-spacing;
$font-default-antialiased-enabled: true;
$font-default-font-weight: $font-weight-default;
$font-default-font-family: $font-family-primary;
$font-default-crop-top: 0em;
$font-default-crop-bottom: 0em;
$font-default-crop: $font-capital-letter-primary;

// text: tighter
$font-default-tighter-font-sizes: (
    min: (
        $font-size-default-tighter,
        $font-tighter-line-height,
    ),
);
$font-default-tighter-text-transform: none;
$font-default-tighter-letter-spacing: $primary-letter-spacing;
$font-default-tighter-antialiased-enabled: true;
$font-default-tighter-font-weight: $font-weight-default;
$font-default-tighter-font-family: $font-family-primary;
$font-default-tighter-crop-top: 0em;
$font-default-tighter-crop-bottom: 0em;
$font-default-tighter-crop: $font-capital-letter-primary;

// text: small
$font-small-font-sizes: (
    min: (
        $font-size-small-1,
        $font-tighter-line-height,
    ),
);
$font-small-text-transform: none;
$font-small-letter-spacing: $primary-letter-spacing;
$font-small-antialiased-enabled: true;
$font-small-font-weight: $font-weight-default;
$font-small-font-family: $font-family-primary;
$font-small-crop-top: 0em;
$font-small-crop-bottom: 0em;
$font-small-crop: $font-capital-letter-primary;

// text: small 2
$font-small-2-font-sizes: (
    min: (
        $font-size-small-2,
        $font-tighter-line-height,
    ),
);
$font-small-2-text-transform: none;
$font-small-2-letter-spacing: $primary-letter-spacing;
$font-small-2-antialiased-enabled: true;
$font-small-2-font-weight: $font-weight-default;
$font-small-2-font-family: $font-family-primary;
$font-small-2-crop-top: 0em;
$font-small-2-crop-bottom: 0em;
$font-small-2-crop: $font-capital-letter-primary;

// text: small 3
$font-small-3-font-sizes: (
    min: (
        $font-size-small-3,
        $font-tighter-line-height,
    ),
);
$font-small-3-text-transform: none;
$font-small-3-letter-spacing: $primary-letter-spacing;
$font-small-3-antialiased-enabled: true;
$font-small-3-font-weight: $font-weight-default;
$font-small-3-font-family: $font-family-primary;
$font-small-3-crop-top: 0em;
$font-small-3-crop-bottom: 0em;
$font-small-3-crop: $font-capital-letter-tiny;

// text: small 4
$font-small-4-font-sizes: (
    min: (
        $font-size-small-4,
        $font-tighter-line-height,
    ),
);
$font-small-4-text-transform: none;
$font-small-4-letter-spacing: $primary-letter-spacing;
$font-small-4-antialiased-enabled: true;
$font-small-4-font-weight: $font-weight-default;
$font-small-4-font-family: $font-family-primary;
$font-small-4-crop-top: 0em;
$font-small-4-crop-bottom: 0em;
$font-small-4-crop: $font-capital-letter-tiny;

// button: large
$font-btn-large-font-sizes: (
    min: (
        to-rem(20px),
        1.25,
    ),
    max: to-rem(20px),
);
$font-btn-large-text-transform: uppercase;
$font-btn-large-letter-spacing: 0;
$font-btn-large-antialiased-enabled: true;
$font-btn-large-font-weight: $font-weight-normal;
$font-btn-large-font-family: $font-family-secondary;

// button: default
$font-btn-default-font-sizes: (
    min: (
        to-rem(16px),
        1.25,
    ),
);
$font-btn-default-text-transform: uppercase;
$font-btn-default-letter-spacing: 0;
$font-btn-default-antialiased-enabled: true;
$font-btn-default-font-weight: $font-weight-normal;
$font-btn-default-font-family: $font-family-secondary;
$font-btn-default-crop: $font-capital-letter-secondary;

// button: small
$font-btn-small-font-sizes: (
    min: (
        to-rem(16px),
        1.25,
    ),
);
$font-btn-small-text-transform: uppercase;
$font-btn-small-letter-spacing: 0;
$font-btn-small-antialiased-enabled: true;
$font-btn-small-font-weight: $font-weight-normal;
$font-btn-small-font-family: $font-family-secondary;

// input fields (text, select, textarea)
$font-input-font-sizes: (
    min: (
        to-rem(20px),
        1.25,
    ),
);
$font-input-text-transform: $font-default-text-transform;
$font-input-letter-spacing: $font-default-letter-spacing;
$font-input-antialiased-enabled: $font-default-antialiased-enabled;
$font-input-font-weight: $font-default-font-weight;
$font-input-font-family: $font-default-font-family;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=heading Colors (=heco)

*/

$h1-color: $body-color;
$h1-color-inverted: $body-inverted-color;
$h2-color: $body-color;
$h2-color-inverted: $body-inverted-color;
$h3-color: $body-color;
$h3-color-inverted: $body-inverted-color;
$h4-color: $body-color;
$h4-color-inverted: $body-inverted-color;
$h5-color: $body-color;
$h5-color-inverted: $body-inverted-color;
$h6-color: $body-color;
$h6-color-inverted: $body-inverted-color;

//** Global text color on `<body>`.
$text-color: $body-color !default;

//** Global textual link color.
$link-color: $brand-color-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%) !default;
//** Link hover decoration.
$link-hover-decoration: underline !default;

$font-size-base: 14px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(
    ($font-size-base * $line-height-base)
) !default; // ~20px
