/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Text Fields

*/
// Variables
$form-control-bootstrap-select-background-color: transparent !default;

.form-control {
    font-size: inherit;
    color: $input-color;
    background-color: $input-bg;
    display: block;
    height: auto;
    width: 100%;
    border-style: $form-field-border-style;
    border-width: $form-field-border-width;
    border-top-color: $input-border-top-color;
    border-right-color: $input-border-right-color;
    border-bottom-color: $input-border-bottom-color;
    border-left-color: $input-border-left-color;
    border-radius: $form-field-border-radius-default;
    padding-left: $input-padding-left;
    padding-right: $input-padding-right;
    // applying button padding (top/bottom) to make them equally high in case of input-groups
    padding-top: $btn-padding-top;
    padding-bottom: $btn-padding-bottom;
    box-shadow: none;
    transition: $form-field-transition;
    // iPad improvements
    -webkit-appearance: none;

    &:hover {
        border-top-color: $input-border-top-color-hover;
        border-right-color: $input-border-right-color-hover;
        border-bottom-color: $input-border-bottom-color-hover;
        border-left-color: $input-border-left-color-hover;
    }

    &:focus {
        background-color: $input-bg-focus;
        border-top-color: $input-border-top-color-focus;
        border-right-color: $input-border-right-color-focus;
        border-bottom-color: $input-border-bottom-color-focus;
        border-left-color: $input-border-left-color-focus;
        box-shadow: none;
        outline: 0;
    }

    // error styles
    .has-error &,
    &.error {
        &,
        &:focus {
            border-top-color: $input-error-border-top-color;
            border-right-color: $input-error-border-right-color;
            border-bottom-color: $input-error-border-bottom-color;
            border-left-color: $input-error-border-left-color;
            background-color: $input-error-bg;
            color: $input-error-color;
            box-shadow: none;
        }
    }

    .inverted-colors & {
        color: $input-inverted-color;
        background-color: $input-inverted-bg;
        border-style: $form-field-inverted-border-style;
        border-width: $form-field-inverted-border-width;
        border-top-color: $input-inverted-border-top-color;
        border-right-color: $input-inverted-border-right-color;
        border-bottom-color: $input-inverted-border-bottom-color;
        border-left-color: $input-inverted-border-left-color;
        border-radius: $form-field-inverted-border-radius-default;
        padding-left: $input-inverted-padding-left;
        padding-right: $input-inverted-padding-right;
        transition: $form-field-inverted-transition;

        &:focus {
            background-color: $input-inverted-bg-focus;
            border-top-color: $input-inverted-border-top-color-focus;
            border-right-color: $input-inverted-border-right-color-focus;
            border-bottom-color: $input-inverted-border-bottom-color-focus;
            border-left-color: $input-inverted-border-left-color-focus;
        }

        &.error {
            border-top-color: $input-inverted-error-border-top-color;
            border-right-color: $input-inverted-error-border-right-color;
            border-bottom-color: $input-inverted-error-border-bottom-color;
            border-left-color: $input-inverted-error-border-left-color;
            background-color: $input-inverted-error-bg;
            color: $input-inverted-error-color;
        }
    }

    // reset bg color in case of a bootstrap-select container
    &.bootstrap-select {
        background-color: $form-control-bootstrap-select-background-color !important;
    }
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Placeholder (default input)

*/

@include placeholder($input-color-placeholder);

.inverted-colors {
    @include placeholder($input-inverted-color-placeholder);
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Input type "number" - let's get rid of the ugly arrows

*/

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}



.form-default {
    .control-label {
        @include font-small();

        display: block;

        &__hint {
            @include font-small-3();

            display: inline-block;
            color: $color-muted-primary;
        }
    }

    .form__col--half {
        @include make-row();

        .form-group {
            @include make-col-ready();

            @include media-breakpoint-up(sm) {
                @include make-col(12);

                margin-top: 0;
            }
        }
    }
}

