/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

List View (this includes ALL plugin templates such as Grid (Static), Table, and co)

*/

.app-list {
    overflow-anchor: none; // to prevent jumping on ajax load more
    // fixes overlap issue with negativ margins (e.g. grid static followd by a button link container)
    position: relative;
    z-index: 1;

    + .content-plugin {
        position: relative;
        z-index: 2;
    }

    &__no-results {
        @include fluid-size(
                (
                    min: $app-list-no-result-cotainer-min-height-min,
                    max: $app-list-no-result-cotainer-min-height-max,
                ),
                'min-height'
        );
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Detail View

*/

.app-detail {
    // optionally: set max width of detail view
    .content-section__container {
        @if $app-detail-content-container-max-width-xs {
            max-width: $app-detail-content-container-max-width-xs !important;
        }

        @if $app-detail-content-container-max-width-xxl {
            @include media-breakpoint-up(xxl) {
                max-width: $app-detail-content-container-max-width-xxl !important;
            }
        }

        @if $app-detail-content-container-softpage-max-width-xs {
            .softpage & {
                max-width: $app-detail-content-container-softpage-max-width-xs !important;
            }
        }

        @if $app-detail-content-container-softpage-max-width-xxl {
            .softpage & {
                @include media-breakpoint-up(xxl) {
                    max-width: $app-detail-content-container-softpage-max-width-xxl !important;
                }
            }
        }
    }

    // within modal, add some spacing
    .tingle-modal & {
        @include app-detail-softpage-spacing();
    }

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Header

    */

    &__header {
        @include app-detail-header-section-spacing(
                $app-detail-header-section-spacing-property
        );
        // in case no preview image is set or no plugin has been added to the header section
        &--empty {
            margin: 0;
            // within softage
            .softpage & {
                display: none;
            }
        }

        // within softage
        .softpage & {
            margin-top: 0;
            padding-top: 0;
        }
    }

    &__header-col {
        @include media-breakpoint-up(xs) {
            @include make-col(24);
        }
    }

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Content Structure

    */

    &__content {
        padding-top: 0;
        margin-top: 0;
        // in case the content section is empty
        &--empty {
            display: none;
        }
    }

    &__template__col-1 {
        .app-detail__col-1 {
            @include media-breakpoint-up(xs) {
                @include make-col(24);
            }
        }
    }

    // the actual container that holds the plugins
    &__content-container {
        // reset container padding, but leave it in case the content-section has a background image/video/color
        .content-section:not(.has-bg-image):not(.has-bg-video):not(.has-bg-color) {
            .content-section__container {
                padding: 0;
            }
        }

        // remove spacing for first content section
        .content-section {
            &.first {
                margin-top: 0;

                &:not(.has-bg-image):not(.has-bg-video):not(.has-bg-color) {
                    padding-top: 0;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Heading

    */

    &__heading-container {
        @include app-detail-heading-container-spacing-bottom();
    }

    &__heading {
        @include font-h2();

        @include media-breakpoint-up(xs) {
            @include make-col(24);
        }
    }

    &__created {
        @include media-breakpoint-up(xs) {
            @include make-col(24);
        }

        @include app-detail-created-spacing-top();
    }

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Content

    */

    &__lead {
        @include font-lead();

        + .app-detail__content-container {
            @include app-detail-spacing-between-lead-and-content();
        }
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Items loaded with AJAX

*/

// default container
.ajax-items-container {
    // prepare immediate children of the container for the transition
    @include the-loader();

    &::before {
        opacity: 0;
        margin-top: 0;
        transition: $load-more-swop-category-transition-property $load-more-swop-category-transition-duration $load-more-swop-category-transition-timing-function;
        top: $load-more-swop-category-loader-spacing-top-xs;

        @include media-breakpoint-up(sm) {
            top: $load-more-swop-category-loader-spacing-top-sm;
        }
    }

    > * {
        opacity: 1;
        transition: $load-more-swop-category-transition-property $load-more-swop-category-transition-duration $load-more-swop-category-transition-timing-function;
    }

    // loading effect when switching category
    &.loading {
        // loader
        &::before {
            transition-delay: 0ms;
            opacity: 1;
        }

        // reveal any children
        > * {
            opacity: 0;
        }
    }

    // all appended items get the following class
    .appended {
        animation: fadeIn 400ms;
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

The 'load more' container and button

*/

.load-more-container {
    clear: both;
    text-align: center;
    opacity: 1;
    transition: $load-more-swop-category-transition-property $load-more-swop-category-transition-duration $load-more-swop-category-transition-timing-function;
    // loading effect when switching category
    &.loading {
        opacity: 0;
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Filter Container

*/

.filter-container {
    & + * {
        @include spacings-size($filter-container-spacing-bottom, margin-top);
    }
}

.filter-container-inner {
    border: none;
    display: flex;
    align-items: baseline;
}

.dropdown {
    cursor: pointer !important;

}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Detail View

*/

.app-detail {
    &__header {
        max-width: (1600px);
        margin-left: auto;
        margin-right: auto;

        &.container-enabled .content-section__container {
            padding-left: 0;
            padding-right: 0;
        }

        @include media-breakpoint-between(17, 18) {
            @include spacings-size('x-page-padding', 'padding-left');
            @include spacings-size('x-page-padding', 'padding-right');

            max-width: none;
        }

        .image-container,
        .swiper-container,
        .video-file-plugin {
            position: relative;
            height: 70vh;
            @include viewport-fluid-size(40vh, max-height);

            @include breakpoint(medium) {
                @include viewport-fluid-size(50vh, max-height);
            }

            @include breakpoint(large) {
                @include viewport-fluid-size(70vh, max-height);
            }
            //
            //@include media-breakpoint-up(19) {
            //     max-height: 51rem;
            // }

            picture {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding-bottom: 0 !important;

                img {

                    @include breakpoint(small only) {
                        object-fit: cover;
                    }
                    @include breakpoint(medium) {
                        height: auto;
                    }
                }
            }


        }

        .video-file-plugin {
            padding-bottom: 0 !important;

            &,
            .the-video {
                height: 100%;
            }

            .the-video {
                object-fit: cover;
            }
        }
    }

    &__heading-container {
        display: flow-root;

        h1 {
            @include font-h1();
        }
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Border of the load more button

 */

.grid-border-bottom {
    width: 100%;
    //border-bottom: to-rem(1px) solid $color-line-secondary;
}

.load-more-button__container {
    position: relative;
    display: flex;
    //align-items: center;
    //@include spacings-size('spacing-scale-2', 'padding-top');
    //@include spacings-size('spacing-equal-5',' padding-bottom');
    margin-left: auto;
    background: $body-background-color;
    width: 100%;
    margin-right: auto;
    margin-top: 0;
    height: 1.5rem;


    &:before, &:after {
        position: relative;
        content: "";
        height: 1px;
        width: 100%;
        transform: translateY(8px);
        background: $color-line-secondary;
    }
}

.load-more-button {
    position: relative;
    display: block;
    width: fit-content;
    flex: 1 0 auto;
    text-decoration: none;
    padding: 0 1.5rem;
    transition: color $global-color-transition-duration $global-color-timing-function;
}

.no-results-container {
    @include fluid-size(
            (
                min: $page-padding-width-xs / 2,
                max: $page-padding-width / 2,
            ),
            'margin-left'
    );
}
