.poster-only-on-mobile {
    .video-controls {
        display: none;
    }
}

.the-video {
    &.IIV {
        &::-webkit-media-controls-play-button,
        &::-webkit-media-controls-start-playback-button {
            opacity: 0;
            pointer-events: none;
            width: 5px;
        }
    }
}

.video-file-plugin {
    position: relative;
    background: $lazyloader-bg-color;

    .the-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        opacity: 0;
        transition: opacity $lazyloader-transition-duration ease-in-out;

        &.lazyloaded {
            opacity: 1;
        }
    }

    .video-controls {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;

        span {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition-property: $video-controls-transition-property;
            transition-duration: $video-controls-transition-duration;
            transition-timing-function: $video-controls-transition-timing-function;
            // on mobile, show controls in any case
            @include media-breakpoint-down(sm) {
                visibility: visible;
                opacity: 1;
            }
        }

        @if $video-controls-image-path-play {
            span {
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url($video-controls-image-path-play);
                width: $video-controls-image-width-xs;
                height: $video-controls-image-height-xs;

                @include media-breakpoint-up(md) {
                    width: $video-controls-image-width-md;
                    height: $video-controls-image-height-md;
                }

                @include media-breakpoint-up(xl) {
                    width: $video-controls-image-width-xl;
                    height: $video-controls-image-height-xl;
                }
            }
        } @else {
            span {
                width: $video-controls-fallback-width;
                height: $video-controls-fallback-width;
                border-radius: 50%;
                background-color: $video-controls-fallback-bg;

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin-left: $video-controls-fallback-arrow-offset;
                    border-style: solid;
                    border-width: ($video-controls-fallback-arrow-height / 2)
                        0
                        ($video-controls-fallback-arrow-height / 2)
                        $video-controls-fallback-arrow-width;
                    border-color: transparent
                        transparent
                        transparent
                        $video-controls-fallback-arrow-color;
                }
            }

            &:hover,
            &:focus {
                span {
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }
        }
    }
    // autoplay enabled
    &.autoplay-enabled,
    &.autoplay-mobile-enabled {
        .the-video {
            transition: none;
            opacity: 1;
        }

        @include media-breakpoint-up(md) {
            .video-controls {
                display: none;
                cursor: default;
            }
        }
    }
    // on pause
    &.on-pause {
        .video-controls {
            span {
                visibility: visible;
                opacity: 1;
            }
        }
    }
    // playing
    &.playing {
        .video-controls {
            span {
                visibility: hidden;
                opacity: 0;
            }
        }
    }
    // on mobile, only show poster
    &.poster-only-on-mobile {
        &.controls-disabled {
            .video-controls {
                display: none;
            }
        }
    }

    // hide controls when autoplay is enabled on mobile
    &.autoplay-mobile-enabled {
        .video-controls {
            display: none;
        }
    }
}

// Project Specific # Grauwiler

.video-badge {
    position: absolute;
    @include spacings-size('y-scale-5', bottom);
    @include spacings-size('x-scale-5', left);
    top: auto;
    z-index: 50;

    @include breakpoint(large) {
        @include spacings-size('y-scale-5', top);
        bottom: auto;
    }

    svg {
        @include fluid-size(
                (
                    min: rem-calc(100px),
                    max: rem-calc(140px),
                ),
                'width'
        );
        @include fluid-size(
                (
                    min: rem-calc(100px),
                    max: rem-calc(140px),
                ),
                'height'
        );
    }
}
