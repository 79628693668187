/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Global Modal Styles

*/


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Django CMS specific adjustments

Position when logged in. This prevents overlapping with the CMS toolbar.

*/

html.cms-ready {
    // collapsed
    .tingle-modal,
    .browser-suggestion {
        top: $cms-toolbar-height-collapsed;

        @include media-breakpoint-up(sm) {
            .tingle-modal__close {
                top: $cms-toolbar-height-collapsed;
            }
        }
    }
    // toolbar expanded
    &.cms-toolbar-expanded {
        .tingle-modal,
        .browser-suggestion {
            top: $cms-toolbar-height-expanded;
        }

        @include media-breakpoint-up(sm) {
            .tingle-modal__close {
                top: $cms-toolbar-height-expanded;
            }
        }
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Global modal definitions

*/

body.tingle-enabled {
    height: auto;

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Print Settings for modal

    */
    @media print {
        height: auto !important;
        margin: 0 !important;
        overflow: visible !important;

        #cms-top,
        .site-header,
        .site-content,
        .site-footer {
            display: none;
        }
    }

    // except when softpage is visible
    :not(.softpage-visible) {
        @include media-breakpoint-down(sm) {
            .site-header,
            .site-content,
            .site-footer {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

.tingle-modal {
    // fixes bug that selects text when closing the modal by clicking on the overlay
    -moz-user-select: none;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    // positioning fix on mobile
    @include media-breakpoint-down(xs) {
        top: 0;
    }
    // add correct pointer
    cursor: pointer;

    &.tingle-modal--noOverlayClose {
        cursor: default;
    }
    // reset positioning styles in print mode
    @media print {
        position: static;
        display: block;
        height: auto !important;
        margin: 0 !important;
        overflow: visible !important;
    }
}

// remove default padding when overflow
.tingle-modal--overflow {
    padding-top: 0;
}

// reset transform per default, and add project specific if desired
.tingle-modal-box {
    // make sure text within the modal is selectable
    -moz-user-select: text;
    // trans
    transform: none;
    .tingle-modal--visible & {
        transform: none;
    }

    @include print() {
        position: static !important;
    }
}

.tingle-modal--visible {
    @media print {
        position: static !important;
        overflow: visible !important;
        top: 0 !important;
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Used to be on project basis

*/

.tingle-modal {
    //background: $modal-global-bg;
    cursor: pointer;
    transition: $modal-global-overlay-transition;
    z-index: z('modal');
}

.tingle-modal-box {
    transition: none;
    border-radius: 0;
    background-color: $modal-global-content-bg;
    min-height: 100%;
    //
    //@include breakpoint(xxlarge) {
    //    @include spacings-size("y-nav-full", padding-top)
    //}
}

.tingle-modal__close {
    transition: none;
    background: transparent;
    border-radius: 0;

    @include print() {
        display: none !important;
    }

    &:hover,
    &:focus,
    &:active {
        &::before {
            color: $modal-global-close-icon-color-hover;
        }
    }

    &::before {
        content: $modal-global-close-icon;
        position: absolute;
        color: $modal-global-close-icon-color;
        font-size: $modal-global-close-icon-size;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition-property: $modal-global-close-icon-transition-property;
        transition-duration: $modal-global-close-icon-transition-duration;
        transition-timing-function: $modal-global-close-icon-transition-timing-function;

        @include icomoon();
    }

    > span {
        text-indent: -9999px;
        display: inline-block;
    }

    @include media-breakpoint-down(xs) {
        transform: translate3d(0, 0, 10px);
        position: absolute !important;
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Modal Header

*/

.tingle-modal-header {
    //@include make-container();
    //@include max-width();

    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: z('modal');
    width: 100%;
       background: none;

    &__container {
        position: relative;
        display: block;
        //
        //&::after {
        //    content: '';
        //    display: block;
        //    position: relative;
        //    z-index: 1;
        //    border-bottom: 1px solid $modal-header-bg-color;
        //    margin-top: -1px;
        //}
    }

    &__inner {
        display: flex;
        align-items: center;
        @include spacings-size("y-nav-full", height)
    }

    &__heading {
        cursor: text;
        display: block;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 25px;
    }

    &__link-close {
        background: transparent;
        border: none;
        height: 50px;
        position: absolute;
        right: -11px;
        transform: translateY(-50%);
        transition: none;
        text-decoration: none;
        top: 50%;
        width: 50px;

        &:after {
            content: "";
            position: absolute;
            height: to-rem(50);
            width: to-rem(50);
            border-radius: 100%;
            transition: $nav-toggle-transition;
            background-color: $brand-gray-98;
            border: 1px solid $brand-gray-80;
            z-index: -1;
        }

        //&:hover,
        //&:focus,
        //&:active {
        //    &::before {
        //        color: $modal-global-close-icon-color-hover;
        //    }
        //}

        //&::before {
        //    @include icomoon();
        //
        //    color: $modal-global-close-icon-color;
        //    content: $modal-global-close-icon;
        //    font-size: $modal-global-close-icon-size;
        //    left: 50%;
        //    position: absolute;
        //    top: 50%;
        //    transform: translate(-50%, -50%);
        //    transition-property: $modal-global-close-icon-transition-property;
        //    transition-duration: $modal-global-close-icon-transition-duration;
        //    transition-timing-function: $modal-global-close-icon-transition-timing-function;
        //}
    }
}

.tingle-modal-header__container {
  @include set-site-header-height();
    @include make-container();
    @include max-width();
    position: absolute;
    display: flex;
    justify-content: flex-end;
    top: 0;
    left: 0;
    right: 0;
    z-index: z('toggle');
    pointer-events: none;
}


.tingle-modal-header__line {
    background-color: $nav-toggle-line-color;
    height: $nav-toggle-line-height;
    width: $nav-toggle-line-width;
    transform-origin: 50% 50%;
    transform-style: preserve-3d;
    transition: $nav-toggle-transition;
    z-index: 1;

    &:first-child {
        transform: translateY(0.3rem) rotateZ(-45deg);
    }

    &:last-child {
        transform: translateY(-0.3rem) rotateZ(45deg);
    }
}
