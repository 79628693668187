/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

default teaser variables

*/

// general
$default-teaser-background-color: $brand-white;
$default-teaser-horizontal-background-color: $brand-color-teaser-2;
$default-teaser-background-color-hover: $default-teaser-background-color;
$default-teaser-box-shadow: none;
$default-teaser-box-shadow-hover: none;
$default-teaser-transition: background-color 300ms ease;
$default-border-color: $brand-gray-80;
$default-border-horizontal-color: transparent;

// content spacing + color
$default-teaser-content-padding-top: 'y-scale-4';
$default-teaser-content-padding-bottom: 'y-scale-4';
$default-teaser-content-padding-left: 'x-scale-4';
$default-teaser-content-padding-right: 'x-scale-4';

$default-teaser-content-color: $color-default-primary;

// technical title spacing + color
$default-teaser-tech-title-margin-bottom: 'y-equal-6';
$default-teaser-tech-title-color: $color-default-primary;

// title spacing + color
$default-teaser-title-margin-bottom: 'y-scale-6';
$default-teaser-title-color: $color-default-primary;

// description spacing
$default-teaser-description-margin-bottom: 'y-scale-5';
$default-teaser-description-max-width: to-rem(600px);
$default-teaser-description-color: $color-default-primary;

// link spacing + variations
$default-teaser-link-margin-top: $default-teaser-description-margin-bottom;
$default-teaser-button-variation: 'default';
$default-teaser-button-mask-effect: 'default';


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

tile

*/

$tile-teaser-background-color: $default-teaser-background-color;
$tile-teaser-box-shadow: $default-teaser-box-shadow;
$tile-teaser-box-shadow-hover: $default-teaser-box-shadow-hover;
$tile-teaser-transition: $default-teaser-transition;

// content spacing
$tile-teaser-content-padding-top: $default-teaser-content-padding-top;
$tile-teaser-content-padding-bottom: $default-teaser-content-padding-bottom;
$tile-teaser-content-padding-left: $default-teaser-content-padding-left;
$tile-teaser-content-padding-right: $default-teaser-content-padding-right;

// Horizontal

$tile-horizontal-teaser-content-padding-top: 'y-scale-2';
$tile-horizontal-teaser-content-padding-bottom: 'y-scale-2';
$tile-horizontal-teaser-content-padding-left: 'y-scale-2';
$tile-horizontal-teaser-content-padding-right: 'y-scale-2';

$tile-teaser-content-color: $default-teaser-content-color;

// technical title spacing + color
$tile-teaser-tech-title-margin-bottom: $default-teaser-tech-title-margin-bottom;
$tile-teaser-tech-title-color: $default-teaser-tech-title-color;

// title spacing + color
$tile-teaser-title-margin-bottom: $default-teaser-title-margin-bottom;
$tile-teaser-title-color: $default-teaser-title-color;

// description spacing + color
$tile-teaser-description-margin-bottom: 0;
$tile-teaser-description-max-width: $default-teaser-description-max-width;
$tile-teaser-description-color: $default-teaser-description-color;

// link spacing + variations
$tile-teaser-link-margin-top: $default-teaser-link-margin-top;
$tile-teaser-button-variation: $default-teaser-button-variation;
$tile-teaser-button-mask-effect: $default-teaser-button-mask-effect;


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

bg image

*/

$bgimage-teaser-image-overlay-color: $brand-black;
$bgimage-teaser-image-overlay-opacity: 0.5;

$bgimage-teaser-background-color: $default-teaser-background-color;
$bgimage-teaser-box-shadow: none;
$bgimage-teaser-box-shadow-hover: none;
$bgimage-teaser-transition: none;

// content spacing
$bgimage-teaser-content-padding-top: $default-teaser-content-padding-top;
$bgimage-teaser-content-padding-bottom: $default-teaser-content-padding-bottom;
$bgimage-teaser-content-padding-left: $default-teaser-content-padding-left;
$bgimage-teaser-content-padding-right: $default-teaser-content-padding-right;
$bgimage-teaser-content-color: $color-default-secondary;

// technical title spacing + color
$bgimage-teaser-tech-title-margin-bottom: $default-teaser-tech-title-margin-bottom;
$bgimage-teaser-tech-title-color: $color-default-secondary;

// title spacing
$bgimage-teaser-title-margin-bottom: $default-teaser-title-margin-bottom;
$bgimage-teaser-title-color: $color-default-secondary;

// description spacing
$bgimage-teaser-description-margin-bottom: $default-teaser-description-margin-bottom;
$bgimage-teaser-description-max-width: $default-teaser-description-max-width;
$bgimage-teaser-description-color: $color-default-secondary;

// link spacing + variations
$bgimage-teaser-link-margin-top: $default-teaser-link-margin-top;
$bgimage-teaser-button-variation: 'inverted';
$bgimage-teaser-button-mask-effect: 'inverted';


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

list

*/

$list-teaser-background-color: $default-teaser-background-color;
$list-teaser-box-shadow: $default-teaser-box-shadow;
$list-teaser-box-shadow-hover: $default-teaser-box-shadow-hover;
$list-teaser-transition: $default-teaser-transition;

// content spacing
$list-teaser-content-padding-top: $default-teaser-content-padding-top;
$list-teaser-content-padding-bottom: $default-teaser-content-padding-bottom;
$list-teaser-content-padding-left: $default-teaser-content-padding-left;
$list-teaser-content-padding-right: $default-teaser-content-padding-right;
$list-teaser-content-color: $default-teaser-content-color;

// technical title spacing + color
$list-teaser-tech-title-margin-bottom: $default-teaser-tech-title-margin-bottom;
$list-teaser-tech-title-color: $default-teaser-tech-title-color;

// title spacing + color
$list-teaser-title-margin-bottom: $default-teaser-title-margin-bottom;
$list-teaser-title-color: $default-teaser-title-color;

// description spacing + color
$list-teaser-description-margin-bottom: $default-teaser-description-margin-bottom;
$list-teaser-description-max-width: $default-teaser-description-max-width;
$list-teaser-description-color: $default-teaser-description-color;

// link spacing + variations
$list-teaser-link-margin-top: 0;
$list-teaser-button-variation: $default-teaser-button-variation;
$list-teaser-button-mask-effect: $default-teaser-button-mask-effect;


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

full width

*/

$fullwidth-teaser-image-overlay-color: $brand-black;
$fullwidth-teaser-image-overlay-opacity: 0.5;

$fullwidth-teaser-background-color: $default-teaser-background-color;
$fullwidth-teaser-box-shadow: none;
$fullwidth-teaser-box-shadow-hover: none;
$fullwidth-teaser-transition: none;

// content spacing
$fullwidth-teaser-content-padding-top: $default-teaser-content-padding-top;
$fullwidth-teaser-content-padding-bottom: $default-teaser-content-padding-bottom;
$fullwidth-teaser-content-padding-left: $default-teaser-content-padding-left;
$fullwidth-teaser-content-padding-right: $default-teaser-content-padding-right;
$fullwidth-teaser-content-color: $color-default-secondary;

// technical title spacing + color
$fullwidth-teaser-tech-title-margin-bottom: $default-teaser-tech-title-margin-bottom;
$fullwidth-teaser-tech-title-color: $color-default-secondary;

// title spacing
$fullwidth-teaser-title-margin-bottom: $default-teaser-title-margin-bottom;
$fullwidth-teaser-title-color: $color-default-secondary;

// description spacing
$fullwidth-teaser-description-margin-bottom: $default-teaser-description-margin-bottom;
$fullwidth-teaser-description-max-width: $default-teaser-description-max-width;
$fullwidth-teaser-description-color: $color-default-secondary;

// link spacing + variations
$fullwidth-teaser-link-margin-top: $default-teaser-link-margin-top;
$fullwidth-teaser-button-variation: 'inverted';
$fullwidth-teaser-button-mask-effect: 'inverted';


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

full width parallax

*/

$parallax-teaser-image-overlay-color: $fullwidth-teaser-image-overlay-color;
$parallax-teaser-image-overlay-opacity: $fullwidth-teaser-image-overlay-opacity;

$parallax-teaser-background-color: $default-teaser-background-color;
$parallax-teaser-box-shadow: none;
$parallax-teaser-box-shadow-hover: none;
$parallax-teaser-transition: none;

// content spacing
$parallax-teaser-content-padding-top: $default-teaser-content-padding-top;
$parallax-teaser-content-padding-bottom: $default-teaser-content-padding-bottom;
$parallax-teaser-content-padding-left: $default-teaser-content-padding-left;
$parallax-teaser-content-padding-right: $default-teaser-content-padding-right;
$parallax-teaser-content-color: $color-default-secondary;

// technical title spacing + color
$parallax-teaser-tech-title-margin-bottom: $default-teaser-tech-title-margin-bottom;
$parallax-teaser-tech-title-color: $color-default-secondary;

// title spacing
$parallax-teaser-title-margin-bottom: $default-teaser-title-margin-bottom;
$parallax-teaser-title-color: $color-default-secondary;

// description spacing
$parallax-teaser-description-margin-bottom: $default-teaser-description-margin-bottom;
$parallax-teaser-description-max-width: $default-teaser-description-max-width;
$parallax-teaser-description-color: $color-default-secondary;

// link spacing + variations
$parallax-teaser-link-margin-top: $default-teaser-link-margin-top;
$parallax-teaser-button-variation: 'inverted';
$parallax-teaser-button-mask-effect: 'inverted';


.teaser {
    display: flex;
    position: relative;

    @include breakpoint(medium) {
        height: 100%;

        &:hover:not(.no-hover),
        &:focus:not(.no-hover) {
            &.teaser--outline .teaser__content {
                background-color: $brand-gray-95;
            }

            picture.lazyloader > img {
                transform: scale(1.05, 1.05);
            }
        }
    }

    .button,
    .link {
        //pointer-events: all;
        //z-index: 11;
    }

    &.teaser--action {
        background-color: $brand-black;
        @include breakpoint(large) {
            height: to-rem(500px);
        }
    }
}

.teaser--background {
    background-color: $default-teaser-background-color;
    //border: 1px solid $default-border-color;
    box-shadow: $default-teaser-box-shadow;

    @include breakpoint(medium) {
        &:hover,
        &:focus {
            picture.lazyloader > img {
                transform: scale(1.05, 1.05);
            }
        }
    }
}

.image-container--hover {

    picture.lazyloader > img {
        transition: opacity ease-in-out $lazyloader-transition-duration,
        transform ease 500ms;
        object-fit: cover;
        object-position: 50% 50%;
    }

    @include breakpoint(medium) {
        &:hover,
        &:focus {
            picture.lazyloader > img {
                transform: scale(1.05, 1.05);
            }
        }
    }
}


.teaser__bg-image {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;

    picture.lazyloader > img {
        transition: opacity ease-in-out $lazyloader-transition-duration,
        transform ease 500ms;
        object-fit: cover;
        object-position: 50% 50%;
    }

    &.teaser--parallax {
        picture {
            width: 100%;
            display: flex;

            img {
                transition: transform 200ms linear;

                &.lazyloaded {
                    animation: lazyload-fade $lazyloader-transition-duration ease-in-out;
                }
            }
        }
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(
                $bgimage-teaser-image-overlay-color,
                $bgimage-teaser-image-overlay-opacity
        );
        z-index: z('teaser-image-overlay');
    }
}

.teaser--vertical {
    flex-direction: column;
}

.teaser--horizontal {
    flex-direction: column;

    &.teaser--background {
        background: $default-teaser-horizontal-background-color;
        //border: 1px solid $default-border-horizontal-color;
    }

    @include breakpoint(small only) {
        .grid-container--media-full-width-small & {
            @include fluid-size(
                    (
                        min: $page-padding-small,
                        max: $page-padding-medium,
                    ),
                    'padding-top'
            );
            @include fluid-size(
                    (
                        min: $page-padding-small,
                        max: $page-padding-medium,
                    ),
                    'padding-right'
            );
            @include fluid-size(
                    (
                        min: $page-padding-small,
                        max: $page-padding-medium,
                    ),
                    'padding-left'
            );
        }
    }

    @include breakpoint(medium) {
        flex-direction: row;
    }
}

.teaser__image-container {
    position: relative;
    width: 100%;

    .teaser--inverted & {
        @include breakpoint(medium) {
            order: 2;
        }
    }

    picture.lazyloader > img {
        transition: opacity ease-in-out $lazyloader-transition-duration,
        transform ease 500ms;
        object-fit: cover;
        object-position: 50% 50%;
    }

    @include breakpoint(medium) {
        .teaser--horizontal & {
            flex: 1 1 50%;

            picture {
                height: 100%;
            }
        }
    }
}

.teaser__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    transition: $default-teaser-transition;
    //pointer-events: none;
    z-index: z('teaser-content');

    @include spacings-size($tile-teaser-content-padding-top, padding-top);
    @include spacings-size($tile-teaser-content-padding-bottom, padding-bottom);
    @include spacings-size($tile-teaser-content-padding-left, padding-left);
    @include spacings-size($tile-teaser-content-padding-right, padding-right);


    .teaser--vertical & {
        justify-content: flex-start;
    }

    .teaser--tile & {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include spacings-size("y-scale-6", padding-top);
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }

    .teaser--inverted & {
        @include breakpoint(medium) {
            order: 1;
        }
    }

    &.teaser__content--bg-image {
        position: absolute;
        justify-content: flex-end;
        height: 100%;
        width: 100%;

        .teaser--horizontal & {
            @include spacings-size($tile-teaser-content-padding-top, padding-top);
            @include spacings-size($tile-teaser-content-padding-bottom, padding-bottom);
            @include spacings-size($tile-teaser-content-padding-left, padding-left);
            @include spacings-size($tile-teaser-content-padding-right, padding-right);
        }
    }


    .teaser--background & {
        @include spacings-size('y-equal-3', padding-top);
        //padding-left: 0;
        //padding-right: 0;
        //padding-bottom: 0;
        pointer-events: auto;
    }

    .teaser--outline & {
        background-color: $default-teaser-background-color;
        border: 1px solid $default-border-color;

        //p,
        //h3 {
        //    max-width: to-rem(600px);
        //}
    }

    .teaser--horizontal & {
        @include spacings-size($tile-horizontal-teaser-content-padding-top, padding-top);
        @include spacings-size($tile-horizontal-teaser-content-padding-bottom, padding-bottom);

        @include breakpoint(small only) {
            .grid-container--media-full-width-small & {
                padding-right: 0;
                padding-left: 0;
            }
        }

        @include breakpoint(medium) {
            @include spacings-size(
                    $tile-horizontal-teaser-content-padding-left,
                    padding-left
            );
            @include spacings-size(
                    $tile-horizontal-teaser-content-padding-right,
                    padding-right
            );
            flex: 1 1 50%;
        }

        @include breakpoint(large) {
            border-left: none;
            flex: 1 1 50%;

            .teaser--inverted & {
                border-left: 1px solid $default-border-color;
                border-right: none;
            }
        }

        @include breakpoint(medium down) {
            border-top: none;
        }
    }

    .teaser--outline.teaser--horizontal & {
        @include spacings-size(
                $default-teaser-content-padding-top,
                padding-top
        );
        @include spacings-size(
                $default-teaser-content-padding-bottom,
                padding-bottom
        );

        @include breakpoint(medium) {
            @include spacings-size(
                    $default-teaser-content-padding-left,
                    padding-left
            );
            @include spacings-size(
                    $default-teaser-content-padding-right,
                    padding-right
            );
        }
    }

    .grid-container--media-full-width-small & {
        @include breakpoint(small only) {
            @include fluid-size(
                    (
                        min: $page-padding-small,
                        max: $page-padding-medium,
                    ),
                    'padding-right'
            );
            @include fluid-size(
                    (
                        min: $page-padding-small,
                        max: $page-padding-medium,
                    ),
                    'padding-left'
            );
        }
    }

    .teaser--no-image & {
        padding-top: 0;
    }

    .teaser--vertical & {
        border-top: none;
    }
}

.teaser__inner-content {
    .teaser--horizontal & {
        @include breakpoint(large) {
            max-width: rem-calc(600px);
        }
    }
}

.teaser__button-container {
    display: block;
    width: 100%;
    margin-top: auto;

    @include breakpoint(medium) {
        width: fit-content;
    }

    .teaser--horizontal &,
    .newsletter-snippet &,
    .teaser--background & {
        margin-top: 0;
    }
}

.teaser__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}
