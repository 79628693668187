// Variables
$browser-suggestion-background-color: #fff !default;
$browser-suggestion-lead-color: $gray-light !default;

.browser-suggestion {
    @include spacings-size(spacing-scale-5, padding-top);
    @include spacings-size(spacing-scale-5, padding-bottom);

    display: flex;
    position: fixed;
    z-index: z('browser-check');
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    background-color: $browser-suggestion-background-color;

    &__logo {
        @include spacings-size(spacing-scale-5, margin-bottom);
    }

    &__container {
        @include make-container();
        @include max-width();

        margin: auto;

        .lead {
            font-weight: 400;
            color: $browser-suggestion-lead-color;
            margin: 0;
        }

        .heading {
            @include spacings-size(spacing-scale-7, margin-top);
            @include spacings-size(spacing-scale-5, margin-bottom);

            display: flow-root;
        }

        .text {
            margin-top: 0;
        }
    }

    &__content {
        @include media-breakpoint-up(0) {
            @include make-col(24);
        }

        @include media-breakpoint-up(5) {
            @include make-col(20);
            @include make-col-offset(2);
        }

        @include media-breakpoint-up(12) {
            @include make-col(16);
            @include make-col-offset(4);
        }
    }

    &__list {
        @include spacings-size(spacing-equal-3, margin-bottom, $negative: true);
        @include spacings-size(spacing-equal-5, margin-right, $negative: true);

        .btn {
            @include spacings-size(spacing-equal-3, margin-bottom);
            @include spacings-size(spacing-equal-5, margin-right);

            display: flex;
        }

        @include media-breakpoint-up(2) {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
