$icons: 'softpage', 'arrow-external', 'arrow-more', 'close';

$icon-size: 28px;
$icon-size-small: 25px;
$icon-margin: to-rem(12);

@mixin icons {
    @each $icon in $icons {
        background-color: var(--white);

        .plugin--inverted & {
            background-color: var(--black);
        }
        &.icon--#{$icon} {
            //background-image: url("icons/#{$icon}.svg");
            mask: url('icons/#{$icon}.svg') no-repeat 50% 50%;

            &.icon--black {
                background-color: var(--black);
            }
        }
    }
}

.icon {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    flex: 0 0 auto;
    height: rem-calc(24px);
    width: rem-calc(24px);
    transition-property: left, right, margin, transform;
    transition-duration: $global-icon-transition-duration;
    transition-timing-function: ease;

    .button & {
        &.icon--arrow-external {
            height: $btn-icon-size / 2.2;
            width: $btn-icon-size / 2.2;

            &.icon--left {
                right: $btn-icon-spacing;
            }

            &.icon--right {
                left: $btn-icon-spacing;
            }
        }

        &.icon--arrow-more {
            height: $btn-icon-size;
            width: $btn-icon-size;

            &.icon--left {
                right: $btn-icon-spacing;
            }

            &.icon--right {
                left: $btn-icon-spacing;
            }
        }

        &.icon--softpage {
            height: $btn-icon-size;
            width: $btn-icon-size * 1.01; // iOs Fix

            &.icon--left {
                right: $btn-icon-spacing;
            }

            &.icon--right {
                left: $btn-icon-spacing;
            }
        }
    }

    &.icon--close {
        display: block;
        height: to-rem(40px);
        width: to-rem(40px);
    }

    &.icon--tiny {
        height: rem-calc(12px);
        width: rem-calc(12px);
    }

    &.icon--small {
        height: rem-calc(16px);
        width: rem-calc(16px);
    }

    &.icon--medium {
        height: rem-calc(18px);
        min-width: rem-calc(18px);
        max-width: rem-calc(20px);
    }

    &.icon--large {
        height: rem-calc(20px);
        width: rem-calc(20px);
    }

    svg {
        display: inline-block;
        vertical-align: middle;
        //width: 100%;
        height: auto;
    }

    //&.icon--arrow-external {
    //    margin-left: 0;
    //    margin-right: -0.5rem; // Center optically
    //}
}
