.site-overlay {
    position: fixed;
    background-color: $site-overlay-bg;
    opacity: 0;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: z($site-overlay-z-index-map-key);
    transition: opacity $site-overlay-transition-duration
        $site-overlay-transition-timing-function;
    will-change: opacity;

    &.visible {
        display: block;

        &.click-close-enabled {
            cursor: pointer;
        }
    }

    &.animating {
        opacity: $site-overlay-opacity-when-visible;
    }
    // when default modal is visible
    .default-modal-visible & {
        z-index: z('site-overlay-above-all');
    }
    // when form modal is visible
    .form-modal-visible & {
        z-index: z('site-overlay-above-all');
    }
    // when image modal is visible
    .image-modal-visible & {
        z-index: z('site-overlay-above-all');
    }

    .swiper-fullscreen-visible & {
        z-index: z('site-overlay-above-all');
    }

    @include print() {
        display: none !important;
    }
}
