/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Colors

*/

// Neutral Colors

$brand-black: #000000;
$brand-gray-15: #262626;
$brand-gray-24: #3C3C3C;
$brand-gray-35: #595959;
$brand-gray-40: #666666;
$brand-gray-50: #808080;
$brand-gray-60: #999993;
$brand-gray-70: #B3B3B3;
$brand-gray-80: #CCCCCC;
$brand-gray-85: #D9D9D9;
$brand-gray-90: #E6E6E6;
$brand-gray-93: #EDEDED;
$brand-gray-95: #F2F2F2;
$brand-gray-98: #FAFAFA;
$brand-white: #ffffff;

$brand-primary-color-40: #6E6758;
$brand-primary-color-50: #897F6C;
$brand-primary-color-60: #A19784;
$brand-primary-color-85: #DFD6C7;
$brand-primary-color-90: #E9E3D9;
$brand-primary-color-93: #F5F1EA;

$brand-red-color-40: #A35050;

$brand-green-color-40: #606E53;
$brand-green-color-50: #7A896C;
$brand-green-color-80: #C7D2BC;
$brand-green-color-93: #F0F5EB;
$brand-blue-color-30: #4D4C6B;
$brand-blue-color-40: #6D6C89;
$brand-blue-color-70: #B8B7CA;
$brand-blue-color-93: #F4F4F9;

$brand-orange-color-40: #9C6835;
$brand-orange-color-50: #BD7B39;
$brand-orange-color-80: #F2C18F;
$brand-orange-color-93: #FFEDD8;


$brand-colors: (
    'brand-black': $brand-black,
    'brand-gray-15': $brand-gray-15,
    'brand-gray-24': $brand-gray-24,
    'brand-gray-35': $brand-gray-35,
    'brand-gray-40': $brand-gray-40,
    'brand-gray-50': $brand-gray-50,
    'brand-gray-60': $brand-gray-60,
    'brand-gray-70': $brand-gray-70,
    'brand-gray-80': $brand-gray-80,
    'brand-gray-85': $brand-gray-85,
    'brand-gray-90': $brand-gray-90,
    'brand-gray-93': $brand-gray-93,
    'brand-gray-95': $brand-gray-95,
    'brand-gray-98': $brand-gray-98,
    'brand-white': $brand-white,
    'brand-primary-color-50': $brand-primary-color-50,
    'transparent': transparent
);


// Accent Colors


/* Key colors */

$brand-color-primary: $brand-primary-color-50;
$brand-color-secondary: $brand-black;
$brand-color-tertiary: $brand-black;

$project-colors: (
    1: $brand-color-primary,
    2: $brand-color-secondary,
    3: $brand-color-tertiary,
);

/* Teaser */

$brand-color-teaser-2: $brand-primary-color-93;
