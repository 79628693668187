@mixin gradient-overlay(
    $height: 100%,
    $gradient-starts-from-bottom: true,
    $before: true
) {
    $pseude-element: before;

    @if $before == false {
        $pseude-element: after;
    }

    // gradient start/end
    $start-color: $overlay-gradient-start-color;
    $start-opacity: $overlay-gradient-start-opacity;
    $end-color: $overlay-gradient-end-color;
    $end-opacity: $overlay-gradient-end-opacity;

    @if $gradient-starts-from-bottom == false {
        $start-color: $overlay-gradient-end-color;
        $start-opacity: $overlay-gradient-end-opacity;
        $end-color: $overlay-gradient-start-color;
        $end-opacity: $overlay-gradient-start-opacity;
    }

    &:#{$pseude-element} {
        content: '';
        display: block;
        position: absolute;

        @if $gradient-starts-from-bottom {
            top: auto;
            bottom: 0;
        } @else {
            top: 0;
        }

        left: 0;
        width: 100%;
        height: $height;
        background: linear-gradient(
            to bottom,
            rgba($start-color, $start-opacity) 0%,
            rgba($end-color, $end-opacity) 100%
        );
    }
}
