/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Image Modal

*/

.image-modal {
    // optional styles
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Image Modal

*/

.image-modal {
    display: flex;
    overflow-x: hidden;
    z-index: z('image-modal');
    transition: $image-modal-transition;

    &.tingle-modal--visible {
        transform: translate(0, 0);
        opacity: 1;
    }

    .tingle-modal-box {
        position: static;
        width: 100%;
        max-width: $image-modal-max-width;
        background-color: transparent;
    }

    .tingle-modal-box__content {
        padding: 0;
    }

    .tingle-modal__close {
        display: none;
        position: absolute;
        top: 0 !important;
        right: 0;
        left: auto;
        line-height: 1;

        &:focus {
            outline: none;
        }

        &::before {
            color: $image-modal-close-icon-color;
        }

        &:hover,
        &:focus {
            &::before {
                color: $image-modal-close-icon-color-hover;
            }
        }

        width: $image-modal-close-btn-width-xs;
        height: $image-modal-close-btn-height-xs;

        @include media-breakpoint-up(md) {
            width: $image-modal-close-btn-width-md;
            height: $image-modal-close-btn-height-md;
        }

        @include media-breakpoint-up(xl) {
            width: $image-modal-close-btn-width-xl;
            height: $image-modal-close-btn-height-xl;
        }

        @include media-breakpoint-up(xxl) {
            width: $image-modal-close-btn-width-xxl;
            height: $image-modal-close-btn-height-xxl;
        }
    }
    // close button enabled
    &.tingle-modal--ButtonClose {
        .tingle-modal__close {
            display: block;
        }
    }

    // overlay close enabled?
    cursor: default;

    &.tingle-modal--OverlayClose {
        cursor: pointer;
    }
}
