/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=form Settings

*/

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=defaults

*/

//** `<input>` border color
$input-border: #ccc !default;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius: $border-radius-base !default;
//** Large `.form-control` border radius
$input-border-radius-large: $border-radius-large !default;
//** Small `.form-control` border radius
$input-border-radius-small: $border-radius-small !default;

//** Border color for inputs on focus
$input-border-focus: #66afe9 !default;

//** Default `.form-control` height
$input-height-base: (
    $line-height-computed + ($padding-base-vertical * 2) + 2
) !default;
//** Large `.form-control` height
$input-height-large: (
    ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) +
        2
) !default;
//** Small `.form-control` height
$input-height-small: (
    floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) +
        2
) !default;

//** `.form-group` margin
$form-group-margin-bottom: 15px !default;

$form-default-max-width: 600px;

// `.form-group` margin top (except :first-child)
$form-group-spacing: 'spacing-gutter-width';

// `.label-control` margin bottom
$form-label-spacing: 'y-equal-7';

// optionally mark label as bold
$form-label-bold-enabled: true;

// error label
$form-label-error-color: $brand-black;

// error message
$form-field-error-spacing-xs: $grid-gutter-width/4;
$form-field-error-spacing-md: $grid-gutter-width/4;
$form-field-error-spacing-xl: $grid-gutter-width/4;
$form-field-error-color: $color-danger;

$form-field-required-icomoon-enabled: false; // only set to 'true' if an icomoon icon is set below
$form-field-required-icon: '*';
$form-field-required-icon-color: #ba1414;
// styling of required symbol
$form-field-required-icon-size: 1em;
$form-field-required-icon-width: 20px;
$form-field-required-icon-height: 1em;
$form-field-required-icon-spacing: 0.2em;
$form-field-required-icon-position-y: 0;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=Fieldset and =Legend

*/

// spacing between fieldsets
$form-fieldset-spacing-xs: $grid-gutter-width;
$form-fieldset-spacing-md: $grid-gutter-width * 1.5;
$form-fieldset-spacing-xl: $grid-gutter-width * 2;

// legend heading size (h1, h2, h3 or h4)
$form-legend-heading-size: 'h2';

// form group container spacing to previous element (legend or .fieldset-text)
$form-group-container-spacing-top-xs: $grid-gutter-width;
$form-group-container-spacing-top-md: $grid-gutter-width;
$form-group-container-spacing-top-xl: $grid-gutter-width;

// fieldset intro text spacing to legend
$form-fieldset-text-spacing-top-xs: $grid-gutter-width/2;
$form-fieldset-text-spacing-top-md: $grid-gutter-width/2;
$form-fieldset-text-spacing-top-xl: $grid-gutter-width/2;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=form =side by side

*/

$form-side-by-side-max-width: 800px;
$form-side-by-side-label-width-md: 240px; // change according to longest label text
$form-side-by-side-label-width-xxl: 300px; // change according to longest label text
$form-side-by-side-label-gap: $grid-gutter-width;
$form-side-by-side-label-text-align: left; // "text-align" values accepted

// spacing between .form-group
$form-side-by-side-group-spacing-top-xs: $grid-gutter-width/4;
$form-side-by-side-group-spacing-top-md: $grid-gutter-width/4;
$form-side-by-side-group-spacing-top-xl: $grid-gutter-width/4;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=form =placeholder-enabled

*/

// spacing between .form-group
$form-placeholder-enabled-group-spacing-top-xs: $grid-gutter-width/4;
$form-placeholder-enabled-group-spacing-top-md: $grid-gutter-width/4;
$form-placeholder-enabled-group-spacing-top-xl: $grid-gutter-width/4;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=fieldset and =legend

*/

// spacing between fieldsets
$form-fieldset-spacing-xs: $grid-gutter-width;
$form-fieldset-spacing-md: $grid-gutter-width * 1.5;
$form-fieldset-spacing-xl: $grid-gutter-width * 2;

// legend heading size (h1, h2, h3 or h4)
$form-legend-heading-size: 'h2';

// form group container spacing to previous element (legend or .fieldset-text)
$form-group-container-spacing-top-xs: $grid-gutter-width;
$form-group-container-spacing-top-md: $grid-gutter-width;
$form-group-container-spacing-top-xl: $grid-gutter-width;

// fieldset intro text spacing to legend
$form-fieldset-text-spacing-top-xs: $grid-gutter-width/2;
$form-fieldset-text-spacing-top-md: $grid-gutter-width/2;
$form-fieldset-text-spacing-top-xl: $grid-gutter-width/2;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

General Form =field Settings (.form-control)

These settings apply to:

<input>'s
<textarea>'s

*/

$form-field-transition: background-color $default-transition-duration
        $default-transition-timing-function,
    color $default-transition-duration $default-transition-timing-function,
    border $default-transition-duration $default-transition-timing-function;
$form-field-border-radius-default: 0;
$form-field-border-width: 1px;
$form-field-border-style: solid;
$form-field-border-color: transparent;

// background color
$input-bg: #fff;
// background color
$input-bg-focus: #fff;
// background color of disabled form field (e.g. type="text")
$input-bg-disabled: $gray-lightest;

// Text color for `<input>`s
$input-color: $brand-black;

//** Default `.form-control` height
$input-height-base: (
    $line-height-computed + ($padding-base-vertical * 2) + 2
) !default;
//** Large `.form-control` height
$input-height-large: (
    ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) +
        2
) !default;
//** Small `.form-control` height
$input-height-small: (
    floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) +
        2
) !default;

// horizontal padding settings for form fields and textarea
$input-padding-left: 0.5em;
$input-padding-right: 0.5em;

// border color
$input-border-top-color: $brand-black;
$input-border-right-color: $brand-black;
$input-border-bottom-color: $brand-black;
$input-border-left-color: $brand-black;

// border color :hover
$input-border-top-color-hover: $brand-black;
$input-border-right-color-hover: $brand-black;
$input-border-bottom-color-hover: $brand-black;
$input-border-left-color-hover: $brand-black;

// border color :focus
$input-border-top-color-focus: $brand-black;
$input-border-right-color-focus: $brand-black;
$input-border-bottom-color-focus: $brand-black;
$input-border-left-color-focus: $brand-black;

// ERROR styles
$input-error-border-top-color: $form-field-required-icon-color;
$input-error-border-right-color: $form-field-required-icon-color;
$input-error-border-bottom-color: $form-field-required-icon-color;
$input-error-border-left-color: $form-field-required-icon-color;
$input-error-bg: #fff;
$input-error-color: $form-field-required-icon-color;

// Placeholder text color
$input-color-placeholder: $brand-black;

// legend settings (IMPORTANT: currently not used and optizmied)
$legend-color: $gray-dark;
$legend-border-color: #e5e5e5;

// textual input addons (IMPORTANT: currently not used and optizmied)
// Background color
$input-group-addon-bg: $gray-lighter;
// Border color
$input-group-addon-border-color: $form-field-border-width;

// Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

INVERTED colors for form =fields (.form-control)

How to use it: Simply add a class `inverted-colors` to one of the parents of a form.

Example:

<div class="site-footer inverted-colors">
    <form>
        <input class="form-control" type="text" name="me-has-inverted-form-styles">
    </form>
</div>

*/

$form-field-inverted-transition: $form-field-transition;
$form-field-inverted-border-radius-default: $form-field-border-radius-default;
$form-field-inverted-border-width: $form-field-border-width;
$form-field-inverted-border-style: $form-field-border-style;
$form-field-inverted-border-color: $form-field-border-color;

// background color
$input-inverted-bg: $input-bg;
// background color of disabled form field (e.g. type="text")
$input-inverted-bg-disabled: $input-bg-disabled;
// background color :focus
$input-inverted-bg-focus: $input-bg-focus;

// Text color for `<input>`s
$input-inverted-color: $input-color;

// horizontal padding settings for form fields and textarea
$input-inverted-padding-left: $input-padding-left;
$input-inverted-padding-right: $input-padding-right;

// border color
$input-inverted-border-top-color: $input-border-top-color;
$input-inverted-border-right-color: $input-border-right-color;
$input-inverted-border-bottom-color: $input-border-bottom-color;
$input-inverted-border-left-color: $input-border-left-color;

// border color :focus
$input-inverted-border-top-color-focus: $input-border-top-color-focus;
$input-inverted-border-right-color-focus: $input-border-right-color-focus;
$input-inverted-border-bottom-color-focus: $input-border-bottom-color-focus;
$input-inverted-border-left-color-focus: $input-border-left-color-focus;

// ERROR styles
$input-inverted-error-border-top-color: $input-error-border-top-color;
$input-inverted-error-border-right-color: $input-error-border-right-color;
$input-inverted-error-border-bottom-color: $input-error-border-bottom-color;
$input-inverted-error-border-left-color: $input-error-border-left-color;
$input-inverted-error-bg: $input-error-bg;
$input-inverted-error-color: $input-error-color;

// Placeholder text color
$input-inverted-color-placeholder: $input-color;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=checkboxes

*/

$checkbox-label-spacing: 1.5em;
$checkbox-siblings-spacing-top: 0.3em;
$checkbox-spacing-left: 0;
$checkbox-bg-color: $input-bg;
$checkbox-bg-color-focus: $input-bg;
$checkbox-bg-color-checked: $input-bg;
$checkbox-border-width: $form-field-border-width;
$checkbox-border-color: $gray-light;
$checkbox-border-color-focus: $brand-color-primary;
$checkbox-border-color-checked: $brand-color-primary;
$checkbox-size: 0.8em;
$checkbox-border-radius: $form-field-border-radius-default;
$checkbox-icon-color: $brand-color-primary;
$checkbox-icon-color-focus: green;
$checkbox-transition: $form-field-transition;

// optional: icon font settings
$checkbox-font-icon-enabled: true; // boolean, if set false, a default css check icon is used as a fallback
$checkbox-font-icon-code: '\e206'; // string, e.g. '\e906'
$checkbox-font-icon-size: 0.8em;

// default: CSS check icon
$checkbox-css-icon-size: 14px; // the calculations are made automatically according to this value

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=radio

Important: Per default, the radio boxes inherit most of the checkbox styles, for they
usually SHOULD look similar in terms of size and spacings

*/

$radio-label-spacing: $checkbox-label-spacing;
$radio-siblings-spacing-top: $checkbox-siblings-spacing-top;
$radio-spacing-left: $checkbox-spacing-left;
$radio-bg-color: $checkbox-bg-color;
$radio-bg-color-focus: $checkbox-bg-color-focus;
$radio-bg-color-checked: $checkbox-bg-color-checked;
$radio-border-width: $checkbox-border-width;
$radio-border-color: $checkbox-border-color;
$radio-border-color-focus: $checkbox-border-color-focus;
$radio-border-color-checked: $checkbox-border-color-checked;
$radio-size: $checkbox-size;
$radio-transition: $checkbox-transition;

// inner circle
$radio-inner-circle-size: $radio-size/2;
$radio-inner-circle-bg: $brand-color-primary;
$radio-inner-circle-transition: $checkbox-transition;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=bootstrap =select

*/

$select-btn-variation: 'default'; // needs to be one of the variations defined in $btn-variations

// this is a quick and dirty solution to fight the bootstrap-select loading jumping bloody effect
// Important: Get height of rendered bootstrap-select at each breakpoint AFTER the typography has been approved.
$select-placeholder-height-xs: 41px;
$select-placeholder-height-md: 41px;
$select-placeholder-height-xxl: 51px;

// dropdown animation settings
$select-dropdown-animation-enabled: true;
$select-dropdown-animation-duration: 300ms;
$select-dropdown-animation-timing-function: ease-in-out;
$select-dropdown-animation-initial-translate-y: -15px;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=File Upload Styles

*/

$file-upload-btn-variation: 'default'; // needs to be one of the variations defined in $btn-variations
$file-upload-icon: '\e90c';
$file-upload-icon-size: 0.8em;
$file-upload-icon-spacing-right: 0.3em;
$file-upload-padding-left: 0.5em;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=datepicker

*/

// default button: calendar
$datepicker-default-width: 100%;
$datepicker-default-min-width: 320px;
$datepicker-default-max-width: 320px;
$datepicker-default-input-bg: $input-bg;
$datepicker-default-btn-width: 30px;
$datepicker-default-btn-icon: '\e60e';
$datepicker-default-btn-icon-size: 0.8em;
$datepicker-default-btn-icon-color: $brand-black;
$datepicker-default-btn-icon-color-hover: $brand-black;
// time button
$datepicker-time-btn-icon: '\e60e';
// clear button
$datepicker-clear-btn-icon: '\e209';
$datepicker-clear-btn-icon-size: 1em;
$datepicker-clear-btn-icon-color: $brand-black;
$datepicker-clear-btn-icon-color-hover: $brand-black;
$datepicker-clear-btn-position-right: $datepicker-default-btn-width;
$datepicker-clear-btn-transition: all 150ms cubic-bezier(0.7, 0.91, 0.41, 1.64);
// selected day
$datepicker-selected-day-color: $white;
$datepicker-selected-day-bg: $brand-color-primary;
$datepicker-selected-day-border-color: $brand-color-primary;
$datepicker-selected-day-border-width: 1;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=dropdown

*/
$dropdown-min-width: 280px;
$dropdown-border-width: 1px;
$dropdown-border-style: solid;
$dropdown-border-color: $brand-black;
$dropdown-border-radius: 0;
$dropdown-border-top: false; // boolean

// toggle
$dropdown-toggle-icon: '\e905';
$dropdown-toggle-icon-spacing-right: 1em;
$dropdown-toggle-icon-spacing-left: auto;
$dropdown-toggle-padding-right: 3em;
$dropdown-toggle-padding-left: 0.5em;

// dropdown
$dropdown-bg: $white;
$zindex-dropdown: 1000 !default;

// link: default
$dropdown-link-padding: 0.5em 0.5em;
$dropdown-link-color: $brand-black;
$dropdown-link-hover-color: $brand-black;
$dropdown-link-hover-bg: transparent;

// link: active
$dropdown-link-active-color: $brand-black;
$dropdown-link-active-bg: $white;
$dropdown-link-disabled-color: $gray-light;

// not used in default dropdowns
$dropdown-divider-bg: transparent;
$dropdown-header-color: $gray-light;
$dropdown-border: rgba(0, 0, 0, 0.15) !default;
$dropdown-fallback-border: #ccc !default;
$dropdown-divider-bg: #e5e5e5 !default;
$dropdown-caret-color: #000 !default;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=textarea =area

*/

$textarea-min-height: 5em;
$textarea-max-height: 60vh;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=tabs

*/

// container
$custom-tabs-border-bottom-width: 0;
$custom-tabs-border-bottom-style: solid;
$custom-tabs-border-bottom-color: #000;
$custom-tabs-register-look-activated: true; // creates a negative margin according to the $custom-tabs-border-bottom-width in order to achieve a 'register look'

// default: uses the same distance like we have between section title and the categories
$custom-tabs-spacing-bottom: $content-section-heading-spacing;

// item
$custom-tabs-item-transition: $default-transition;
$custom-tabs-item-border-width: $custom-tabs-border-bottom-width;
$custom-tabs-item-border-style: $custom-tabs-border-bottom-style;
$custom-tabs-item-border-color: $custom-tabs-border-bottom-color;
$custom-tabs-item-padding-xs: 0.6em 0.8em;
$custom-tabs-item-padding-md: 0.6em 0.8em;
$custom-tabs-item-bg-default: #fff;
$custom-tabs-item-bg-default-hover: #fff;
$custom-tabs-item-color-default: $brand-color-secondary;
$custom-tabs-item-bg-active: $brand-color-primary;
$custom-tabs-item-bg-active-hover: $brand-color-primary;
$custom-tabs-item-color-active: $brand-color-secondary;
