
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Animation

*/

$global-color-transition-duration: 300ms;
$global-icon-transition-duration: 300ms;

$global-color-timing-function: ease;
$global-icon-timing-function: ease-in-out;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=links (default)

*/

$link-transition-property:           color, border-color;
$link-transition-duration:           $global-color-transition-duration;
$link-transition-timing-function:    $global-color-timing-function;
$link-color:                         $body-color;
$link-decoration:                    underline;
$link-color-hover:                   $link-color;
$link-decoration-hover:              none;

// textlink-icon
$textlink-icon-color:                $link-color;
$textlink-icon-color-hover:          $link-color;
$textlink-icon-height-external:      0.7em;
$textlink-icon-height-more:          0.7em;
$textlink-icon-height-softpage:      1.1em;

// border enabled?
$link-border-width:         1px;
$link-border-style:         solid;
$link-border-color:         #999;
$link-border-color-hover:   $color-default-primary;
$link-border:               $link-border-width $link-border-style $link-border-color-hover;
$link-border-hover:         $link-border-width $link-border-style $link-border-color-hover;
$link-background-color:     #ccc;
$link-text-spacing:         0.2em;

// inverted text on dark background
$link-inverted-color:               $color-default-secondary;
$link-inverted-color-hover:         $color-default-secondary;
$link-inverted-border-color:        $color-default-secondary;
$link-inverted-border-color-hover:  $color-default-secondary;
