/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Re-Usable Loader Mixin

*/

// animation
@keyframes loader-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@mixin the-loader($btn-ajax-loader-context: false) {
    position: relative;

    > * {
        z-index: 2;
    }

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        border: $loader-border-width solid transparent;
        border-radius: 50%;
        z-index: 1;
        animation: loader-animation $loader-animation-duration
            $loader-animation-timing-function infinite;
        // optional: when within [data-btn-ajax-loader] context
        @if $btn-ajax-loader-context == true {
            width: $btn-loader-size;
            height: $btn-loader-size;
            margin-left: -($btn-loader-size/2);
            margin-top: -($btn-loader-size/2);
            border-top-color: $btn-loader-border-top-color;
            border-bottom-color: $btn-loader-border-bottom-color;
        }
        // default
        @else {
            width: $loader-size;
            height: $loader-size;
            margin-left: -($loader-size/2);
            margin-top: -($loader-size/2);
            border-top-color: $loader-border-top-color;
            border-bottom-color: $loader-border-bottom-color;
        }
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Re-Usable Loader CLASS

===

Usage:

<span class="loader"></span>

Proper positioning has to be made in each context.

*/

.loader {
    display: block;
    width: $loader-size;
    height: $loader-size;

    @include the-loader();

    &.loader--products {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 4000;
        display: none;
        .fetching & {
            display: block;
        }
    }
}
