.topic-header {
    margin-top: 0;
    padding-top: 0;
}

.topic-header__content {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 60vh;
    z-index: 1;
    @include breakpoint(medium) {
        height: 65vh;
        min-height: to-rem(400px);
    }
}
