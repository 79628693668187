/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Spacing Modifier

*/

$section-spacings: 'y-section-0', 'y-section-1', 'y-section-2', 'y-section-3',
'y-section-4';
$equal-spacings: 'y-equal-1', 'y-equal-2', 'y-equal-3', 'y-equal-4', 'y-equal-5',
'y-equal-6', 'y-equal-7', 'y-equal-8';
$scale-spacings: "y-menu-height", "y-scale-0", "y-scale-1", "y-scale-2", "y-scale-3", "y-scale-4", "y-scale-5", "y-scale-6", "y-scale-7", "y-scale-8", "y-scale-9", "y-equal-1", "y-equal-2", "y-equal-3", "y-equal-4", 'y-equal-5',
'y-equal-6', 'y-equal-7', 'y-equal-8', 'y-section-0', 'y-section-1', 'y-section-2', 'y-section-3', 'y-nav-full',
'y-section-4', "x-gutter-width", "x-scale-1", "x-scale-2", "x-scale-3", "x-scale-4", "x-scale-5", "x-scale-6", "x-scale-7", "x-scale-8", "x-equal-1", "x-equal-2", "x-equal-3", "x-equal-4", 'x-equal-5', 'x-equal-6';
$y-scale-spacings: "y-menu-height", "y-scale-0", "y-scale-1", "y-scale-2", "y-scale-3", "y-scale-4", "y-scale-5", "y-scale-6", "y-scale-7", "y-scale-8", "y-scale-9", "y-equal-1", "y-equal-2", "y-equal-3", "y-equal-4", 'y-equal-5',
'y-equal-6', 'y-equal-7', 'y-equal-8', 'y-section-0', 'y-section-1', 'y-section-2', 'y-section-3', 'y-nav-full',
'y-section-4';
$x-scale-spacings: "x-gutter-width", "x-scale-1", "x-scale-2", "x-scale-3", "x-scale-4", "x-scale-5", "x-scale-6", "x-scale-7", "x-scale-8", "x-equal-1", "x-equal-2", "x-equal-3", "x-equal-4", 'x-equal-5', 'x-equal-6';

@mixin section-spacings {
    @each $spacing in $section-spacings {
        &.#{$spacing} {
            @include spacings-size(#{$spacing}, margin-top);
            @include spacings-size(#{$spacing}, margin-bottom);
        }
        &.#{$spacing}-top {
            @include spacings-size(#{$spacing}, margin-top);
        }
        &.#{$spacing}-bottom {
            @include spacings-size(#{$spacing}, margin-bottom);
        }
        &.#{$spacing}-small-top {
            @include breakpoint(small only) {
                @include spacings-size(#{$spacing}, margin-top);
            }
        }
        &.#{$spacing}-medium-top {
            @include breakpoint(medium only) {
                @include spacings-size(#{$spacing}, margin-top);
            }
        }
        &.#{$spacing}-large-top {
            @include breakpoint(large) {
                @include spacings-size(#{$spacing}, margin-top);
            }
        }
    }
}

@mixin equal-spacings {
    @each $spacing in $equal-spacings {
        &.#{$spacing} {
            @include spacings-size(#{$spacing}, margin-top);
            @include spacings-size(#{$spacing}, margin-bottom);
        }
        &.#{$spacing}-top {
            @include spacings-size(#{$spacing}, margin-top);
        }
        &.#{$spacing}-bottom {
            @include spacings-size(#{$spacing}, margin-bottom);
        }
    }
}

@mixin scale-spacings {
    @each $spacing in $scale-spacings {
        &.#{$spacing} {
            @include spacings-size(#{$spacing}, margin-top);
            @include spacings-size(#{$spacing}, margin-bottom);
        }
        &.#{$spacing}-top {
            @include spacings-size(#{$spacing}, margin-top);
        }
        &.#{$spacing}-bottom {
            @include spacings-size(#{$spacing}, margin-bottom);
        }
        &.#{$spacing}-right {
            @include spacings-size(#{$spacing}, margin-right);
        }
        &.#{$spacing}-left {
            @include spacings-size(#{$spacing}, margin-left);
        }
        &.#{$spacing}-small-bottom {
            @include breakpoint(small only) {
                @include spacings-size(#{$spacing}, margin-bottom);
            }
        }
        &.#{$spacing}-medium-bottom {
            @include breakpoint(medium only) {
                @include spacings-size(#{$spacing}, margin-bottom);
            }
        }
        &.#{$spacing}-large-bottom {
            @include breakpoint(large) {
                @include spacings-size(#{$spacing}, margin-bottom);
            }
        }
        &.#{$spacing}-small-top {
            @include breakpoint(small only) {
                @include spacings-size(#{$spacing}, margin-top);
            }
        }
        &.#{$spacing}-medium-top {
            @include breakpoint(medium only) {
                @include spacings-size(#{$spacing}, margin-top);
            }
        }
        &.#{$spacing}-large-top {
            @include breakpoint(large) {
                @include spacings-size(#{$spacing}, margin-top);
            }
        }
    }
}

@mixin scale-padding {
    @each $spacing in $scale-spacings {
        &.#{$spacing} {
            @include spacings-size(#{$spacing}, padding-top);
            @include spacings-size(#{$spacing}, padding-bottom);
        }
        &.#{$spacing}-top {
            @include spacings-size(#{$spacing}, padding-top);
        }
        &.#{$spacing}-right {
            @include spacings-size(#{$spacing}, padding-right);
        }
        &.#{$spacing}-bottom {
            @include spacings-size(#{$spacing}, padding-bottom);
        }
        &.#{$spacing}-left {
            @include spacings-size(#{$spacing}, padding-left);
        }
        &.#{$spacing}-small-bottom {
            @include breakpoint(small only) {
                @include spacings-size(#{$spacing}, padding-bottom);
            }
        }
        &.#{$spacing}-medium-bottom {
            @include breakpoint(medium only) {
                @include spacings-size(#{$spacing}, padding-bottom);
            }
        }
        &.#{$spacing}-large-bottom {
            @include breakpoint(large) {
                @include spacings-size(#{$spacing}, padding-bottom);
            }
        }
    }
}

@mixin y-scale-padding {
    @each $spacing in $y-scale-spacings {
        &.#{$spacing} {
            @include spacings-size(#{$spacing}, padding-top);
            @include spacings-size(#{$spacing}, padding-bottom);
        }
        &.#{$spacing}-top {
            @include spacings-size(#{$spacing}, padding-top);
        }
        &.#{$spacing}-bottom {
            @include spacings-size(#{$spacing}, padding-bottom);
        }
        &.#{$spacing}-small-bottom {
            @include breakpoint(small only) {
                @include spacings-size(#{$spacing}, padding-bottom);
            }
        }
        &.#{$spacing}-medium-bottom {
            @include breakpoint(medium only) {
                @include spacings-size(#{$spacing}, padding-bottom);
            }
        }
        &.#{$spacing}-large-bottom {
            @include breakpoint(large) {
                @include spacings-size(#{$spacing}, padding-bottom);
            }
        }
    }
}

@mixin x-scale-margin {
    @each $spacing in $x-scale-spacings {
        &.#{$spacing}-right {
            @include spacings-size(#{$spacing}, margin-right);
        }
        &.#{$spacing}-left {
            @include spacings-size(#{$spacing}, margin-left);
        }
    }
}

@mixin equal-padding {
    @each $spacing in $equal-spacings {
        &.#{$spacing} {
            @include spacings-size(#{$spacing}, padding-top);
            @include spacings-size(#{$spacing}, padding-bottom);
        }
        &.#{$spacing}-top {
            @include spacings-size(#{$spacing}, padding-top);
        }
        &.#{$spacing}-bottom {
            @include spacings-size(#{$spacing}, padding-bottom);
        }
        &.#{$spacing}-small-bottom {
            @include breakpoint(small only) {
                @include spacings-size(#{$spacing}, padding-bottom);
            }
        }
        &.#{$spacing}-medium-bottom {
            @include breakpoint(medium only) {
                @include spacings-size(#{$spacing}, padding-bottom);
            }
        }
        &.#{$spacing}-large-bottom {
            @include breakpoint(large) {
                @include spacings-size(#{$spacing}, padding-bottom);
            }
        }
    }
}

.section-spacing {
    @include spacings-size('y-section-4', margin-top);
    @include spacings-size('y-section-4', margin-bottom);
    @include section-spacings();

    .page-article & {
        @include spacings-size('y-scale-1', margin-top);
        @include spacings-size('y-scale-1', margin-bottom);
    }

    .site-footer & {
        @include spacings-size('y-scale-3', margin-top);
        @include spacings-size('y-scale-3', margin-bottom);
    }
}

.y-scale, .scale {
    @include scale-spacings();
}

.scale-padding {
    @include scale-padding();
}

.x-scale-margin {
    @include x-scale-margin();
}

.y-scale-padding {
    @include y-scale-padding();
}

.y-equal {
    @include equal-spacings();
}

.y-equal-padding {
    @include equal-padding();
}

.y-scale-mobile {
    @include breakpoint(small only) {
        @include scale-spacings();
    }
}

.y-section {
    @include section-spacings();
}

.y-section-0 {
    margin: 0;
}


%transition-timing-duration {
    transition-duration: 300ms;
    transition-timing-function: ease;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Text Modifier

*/

.text--debug {
    background-color: rgba(255, 0, 0, 0.2);
    background-clip: content-box;
    padding: 0.001em;
}

.text--default {
    @include font-default();
}

.text--default-tighter {
    @include font-default-tighter();
}

.text--white {
    & {
        color: var(--white);
    }
}

.text--black {
    color: $brand-black;
}

.text--small {
    @include font-small();
}

.text--small-2 {
    @include font-small-2();
}

.text--small-3 {
    @include font-small-3();
}

.text--small-4 {
    @include font-small-4();
}

.text--btn-default {
    @include font-btn-default();
}

.text--btn-small {
    @include font-btn-small();
}

.text--btn-large {
    @include font-btn-large();
}

.text--input-default {
    @include font-input-default();
}

.text--h1 {
    @include font-h1();
}

.text--h2 {
    @include font-h2();
}

.text--h3 {
    @include font-h3();
}

.text--h4 {
    @include font-h4();
}

.text--h5 {
    @include font-h5();
}

.text--h6 {
    @include font-h6();
}

.text--bold {
    @include font-family-bold();
}

.text--center {
    text-align: center;
}


.text--center-mobile {
    @include breakpoint(small only) {
        text-align: center;
    }
}

.text--right {
    text-align: right;
}

.text--right-medium {
    @include breakpoint(medium) {
        text-align: right;
    }
}

.text--semibold {
    @include font-family-semibold();
}

.text--tighter {
    @include font-default-tighter();
}

.text--uppercase {
    text-transform: uppercase;
    letter-spacing: 0.075em;
    font-family: $font-family-secondary;

    @include line-height-crop(
            $font-secondary-case-height,
            $font-secondary-shortfall,
            $font-heading-line-height
    );
}

.text--label {
    @include spacings-size('y-equal-6', 'margin-bottom');
    @include font-small-2();

    display: flow-root;
    font-size: 1rem;
    color: $color-muted-primary;

    & + p {
        @include font-default-tighter();

        margin-top: 0;
    }
}

.text--lead {
    @include font-lead();
}

.text--lead-2 {
    @include font-lead-2();
}

.text--block {
    display: block;
}

ul {
    &.no-decoration {
        padding-left: 0;
        margin-left: 0;

        li {
            list-style: none;
            padding: 0;
        }
    }
}

a.no-decoration {
    text-decoration: none;
}

a.no-hover {
    &:hover,
    &:focus {
        color: inherit;
    }
}

.order-mobile-2 {
    @include breakpoint(small only) {
        order: 2;
    }
}

.order-mobile-1 {
    @include breakpoint(small only) {
        order: 1;
    }
}

.order-medium-2 {
    @include breakpoint(medium only) {
        order: 2;
    }
}

.order-medium-1 {
    @include breakpoint(medium only) {
        order: 1;
    }
}

.no-break {
    white-space: nowrap;
}

.opacity-45 {
    opacity: 0.45;
}

.opacity-25 {
    opacity: 0.25;
}

.text-column {
    li:not(:last-child) {
        display: list-item;
        padding-bottom: 0 !important;
        margin-bottom: rem-calc(30);
    }

    &:before {
        margin-top: 0 !important;
    }

    &.text-column--2 {
        @include breakpoint(medium) {
            columns: 2;
        }
    }

    &.text-column--3 {
        @include breakpoint(medium) {
            columns: 2;
        }
        @include breakpoint(large) {
            columns: 3;
        }
    }
}

/* color modifier */

.bg--primary {
    background: $brand-color-primary;
}

.bg--secondary {
    background: $brand-color-secondary;
}

.bg--brand-green {
    background: $brand-green;
}

.bg--brand-red {
    background: $brand-red;
}

.bg--brand-yellow {
    background: $brand-yellow;
}

@each $color, $value in $brand-colors {
    .bg--#{$color} {
        background: $value;
    }
    .bg--to--#{$color} {
        &:hover {
            background: $value;
        }
    }
    .text--#{$color} {
        & {
            color: $value;
        }
    }
    .text--to--#{$color} {
        &:hover,
        &:focus {
            color: $value;
        }
    }

    .icon--#{$color} {
        &, svg {
            color: $value;
            fill: $value;
        }
    }
    .border--#{$color} {
        &:after,
        &:before {
            background-color: $value !important;
        }

        border-color: $value !important;

    }
    .border--to--#{$color} {
        &:hover {
            &:after,
            &:before {
                background-color: $value !important;
            }

            border-color: $value !important;
        }

    }


}

.--hover {

    @each $color, $value in $brand-colors {
        &:hover {
            .hover--text--#{$color} {
                color: $value;
            }
        }
    }
}

$transition-property: "color", "border-color", "background-color";

@each $value in $transition-property {
    .hover--#{$value} {
        @extend %transition-timing-duration;
        transition-property: #{$value};
    }
}

/* Container modifier */


.page-padding--small {
    @include breakpoint(small down) {
        @include fluid-size(
                (
                    min: $page-padding-small,
                    max: $page-padding-medium,
                ),
                'padding-right'
        );
        @include fluid-size(
                (
                    min: $page-padding-small,
                    max: $page-padding-medium,
                ),
                'padding-left'
        );
    }
}

.border {
    position: relative;
    width: 100%;
    margin: 0 !important;
}

.border--full {
    border-style: solid;
    border-width: 1px;
    border-color: $body-color;
}

.border--top {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: $body-color;
}

.border--bottom {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $body-color;
}

.no-spacing {
    padding: 0;
    margin: 0;
}

.align-center {
    @include breakpoint(medium) {
        justify-content: center;
    }
}

.align-right {
    @include breakpoint(medium) {
        justify-content: flex-end;
        align-items: flex-end;
    }
}

.align-center-mobile {
    @include breakpoint(small only) {
        justify-content: center;
    }
}

.align-right-mobile {
    @include breakpoint(small only) {
        justify-content: flex-end;
        align-items: flex-end;
    }
}


.ml-auto {
    margin-left: auto;
}

.mt-auto {
    margin-top: auto;
}

.flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.align-content-center {
    align-content: center;
}

.align-items-center {
    align-items: center;
}

.align-self-baseline {
    align-self: baseline;
}

.hidden {
    display: none !important;
}

.card-shadow {
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.16);
}

.softpage--only {
    display: none;

    .softpage & {
        display: block;
    }
}

.softpage--hidden {
    display: block;

    .softpage & {
        display: none;
    }
}

.image-container {
    &.image-container--round {
        picture img {
            border-radius: 50%;
            height: 100%;
            width: 100%;
        }
    }
}


.counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem-calc(42px);
    height: rem-calc(42px);
    border-radius: 50%;
    @extend %transition-timing-duration;
    transition-property: background-color, border-color;

    &.border--transparent {
        border: 1px solid transparent;
    }

    &.counter--small {
        width: rem-calc(30px);
        height: rem-calc(30px);
    }
}

.close-to-counter {
    position: relative;
    display: flex;

    svg {
        position: relative;
        z-index: 1;
    }

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: rem-calc(36px);
        height: rem-calc(36px);
        z-index: 0;
        transition-property: background-color;
        transition-duration: $link-transition-duration;
        transition-timing-function: $link-transition-timing-function;
    }

    &:hover, &:focus {
        &:after {
            background-color: $brand-gray-93;
        }
    }
}

.product-card__open {
    background-color: $brand-gray-90;
    color: $brand-gray-60;


    &, svg {
        transition-property: color, border-color, background-color;
        transition-duration: $link-transition-duration;
        transition-timing-function: $link-transition-timing-function;
    }

    svg {
        transform: rotate(180deg);
    }

    &:hover,
    &:focus,
    .teaser__content:hover &,
    .teaser__content:focus & {
        background-color: $brand-primary-color-85;
    }
}


%hero-fluid-container {
    @include fluid-size((min: to-rem(170), max: to-rem(400)), 'min-height');
    @include viewport-fluid-size(60vh, max-height);
    height: 70vh;

    @include breakpoint(large) {
        @include fluid-size((min: to-rem(340), max: to-rem(816)), 'max-height');
        height: 82vh;
    }
}

%hero-fluid-container-full {
    @include fluid-size((min: to-rem(170), max: to-rem(400)), 'min-height');
    @include viewport-fluid-size(60vh, max-height);
    height: 70vh;

    @include breakpoint(large) {
        @include fluid-size((min: to-rem(340), max: to-rem(816)), 'max-height');
        height: 82vh;
    }
}
