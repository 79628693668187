@mixin icomoon() {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    @include antialiased();
}

@mixin icon-base($icon, $icon-size) {
    position: relative;

    &::before {
        @include icomoon();

        content: $icon;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        font-size: $icon-size;
    }
}

@mixin icon-left($icon: 'x', $icon-size: 0.5em, $padding: 1em ) {
    @include icon-base($icon, $icon-size);

    padding-left: $padding;

    &::before {
        left: 0;
        font-size: $icon-size;
    }
}

@mixin icon-left-btn($padding: 1em, $padding-icon: 1em, $icon: 'x', $icon-size: 0.5em) {
    @include icon-left($icon, $icon-size, $padding);

    &::before {
        padding-left: $padding-icon;
    }
}

@mixin icon-left-outside($padding: 1em, $icon: 'x', $icon-size: 0.5em) {
    @include icon-base($icon, $icon-size);

    margin-left: $padding;

    &::before {
        font-size: $icon-size;
        left: -$padding;
        padding-right: $padding;
    }
}

@mixin icon-right($padding: 1em, $icon: 'x', $icon-size: 0.5em) {
    @include icon-base($icon, $icon-size);

    padding-right: $padding;

    &::before {
        right: 0;
    }
}

@mixin icon-right-btn($padding: 1em, $padding-icon: 1em, $icon: 'x', $icon-size: 0.5em) {
    @include icon-right($padding, $icon, $icon-size);

    &::before {
        padding-right: $padding-icon;
    }
}

@mixin icon-right-outside($padding: 1em, $icon: 'x', $icon-size: 0.5em) {
    @include icon-base($icon, $icon-size);

    margin-right: $padding;

    &::before {
        font-size: $icon-size;
        right: -$padding;
        padding-left: $padding;
    }
}

@mixin link-icon-external-text() {
    @include icon-right-outside($padding: 1em, $icon: '\e90a', $icon-size: 0.5em);

    margin-right: 1.2em;

    &:hover,
    &:focus {
        &::before {
            top: 50%;
        }
    }

    &::before {
        right: -1.5em;
        transform: rotate(-45deg);
    }
}

