.offcanvas {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: $brand-white;
    z-index: 5000;
    transform: translateY(100%);
    transition: transform 400ms ease, height 400ms ease, opacity 300ms ease;


    &.offcanvas--open {
        transform: translateY(0);
        height: 100vh;
    }
    //&:after {
    //    content: "";
    //    position: absolute;
    //    bottom: 0;
    //    right: 15px;
    //    width: 100%;
    //    height: 6rem;
    //    background-color: $body-background-color;
    //}
}

.offcanvas__container {
    position: relative;
    height: 100%;
    overflow-y: auto;

    @include breakpoint(large) {
        display: flex;
        align-items: center;
    }

    .section {
        width: 100%;
    }
}

.offcanvas_close {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    bottom: to-rem(40px);
}
