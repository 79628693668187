.quote-container {
    display: flex;
    flex-direction: column;
    background-color: $brand-color-primary;

    &.quote-container--no-image {
        background: none;
    }

    blockquote {
        margin: 0;
    }

    @include breakpoint(small only) {
        .grid-container--media-full-width & {
            @include fluid-size((min: $page-padding-small, max: $page-padding-medium), 'padding-top');
            @include fluid-size((min: $page-padding-small, max: $page-padding-medium), 'padding-right');
            @include fluid-size((min: $page-padding-small, max: $page-padding-medium), 'padding-left');
        }
    }

    @include breakpoint(medium) {
        flex-flow: row wrap;
        height: 100%;
    }
}


.quote__image-container {
    width: 100%;
    @include spacings-size("x-page-padding", padding-top);
    @include spacings-size("x-page-padding", padding-right);
    @include spacings-size("x-page-padding", padding-left);

    @include breakpoint(medium) {
        padding: 0;
        flex: 1 1 400px;
    }
}

.quote__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    @include spacings-size($tile-horizontal-teaser-content-padding-top, padding-top);
    @include spacings-size($tile-teaser-content-padding-right, padding-right);
    @include spacings-size($tile-horizontal-teaser-content-padding-bottom, padding-bottom);
    @include spacings-size($tile-teaser-content-padding-left, padding-left);

    @include breakpoint(small only) {
        .grid-container--media-full-width & {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @include breakpoint(medium) {
        flex: 1 1 400px;
        @include spacings-size($tile-horizontal-teaser-content-padding-right, padding-right);
        @include spacings-size($tile-horizontal-teaser-content-padding-left, padding-left);
    }


    .quote-container--no-image &{
        padding: 0;
    }
}

.quotation-mark {
    display: block;
    &:first-child {
        padding-right: 0.1em;
    }

    &:last-child {
        padding-left: 0.1em;
    }

    @include breakpoint(medium) {
        display: inline;

        &:first-child {
            margin-left: -0.6em;
        }
    }
}
