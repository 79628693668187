/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=Text Container (text structure withing '.text-container')

*/

// for paragraphs, lists, tables and images (first and last item will be treated automatically)
$text-container-element-default-margin-bottom-min: to-rem(30px);
$text-container-element-default-margin-bottom-max: to-rem(30px);
$text-container-element-default-margin-top-min: to-rem(25px);
$text-container-element-default-margin-top-max: to-rem(25px);

// p.lead
$p-lead-margin-bottom-min: to-rem(25px);
$p-lead-margin-bottom-max: to-rem(30px);
$p-lead-margin-top-min: to-rem(25px);
$p-lead-margin-top-max: to-rem(30px);

// p.small
$p-small-margin-bottom-min: to-rem(10px);
$p-small-margin-bottom-max: to-rem(15px);
$p-small-margin-top-min: to-rem(10px);
$p-small-margin-top-max: to-rem(15px);

// h1
$text-container-h1-margin-bottom-min: to-rem(25px);
$text-container-h1-margin-bottom-max: to-rem(40px);
$text-container-h1-margin-top-min: to-rem(40px);
$text-container-h1-margin-top-max: to-rem(50px);

// h2
$text-container-h2-margin-bottom-min: to-rem(15px);
$text-container-h2-margin-bottom-max: to-rem(30px);
$text-container-h2-margin-top-min: to-rem(40px);
$text-container-h2-margin-top-max: to-rem(50px);

// h3
$text-container-h3-margin-bottom-min: to-rem(15px);
$text-container-h3-margin-bottom-max: to-rem(30px);
$text-container-h3-margin-top-min: to-rem(40px);
$text-container-h3-margin-top-max: to-rem(50px);

// h4
$text-container-h4-margin-bottom-min: to-rem(15px);
$text-container-h4-margin-bottom-max: to-rem(30px);
$text-container-h4-margin-top-min: to-rem(40px);
$text-container-h4-margin-top-max: to-rem(50px);

// h5
$text-container-h5-margin-bottom-min: to-rem(15px);
$text-container-h5-margin-bottom-max: to-rem(30px);
$text-container-h5-margin-top-min: to-rem(40px);
$text-container-h5-margin-top-max: to-rem(50px);

// h6
$text-container-h6-margin-bottom-min: to-rem(15px);
$text-container-h6-margin-bottom-max: to-rem(30px);
$text-container-h6-margin-top-min: to-rem(40px);
$text-container-h6-margin-top-max: to-rem(50px);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=Lists (withing .text-container)

Make sure to define this list AFTER the typography (especially font size
and line height) is defined. Otherwise you will have to readjust it.

*/

$list-item-spacing: 'y-equal-5';

// ul
$unordered-list-item-padding: 0.7em;
$unordered-list-item-icon-size: 0.3em;
$unordered-list-item-icon-spacing-top: 0.6em; // might need some fine tuning, depending on the icon and icon size
$unordered-list-item-icon-spacing-left: 0;
$unordered-list-item-icon-color: currentColor;
$unordered-list-item-icon-border-radius: 50%;

// ol
$ordered-list-item-padding: 0;
$ordered-list-item-icon-spacing-left: 0.4em;
