/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Grid system

Generate semantic grid columns with these mixins.

https://getbootstrap.com/docs/4.3/layout/grid/#sass-mixins

*/

@mixin make-container(
    $gutter: $page-padding-width,
    $gutter-xs: $page-padding-width-xs
) {
    @include fluid-size(
        (
            min: $gutter-xs,
            max: $gutter,
        ),
        'padding-left'
    );
    @include fluid-size(
        (
            min: $gutter-xs,
            max: $gutter,
        ),
        'padding-right'
    );

    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@mixin make-row(
    $gutter: $grid-gutter-width,
    $gutter-xs: $grid-gutter-width-xs
) {
    @include fluid-size(
        (
            min: $gutter-xs / 2,
            max: $gutter / 2,
        ),
        'margin-left',
        $negative: true
    );
    @include fluid-size(
        (
            min: $gutter-xs / 2,
            max: $gutter / 2,
        ),
        'margin-right',
        $negative: true
    );

    display: flex;
    flex-wrap: wrap;
}

@mixin make-col-ready(
    $gutter: $grid-gutter-width,
    $gutter-xs: $grid-gutter-width-xs
) {
    @include fluid-size(
        (
            min: $gutter-xs / 2,
            max: $gutter / 2,
        ),
        'padding-left'
    );
    @include fluid-size(
        (
            min: $gutter-xs / 2,
            max: $gutter / 2,
        ),
        'padding-right'
    );

    position: relative;
    // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we use `flex` values
    // later on to override this initial width.
    width: 100%;
}

@mixin make-col($size, $columns: $grid-columns) {
    flex: 0 0 percentage($size / $columns);
    // Add a `max-width` to ensure content within each column does not blow out
    // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
    // do not appear to require this.
    max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
    $num: $size / $columns;

    margin-left: if($num == 0, 0, percentage($num));
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Column Tweaks

*/

@mixin make-row-sm-max($gutter: $grid-gutter-width-xs) {
    @include media-breakpoint-down(sm) {
        margin-left: -($gutter/2);
        margin-right: -($gutter/2);
    }
}

@mixin column-padding-sm-max($gutter: $grid-gutter-width-xs) {
    @include media-breakpoint-down(sm) {
        padding-left: $gutter/2 !important;
        padding-right: $gutter/2 !important;
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Content Plugin > Simple flexbox mixins to add support for ordering on mobile devices

*/

@mixin content-plugin-col-mobile-flexbox($flex-bp: breakpoint-max(sm)) {
    @media (max-width: $flex-bp) {
        .content-section__col-container-inner {
            flex-direction: column;
        }
    }
}

@mixin content-plugin-col-mobile-flexbox-spacing-fix(
    $flex-bp: breakpoint-max(sm)
) {
    @media (max-width: $flex-bp) {
        .content-section__col-container-inner {
            @include spacings-size(
                $content-plugin-stacked-col-spacing,
                margin-top,
                $negative: true
            );
        }
    }
}
