// https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_print.scss

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */

// ==========================================================================
// Print styles.
// Inlined to avoid the additional HTTP request: h5bp.com/r
// ==========================================================================

//variables
$print-background: transparent !default;
$print-color: #000 !default;
$print-pre-border-color: #999 !default;
$print-btn-caret-border-top-color: #000 !default;
$print-label-border-color: #000 !default;
$print-table-th-background-color: #fff !default;
$print-table-bordered-border-color: #ddd !default;

@media print {
    *,
    *::before,
    *::after {
        background: $print-background !important;
        color: #000 !important; // Black prints faster: h5bp.com/
        box-shadow: none !important;
        text-shadow: none !important;
        color: inherit;
        //-webkit-print-color-adjust: exact;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:not(.brand-logo__link)::after {
        content: ' (' attr(href) ')';
    }

    abbr[title]::after {
        content: ' (' attr(title) ')';
    }

    // Don't show links that are fragment identifiers,
    // or use the `javascript:` pseudo protocol
    a[href^='#']::after,
    a[href^='javascript:']::after {
        content: '';
    }

    pre,
    blockquote {
        border: 1px solid $print-pre-border-color;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; // h5bp.com/t
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }
    span {
        color: inherit;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
        color: inherit;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    // Bootstrap specific changes start

    // Bootstrap components
    .navbar {
        display: none;
    }

    .btn,
    .dropup > .btn {
        > .caret {
            border-top-color: $print-btn-caret-border-top-color !important;
        }
    }

    .label {
        border: 1px solid $print-label-border-color;
    }

    .table {
        border-collapse: collapse !important;

        td,
        th {
            background-color: $print-table-th-background-color !important;
        }
    }

    .table-bordered {
        th,
        td {
            border: 1px solid $print-table-bordered-border-color !important;
        }
    }

    .show-breakpoint,
    .nav-toggle__container,
    .offcanvas,
    .watchlist,
    .edit-tools {
        display: none !important;
    }
    //
    //.tpl-watchlist-print {
    //    .site-content {
    //        display: flex;
    //        flex-direction: column;
    //        justify-content: space-between;
    //        padding: 0;
    //        margin: 0;
    //    }
    //
    //    .watchlist-print__container {
    //        width: 100%;
    //    }
    //}

    .brand-logo__image--large {
        @include breakpoint(medium) {
            height: 80px !important;
        }
    }

    .cell--full-on-print {
        width: 100% !important;
    }

    .hide-for-print {
        display: none !important;
    }

    .show-for-print {
        display: block !important;
    }


    .site-content {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }
}


.footer-print {
    display: flex;
}

.tpl-watchlist-print {

    @media print {
        filter: grayscale(100%);
    }


    @include breakpoint(small only) {
        .product-card {
            align-items: flex-start;
        }
    }

    .site-content {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    .watchlist-print__container {
        width: 100%;
    }

    .hide-for-print {
        display: none !important;
    }

    .show-for-print {
        display: block !important;
    }
}
