/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=overview-link sytles

These are used in detail templates in block "overview_link"

*/

.overview-link {
    @include spacings-size(y-section-3, margin-top);
    @include spacings-size(y-equal-6, padding);

    a {
        display: flex;
        align-items: center;
        //@include font-default-tighter();
        color: inherit;
        text-decoration: inherit;
        transition: color $link-transition-duration
            $link-transition-timing-function;

        &:hover,
        &:focus {
            color: $link-color-hover;

            svg {
                fill: $link-color-hover;
            }
        }

        span {
            //display: inline-flex;
            //align-items: center;
        }

        svg {
            flex-shrink: 0;
            margin-right: 0.5rem;
            width: 1.1875rem;
            transition: fill $link-transition-duration
                $link-transition-timing-function;
        }
    }

    .softpage & {
        display: none;
    }
}
