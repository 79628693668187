.social-icons {
    text-align: $social-profile-icon-alignment;

    &__list {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    &__list-item {
        display: inline-block;

        & + .social-icons__list-item {
            margin-left: $social-profile-icon-spacing;
        }
    }

    &__link {
        // no underline, what so ever!
        &:link,
        &:visited,
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            border: none;
        }
        // hover styles
        &:hover,
        &:focus {
            .social-icons__icon {
                &::before {
                    color: $social-profile-icon-color-hover;
                    opacity: $social-profile-icon-opacity-hover;
                    // inverted colors
                    .inverted-colors & {
                        color: $social-profile-icon-inverted-color-hover;
                    }
                }
            }
        }
    }

    &__icon {
        display: block;

        &::before {
            @include icomoon();

            display: block;
            color: $social-profile-icon-color;
            opacity: $social-profile-icon-opacity;
            transition-property: $social-profile-icon-transition-property;
            transition-duration: $social-profile-icon-transition-duration;
            transition-timing-function: $social-profile-icon-transition-timing-function;
            font-size: $social-profile-icon-size-xs;

            @include media-breakpoint-up(md) {
                font-size: $social-profile-icon-size-md;
            }

            @include media-breakpoint-up(xl) {
                font-size: $social-profile-icon-size-xl;
            }
            // inverted colors
            .inverted-colors & {
                color: $social-profile-icon-inverted-color;
            }
        }
    }

    // $social-profile-icons is defined in the project variables file
    @each $selector, $icon in $social-profile-icons {
        &__icon--#{$selector} {
            &::before {
                content: $icon;
            }
        }
    }
}
