.seo-accordion__title {
    @include spacings-size('y-equal-3', padding-bottom);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

SEO Accordion plugin styles

*/

.seo-accordion {
    border-bottom: $seo-accordion-border-width solid $seo-accordion-border-color;

    &__item {
        position: relative;

        & + & {
            margin-top: -$seo-accordion-border-width;
        }
    }

    &__title {
        @include spacings-size($seo-accordion-content-spacing, padding);
        @include icon-right(
            $padding: 0,
            $icon: '\e90f',
            $icon-size: $seo-accordion-icon-size
        );

        border-top: $seo-accordion-border-width solid $seo-accordion-border-color;
        color: $seo-accordion-title-color;
        display: block;
        padding-right: $seo-accordion-icon-size * 2;
        text-decoration: none;

        &::before {
            width: $seo-accordion-icon-size;
            height: $seo-accordion-icon-size;
            transition: $seo-accordion-transition;
        }

        &:hover {
            color: $seo-accordion-title-color-hover;

            &::before {
                color: $seo-accordion-icon-color-hover;
            }
        }

        .seo-accordion__item--open & {
            &::before {
                transform: translate(0, -50%) rotate(45deg);
            }
        }

        h3 {
            @include font-lead();

            color: inherit;
            transition: $seo-accordion-transition;
        }
    }

    &__content {
        display: none;
        overflow: auto;

        &::before,
        p::before,
        p::after,
        &::after {
            content: '';
            display: block;
            margin: 0;
        }

        &::after {
            @include spacings-size(
                    $seo-accordion-content-spacing,
                    padding-bottom
            );
        }
    }
}
