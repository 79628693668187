/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

App =list View (this includes ALL plugin templates such as Grid (Static), Table, and co) [=appli]

*/

// "read more" link: globally set the button style
$app-list-btn-variation: 'default'; // only valid button variations keyboard e.g. 'default', 'primary', ...
$app-list-btn-padding-enabled: true; // set to 'false' if the default button padding should be removed (might be desired with varation 'link')

// "load more/all" link: globally set the button style
$app-list-load-more-btn-variation: 'primary';
$app-list-load-more-btn-padding-enabled: true; // set to 'false' if the default button padding should be removed (might be desired with varation 'link')

// vertical spacings of CONTENT of e.g. grid items
$manage-spacings-container: 'y-scale-6'; // pixel-value or spacing value. set to 'false' if no spacing should be used

// in case of an empty results, define a global min height
$app-list-no-result-cotainer-min-height-min: 6.25rem;
$app-list-no-result-cotainer-min-height-max: 18.75rem;

// App Content Plugin specific spacings: VERTICAL spacing between list or grid items

// LIST template
$app-content-plugin-list-item-spacing: 'y-scale-2'; // pixel-value or spacing value. set to 'false' if no spacing should be used

// STATIC grid template
$app-content-plugin-static-grid-item-spacing: 'y-gutter-width'; // pixel-value or spacing value. set to 'false' if no spacing should be used

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

App =list View (default templates)

*/

// slider
$app-list-slider-overlay-gradient-height: 66%;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

App =detail View (within Softpage or as Deeplink) [=appde]

*/

// stacked columns
$app-detail-col-stack-spacing-xs-max: 40px;

// deeplink: set max-width
$app-detail-content-container-max-width-xs: false; // pixel value. set to 'false' if default container width should be used
$app-detail-content-container-max-width-xxl: false; // pixel value. set to 'false' if default container width should be used
// within softpage: set max-width
$app-detail-content-container-softpage-max-width-xs: false; // pixel value. set to 'false' if the deeplink width should be used
$app-detail-content-container-softpage-max-width-xxl: false; // pixel value. set to 'false' if the deeplink width should be used

// within softpage, create some spacing around all sections
// Note: Sets 'padding'-property
$app-detail-softpage-spacing-xs: 0 0 0 0;
$app-detail-softpage-spacing-sm: 0 0 0 0;
$app-detail-softpage-spacing-md: 0 0 0 0;
$app-detail-softpage-spacing-lg: 0 0 0 0;
$app-detail-softpage-spacing-xl: 0 0 0 0;
$app-detail-softpage-spacing-xxl: 0 0 0 0;

// Header section (spacing between)
$app-detail-header-section-spacing-property: 'margin'; // either 'margin' or 'padding'
$app-detail-header-section-spacing-top: false; // spacing value. set to 'false' if no spacing should be used
$app-detail-header-section-spacing-bottom: 'y-scale-2'; // spacing value. set to 'false' if no spacing should be used

// Heading Container (spacing to next element)
$app-detail-heading-container-spacing-bottom: 'y-scale-4'; // spacing value. set to 'false' if no spacing should be used

// Spacing between LEAD and CONTENT
$app-detail-spacing-between-lead-and-content: 'y-scale-2'; // spacing value. set to 'false' if no spacing should be used

// Created (spacing top)
$app-detail-created-spacing-top: 'y-scale-6'; // spacing value. set to 'false' if no spacing should be used

// Content Section
$app-detail-content-section-spacing: 'y-scale-2'; // spacing value. set to 'false' if no spacing should be used

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

The AJAX container (where items are exchanged OR appended) [=loadco]

*/

// the transition settings for the AJAX container AND the 'load-more-container' when changing categories
$load-more-swop-category-transition-property: opacity;
$load-more-swop-category-transition-duration: 100ms;
$load-more-swop-category-transition-timing-function: ease-in;
$load-more-swop-category-loader-spacing-top-xs: 100px;
$load-more-swop-category-loader-spacing-top-sm: 150px;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

=load =more" container [=loadmo]

VERTICAL spacing

*/

$load-more-container-spacing-top: 'y-equal-5';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

App =filter spacing

*/

// spacing to next element (grid, list, ...)
$filter-container-spacing-bottom: 'y-scale-5';

// spacing between selects
$filter-container-spacing-between-select: 0.5em; // 'em' recommended
