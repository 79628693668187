.people-plugin:not(.app-content-plugin) {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
        flex-direction: row;
        align-items: center;
    }
}

.people-plugin__image-container {
    @include spacings-size((min: to-rem(100px), max: to-rem(160px)), width);

    @include breakpoint(medium) {
        @include spacings-size(y-scale-7, margin-right);
    }
}

.participant-item__container {
    display: flex;
    align-items: center;
}

.people-contact-details {
    display: block;
    padding: 0;
}

.participant-portrait {
    @include spacings-size((min: to-rem(60px), max: to-rem(80px)), width);
    @include spacings-size(y-scale-7, margin-right);
}


.people-job-item {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    min-height: to-rem(400px);
    padding-top: to-rem(150px);
    @include spacings-size('y-scale-4', padding-right);
    @include spacings-size('y-scale-4', padding-bottom);
    @include spacings-size('y-scale-4', padding-left);
    background-color: $brand-black;
    transition: background-color 300ms ease-in-out;

    @include breakpoint(medium) {
        @include spacings-size('y-scale-4', padding-top);
    }

    &:hover {
        background-color: $brand-gray-15;
    }
}

.people-job-item__eyecatcher {
    position: absolute;
    @include spacings-size('y-scale-5', top);
    @include spacings-size('y-scale-5', right);
    @include spacings-size((min: to-rem(80px), max: to-rem(150px)), width);
}
