/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Global Swiper Custom Styles

*/
// Varibables
$swiper-border-color: $gray-lighter !default;
$swiper-content-color: $gray-light !default;

.swiper-container {
    opacity: 0;
    transition: opacity 300ms ease-in-out;

    &.swiper-initialized,
    &.swiper-disabled {
        opacity: 1;
    }

    &.swiper-disabled {
        + .swiper-optional-container {
            display: none;
        }
    }
}

// Buttons (arrows)
.swiper-button {
    background: none;
    position: absolute;
    z-index: 1;
    top: auto;
    bottom: 0;
    transform: translate(0, 0);
    margin: 0;
    border: 0;
    cursor: pointer;
    width: $swiper-default-arrows-button-width-xs;
    height: $swiper-default-arrows-button-height-xs;

    @include media-breakpoint-up(md) {
        width: $swiper-default-arrows-button-width-md;
        height: $swiper-default-arrows-button-height-md;
    }

    @include media-breakpoint-up(xl) {
        width: $swiper-default-arrows-button-width-xl;
        height: $swiper-default-arrows-button-height-xl;
    }

    &:link,
    &:visited {
        color: $swiper-default-arrows-color;
        border: none;
        text-decoration: none;
    }

    &:hover,
    &:focus {
        border: none;
        text-decoration: none;

        .swiper-button__inner {
            opacity: $swiper-default-arrows-opacity-hover;

            &::before {
                color: $swiper-default-arrows-color-hover;
            }
        }
    }
    // icon
    &__inner {
        display: block;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        opacity: $swiper-default-arrows-opacity;
        transition-property: $swiper-default-arrows-transition-property;
        transition-duration: $swiper-default-arrows-transition-duration;
        transition-timing-function: $swiper-default-arrows-transition-timing-function;
        width: $swiper-default-arrows-button-width-xs;
        height: $swiper-default-arrows-button-height-xs;
        background-color: $swiper-default-arrows-button-bg-xs;

        @include media-breakpoint-up(md) {
            width: $swiper-default-arrows-button-width-md;
            height: $swiper-default-arrows-button-height-md;
        }

        @include media-breakpoint-up(xl) {
            width: $swiper-default-arrows-button-width-xl;
            height: $swiper-default-arrows-button-height-xl;
        }

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $swiper-default-arrows-color;
            font-size: $swiper-default-arrows-icon-size-xs;
            transition: inherit;

            @include media-breakpoint-up(md) {
                font-size: $swiper-default-arrows-icon-size-md;
            }

            @include media-breakpoint-up(xl) {
                font-size: $swiper-default-arrows-icon-size-xl;
            }

            @include icomoon();
        }
    }
    // no point in displaying controls if there is only 1 slide. Class toggled via JS.
    .swiper-disabled & {
        display: none;
    }
}

.swiper-button-prev {
    left: -$swiper-default-arrows-button-width-xs/3;

    @include media-breakpoint-up(md) {
        left: -$swiper-default-arrows-button-width-md/3;
    }

    @include media-breakpoint-up(xl) {
        left: -$swiper-default-arrows-button-width-xl/3;
    }

    &__inner {
        left: 0;

        &::before {
            content: $swiper-default-arrows-icon-previous;
        }
    }
}

.swiper-button-next {
    right: -$swiper-default-arrows-button-width-xs/3;

    @include media-breakpoint-up(md) {
        right: -$swiper-default-arrows-button-width-md/3;
    }

    @include media-breakpoint-up(xl) {
        right: -$swiper-default-arrows-button-width-xl/3;
    }

    &__inner {
        right: 0;

        &::before {
            content: $swiper-default-arrows-icon-next;
        }
    }
}

.swiper-optional-container {
    @include clearfix();

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    height: $swiper-default-arrows-button-height-xs;
    clear: both;

    @include media-breakpoint-up(md) {
        height: $swiper-default-arrows-button-height-md;
    }

    @include media-breakpoint-up(xl) {
        height: $swiper-default-arrows-button-height-xl;
    }

    .swiper-disabled & {
        display: none;
    }
    border-bottom: 1px solid $swiper-border-color;
}

.swiper-content-container {
    .swiper-content {
        margin-top: $grid-gutter-width/4;
        color: $swiper-content-color;
        display: none;

        &--visible {
            display: block;
        }
    }
}

.swiper-slide-details-container {
    @include max-width();
    @include column-padding-sm-max();

    margin: $grid-gutter-width auto 0;

    @include media-breakpoint-up(md) {
        max-width: 900px;
    }

    .swiper-content {
        text-align: center;

        &__title {
            @include font-h3();

            margin-bottom: $grid-gutter-width/2;
        }

        &__logo__list {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: $grid-gutter-width/2 0 $grid-gutter-width;
        }

        &__logo {
            max-height: $grid-gutter-width * 4;

            @include media-breakpoint-up(md) {
                max-height: $grid-gutter-width * 5;
            }
        }
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Counter

*/

.swiper-counter {
    display: none;
    float: right;

    &__separator {
        &::before {
            content: '/';
        }
    }
}

// activated via plugin
.swiper-counter--active {
    display: block;
}
