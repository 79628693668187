.project-facts {
    background: $brand-black;
    @include spacings-size('y-scale-1', 'padding-top');
    @include spacings-size('y-scale-1', 'padding-bottom');
}

.project-facts__container {
    @include breakpoint(medium) {
        min-height: 85vh;
    }
}
