.tpl-error {
    .site-content {
        display: flex;
        align-items: center;

        .content-section {
            @include spacings-size($content-section-spacing, margin-top);
            @include spacings-size($content-section-spacing, margin-bottom);

            display: flex;
            align-items: center;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            > * {
                margin: 0 auto;
                width: 100%;
            }
        }
    }

    footer.site-footer {
        margin-top: 0;
    }
}
