/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Tabs

*/

.custom-nav-tabs {
    padding: 0;
    border-bottom-width: $custom-tabs-border-bottom-width;
    border-bottom-style: $custom-tabs-border-bottom-style;
    border-bottom-color: $custom-tabs-border-bottom-color;
    // mobile optimizations
    @include media-breakpoint-down(xs) {
        border-bottom: none;
    }

    @include clearfix();

    // define spacing to next element
    & + * {
        @include spacings-size($custom-tabs-spacing-bottom, margin-top);
    }

    &__item {
        list-style: none;
        float: left;
        margin-left: -$custom-tabs-item-border-width;
        // mobile optimizations
        @include media-breakpoint-down(xs) {
            margin-left: 0;
            margin-right: $grid-gutter-width/4;

            &:last-child {
                margin-right: 0;
            }
        }

        @if $custom-tabs-register-look-activated == true {
            margin-bottom: -$custom-tabs-item-border-width;
            // mobile optimizations
            @include media-breakpoint-down(xs) {
                margin-bottom: $grid-gutter-width/4;
            }
        }

        &:first-child {
            margin-left: 0;
        }

        &.active {
            // ACTIVE state
            > a {
                &,
                &:link,
                &:visited {
                    color: $custom-tabs-item-color-active;
                    background-color: $custom-tabs-item-bg-active;
                }

                &:hover,
                &:active,
                &:focus {
                    background-color: $custom-tabs-item-bg-active-hover;

                    span {
                        border: none;
                    }
                }
            }
        }
    }

    &__link {
        display: block;
        padding: $custom-tabs-item-padding-xs;
        border-width: $custom-tabs-item-border-width;
        border-style: $custom-tabs-item-border-style;
        border-color: $custom-tabs-item-border-color;
        border-bottom: none;
        text-decoration: none;
        transition: $custom-tabs-item-transition;

        // mobile optimizations
        @include media-breakpoint-down(xs) {
            border-bottom-width: $custom-tabs-item-border-width;
            border-bottom-style: $custom-tabs-item-border-style;
            border-bottom-color: $custom-tabs-item-border-color;
        }

        @include media-breakpoint-up(md) {
            padding: $custom-tabs-item-padding-md;
        }

        // DEFAULT state
        &,
        &:link,
        &:visited {
            color: $custom-tabs-item-color-default;
            background-color: $custom-tabs-item-bg-default;
        }

        &:hover,
        &:active,
        &:focus {
            background-color: $custom-tabs-item-bg-default-hover;

            span {
                border-bottom: 1px solid $brand-color-secondary;
            }
        }
    }
}
