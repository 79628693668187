:root {
    /* Grays */
    --myst: #f0f0f0;
    --gray: #6d6c70;
    --light-gray: #b6b6b7;
    --dark-gray: #333;
    --text: #5c5e51;
    --darkblack: #111;
    --black: #000000;
    --white: #fff;

    /* Project grays */

    --grey-15: #262626;
    --grey-35: #595959;
    --grey-50: #808080;
    --grey-60: #999999;
    --grey-70: #b3b3b3;
    --grey-80: #cccccc;
    --grey-90: #e6e6e6;
}

$general-block-margin: $page-padding-small;

.section {
    &.hidden-on-desktop {
        @include breakpoint(medium) {
            display: none;
            // when logged it, show in any case
            .cms-ready & {
                display: block;
                opacity: 0.4;
            }
        }
    }

    &.hidden-on-mobile {
        @include breakpoint(small only) {
            display: none;
            // when logged it, show in any case
            .cms-ready & {
                display: block;
                opacity: 0.4;
            }
        }
    }
}

.section--bg {
    @include content-section-spacing-padding();

    .hero & {
        margin-top: 0;
        padding-top: 0;
        @include spacings-size(y-scale-2, padding-bottom);
        @include breakpoint(large) {
            padding-top: $site-header-height-xxl;
        }
    }
}

.cell--full-width {
    margin: 0 100%;
}

.align-middle {
    align-self: center;
}

.align-bottom {
    align-self: flex-end;
}

.grid-container {
    &.grid-container--media-full-width-small {
        //overflow: hidden;
        @include breakpoint(small down) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.grid-container--media-full-width-medium {
        //overflow: hidden;
        @include breakpoint(medium down) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    //&.grid-container--media-full-width-large {
    //    //overflow: hidden;
    //    @include breakpoint(medium down) {
    //        padding-left: 0;
    //        padding-right: 0;
    //    }
    //}
}

.grid-x > .large-8 {
    @include breakpoint(large) {
        width: 83.33333%;
    }
    @include breakpoint(xlarge) {
        width: 66.66667%;
    }
}

.text-plugin,
.quote__content,
.people-plugin,
.button-list {
    .grid-container--media-full-width-medium .medium-12 & {
        @include breakpoint(medium only) {
            @include fluid-size(
                    (
                        min: $page-padding-small,
                        max: $page-padding-medium,
                    ),
                    'padding-right'
            );
            @include fluid-size(
                    (
                        min: $page-padding-small,
                        max: $page-padding-medium,
                    ),
                    'padding-left'
            );
        }
    }


    .grid-container--media-full-width-medium .medium-10 & {
        @include breakpoint(medium only) {

            @include fluid-size(
                    (
                        min: $page-padding-small * 0.833,
                        max: $page-padding-medium * 0.833,
                    ),
                    'padding-right'
            );
            @include fluid-size(
                    (
                        min: $page-padding-small * 0.833,
                        max: $page-padding-medium * 0.833,
                    ),
                    'padding-left'
            );
        }
    }

    .grid-container--media-full-width-medium .medium-8 & {
        @include breakpoint(medium only) {

            @include fluid-size(
                    (
                        min: $page-padding-small * 0.666,
                        max: $page-padding-medium * 0.666,
                    ),
                    'padding-right'
            );
            @include fluid-size(
                    (
                        min: $page-padding-small * 0.666,
                        max: $page-padding-medium * 0.666,
                    ),
                    'padding-left'
            );
        }
    }

    .grid-container--media-full-width-medium .medium-6 & {
        @include breakpoint(medium only) {

            @include fluid-size(
                    (
                        min: $page-padding-small * 1,
                        max: $page-padding-medium * 1,
                    ),
                    'padding-right'
            );
            @include fluid-size(
                    (
                        min: $page-padding-small * 1,
                        max: $page-padding-medium * 1,
                    ),
                    'padding-left'
            );
        }
    }

    @include breakpoint(small only) {
        .grid-container--media-full-width-small &,
        .grid-container--media-full-width-medium & {
            @include breakpoint(small down) {
                @include fluid-size(
                        (
                            min: $page-padding-small,
                            max: $page-padding-medium,
                        ),
                        'padding-right'
                );
                @include fluid-size(
                        (
                            min: $page-padding-small,
                            max: $page-padding-medium,
                        ),
                        'padding-left'
                );
            }
        }
    }
}


@include breakpoint(small down) {
    .order-1 {
        order: 1;
    }

    .order-2 {
        order: 2;
    }

    .order-3 {
        order: 3;
    }

    .order-4 {
        order: 4;
    }
}

.grid-container {
    @include fluid-size(
            (
                min: $page-padding-small,
                max: $page-padding-medium,
            ),
            'padding-right'
    );
    @include fluid-size(
            (
                min: $page-padding-small,
                max: $page-padding-medium,
            ),
            'padding-left'
    );
}

.grid-padding-x > .cell {
    @include fluid-size(
            (
                min: $gutter-width-small / 2,
                max: $gutter-width-medium / 2,
            ),
            'padding-right'
    );
    @include fluid-size(
            (
                min: $gutter-width-small / 2,
                max: $gutter-width-medium / 2,
            ),
            'padding-left'
    );

    .grid-container--media-full-width-medium & {
        //@include breakpoint(medium down) {
        //    @include fluid-size(
        //            (
        //                min: $gutter-width-small / 2,
        //                max: $gutter-width-medium / 2,
        //            ),
        //            'margin-right'
        //    );
        //    @include fluid-size(
        //            (
        //                min: $gutter-width-small / 2,
        //                max: $gutter-width-medium / 2,
        //            ),
        //            'margin-left'
        //    );
        //}
    }
}

.cell.space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

//
//.grid--full-height {
//    @include breakpoint(medium) {
//        height: 100vh;
//        align-content: center;
//    }
//}

.grid-container--bg {
    position: relative;
    @include fluid-size((min: $page-padding-small, max: $page-padding-medium), 'margin-right');
    @include fluid-size((min: $page-padding-small, max: $page-padding-medium), 'margin-left');

    @include breakpoint(xxlarge) {
        max-width: $project-width;
        margin: 0 auto;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        margin: 0 auto;
        z-index: -1;

        @include breakpoint(medium) {
            @include fluid-size(
                    (
                        min: $page-padding-small,
                        max: $page-padding-medium,
                    ),
                    'right'
            );
            @include fluid-size(
                    (
                        min: $page-padding-small,
                        max: $page-padding-medium,
                    ),
                    'left'
            );
        }
    }

    .topic-header & {
        @include spacings-size('y-scale-2', 'padding-bottom');

        &:after {
            background: $brand-color-primary;
            z-index: 0;
        }
    }
}

.grid-container:not(.full) > .grid-padding-x {
    @include fluid-size(
            (
                min: -$gutter-width-small / 2,
                max: -$gutter-width-medium / 2,
            ),
            'margin-right'
    );
    @include fluid-size(
            (
                min: -$gutter-width-small / 2,
                max: -$gutter-width-medium / 2,
            ),
            'margin-left'
    );
}

.cms-placeholder {
    display: none;
}
