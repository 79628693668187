/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Softpage

*/

$softpage-offset-top-compact-mode-md: $site-header-compact-mode-height-md;
$softpage-offset-top-compact-mode-xl: $site-header-compact-mode-height-xl;
$softpage-offset-top-compact-mode-xxl: $site-header-compact-mode-height-xxl;


.softpage {
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Reduce content-section margin-top

    */

    .site-content {
        & > .content-section {
            margin-top: 0;
        }
    }

    .tingle-modal-box {
        &__content {
            padding-top: 0;

            @include media-breakpoint-up(lg) {
                @include make-col(24);
                @include make-col-offset(0);
            }
        }
    }
}

.tingle-modal-header__link-close {
    cursor: pointer;
}

body.tingle-enabled {
    :not(.softpage-visible) {
        @include media-breakpoint-down(sm) {
            .site-header,
            .site-content,
            .site-footer {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.hide-on-softpage {
    display: block;
    .softpage-visible & {
        display: none;
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Softpage

*/

.softpage {
    opacity: 1;
    visibility: visible;
    transform: translate(0, $softpage-content-offset);
    transition: $softpage-content-transition;
    cursor: auto;





    &.tingle-modal--visible {
        transform: translateY(0);
    }

    .tingle-modal-header {
        @include print() {
            display: none;
        }
    }
}

.tingle-modal__close {
    display: none;
    position: fixed;
    line-height: 1;
    top: 0 !important;
    left: auto;

    &:focus {
        outline: none;
    }

    // keep site header visible when opened
    @if $softpage-site-header-visible-when-opened {
        @include media-breakpoint-up(md) {
            top: $softpage-offset-top-md !important;
        }

        @include media-breakpoint-up(xxl) {
            top: $softpage-offset-top-xxl !important;
        }
    }

    // Special case: Softpage behind site-header
    @if $site-header-compact-mode-enabled == true {
        .header-compact-mode & {
            top: 0 !important;
            // keep site header visible when opened
            @if $softpage-site-header-visible-when-opened {
                @include media-breakpoint-up(md) {
                    top: $softpage-offset-top-compact-mode-md !important;
                }

                @include media-breakpoint-up(xl) {
                    top: $softpage-offset-top-compact-mode-xl !important;
                }

                @include media-breakpoint-up(xxl) {
                    top: $softpage-offset-top-compact-mode-xxl !important;
                }
            }
        }
    }

    right: $softpage-close-btn-position-right-xs;
    width: $softpage-close-btn-width-xs;
    height: $softpage-close-btn-height-xs;

    @include media-breakpoint-up(md) {
        transform: translate(0, 0);
        background: transparent;
        border-radius: 0;
        right: $softpage-close-btn-position-right-md;
        width: $softpage-close-btn-width-md;
        height: $softpage-close-btn-height-md;
    }

    @include media-breakpoint-up(xl) {
        right: $softpage-close-btn-position-right-xl;
        width: $softpage-close-btn-width-xl;
        height: $softpage-close-btn-height-xl;
    }

    @include media-breakpoint-up(xxl) {
        right: $softpage-close-btn-position-right-xxl;
        width: $softpage-close-btn-width-xxl;
        height: $softpage-close-btn-height-xxl;
    }
}


.tingle-modal-box {
        margin: 0;
        width: 100%;
        //min-height: 0; // this one is set on a project basis, so we gotta force it

        &__content {
            //@include spacings-size('spacing-scale-2', 'padding-top');
            //@include spacings-size('spacing-section-4', 'padding-bottom');

            padding-left: 0 !important;
            padding-right: 0 !important;
            max-width: none;

            > .content-section {
                &:first-child {
                    padding-top: 0;
                }
            }

            //@include media-breakpoint-up(lg) {
            //    @include make-col(16);
            //    @include make-col-offset(4);
            //}

            @include print() {
                padding-top: 0 !important;
            }
        }
    }
