$button-width: to-rem(122);
$button-small-width: to-rem(110);

$button-padding: 1rem;
$button-tiny-padding: to-rem(14px);


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Links

*/

// Plugin link
$link-transition-property:           color, border-color;
$link-transition-duration:           $global-color-transition-duration;
$link-transition-timing-function:    $global-color-timing-function;

$link-color:                         $brand-gray-15;
$link-color-hover:                   $brand-gray-40;

$link-color-inverted:                $brand-white;

$link-border-color:                  transparent;
$link-color-border-inverted:         transparent;
$link-icon-color:                    $link-color;
$link-icon-color-hover:              $link-color-hover;
$link-border-color-hover:            $brand-gray-15;
$link-color-border-hover-inverted:   $brand-gray-15;

$link-color-hover-inverted:          $brand-white;

$link-decoration:                    none;
$link-decoration-color:              none;
$link-decoration-underline:          false;
$link-decoration-color:              none;
$link-decoration-hover:              false;

$link-icon-height-external:      0.7em;
$link-icon-height-more:          0.7em;
$link-icon-height-softpage:      1.4em;

// Textlink
$textlink-color:                     inherit;
$textlink-color-hover:               inherit;

$textlink-color-inverted:            $brand-white;
$textlink-color-hover-inverted:      $brand-white;

$textlink-border-color:              $brand-gray-70;
$textlink-border-color-hover:        $brand-gray-95;

$textlink-color-border-inverted:     $brand-gray-60;
$textlink-color-border-hover-inverted: $brand-gray-15;

$textlink-decoration:                none;
$textlink-decoration-underline:      true;
$textlink-decoration-color:          $brand-gray-60;
$textlink-decoration-hover:          false;




$textlink-icon-color:                $brand-gray-60;
$textlink-icon-color-hover:          inherit;
$textlink-icon-color-inverted:       $brand-gray-90;
$textlink-icon-color-hover-inverted: $link-color-hover-inverted;

$textlink-icon-height-external:      0.7em;
$textlink-icon-height-more:          0.7em;
$textlink-icon-height-softpage:      1em;


// inverted text on dark background
$link-inverted-color:               $color-default-secondary;
$link-inverted-color-hover:         $color-default-secondary;
$link-inverted-border-color:        $color-default-secondary;
$link-inverted-border-color-hover:  $color-default-secondary;



$link-variations: (
    // Textlink
    'default': (
        'default': (
            'container':                     'mask',
            'label-color':                   $body-color,
            //'label-font':                    $font-btn-default-font-family,
            //'label-size':                    $font-btn-default-font-sizes,
            //'label-letter-spacing':          $font-btn-default-letter-spacing,
            //'label-weight':                  $font-btn-default-font-weight,
            'icon-color':                    $brand-gray-50,
            'icon-size-softpage':            $textlink-icon-height-softpage,
            'icon-size-external':            $textlink-icon-height-external,
            'border-color':                  $brand-gray-70,

            'inverted': (
                'container':                 'mask',
                'label-color':               $brand-white,
                'icon-color':                $brand-gray-50,
                'border-color':              $brand-gray-35,
            )
        ),

        'hovered': (
            'container':                     'mask',
            'label-color':                   $body-color,
            'icon-color':                    $brand-gray-40,
            'border-color':                  $brand-gray-90,

            'inverted': (
                'container':                 'mask',
                'label-color':               $brand-white,
                'icon-color':                $brand-gray-60,
                'border-color':              $brand-gray-15,
            )
        ),
    ),

    // Button Plugin
    'plugin': (
        'default': (
            'container':                     false,
            'label-color':                   $body-color,
            'icon-color':                    $body-color,
            'icon-size-softpage':            $link-icon-height-softpage,
            'icon-size-external':            $link-icon-height-external,
            'border-color':                  $link-border-color,
            'font-crop':                     True,

            'inverted': (
                'container':                 false,
                'label-color':               $brand-white,
                'icon-color':                $brand-white,
                'border-color':              $brand-gray-60,
            )
        ),

        'hovered': (
            'container':                     false,
            'label-color':                   $brand-primary-color-50,
            'icon-color':                    $brand-primary-color-50,
            'border-color':                  $link-border-color,

            'inverted': (
                'container':                 false,
                'label-color':               $brand-white,
                'icon-color':                $brand-white,
            )
        ),
    ),
);

@each $link, $type in $link-variations {
     //@debug  "link vars: #{$link}";
    .link--#{$link} {
        transition: box-shadow $global-color-transition-duration $global-color-timing-function,
        color $global-color-transition-duration $global-color-timing-function;
        color: map-deep-get($type, "default", 'label-color');
        white-space: nowrap;

        .meta-small & {
            padding-right: 0 !important;

            @include breakpoint(small only) {
                justify-content: center;
            }

            .icon {
                display: none;
            }
        }


        &.link--softpage {
            .icon {
                 height: 0;
                width: map-deep-get($type, "default", 'icon-size-softpage');
                align-items: center;
                svg {
                    height: map-deep-get($type, "default", 'icon-size-softpage');
                }
            }

            .icon--right {
                left: map-deep-get($type, "default", 'icon-size-softpage') + 0.4em;
                margin-left: - map-deep-get($type, "default", 'icon-size-softpage');
                //padding-right: 1.8em;
            }

            .icon--left {
                right: map-deep-get($type, "default", 'icon-size-softpage') + 0.4em;
                margin-right: - map-deep-get($type, "default", 'icon-size-softpage');
                //padding-left: 1.8em;
            }

            &.link--icon-right {
                padding-right: 1.6em;
            }

            &.link--icon-left {
                padding-left: 1.6em;
            }
        }



        &.link--arrow-external {
            .icon {
               height: 0;
                width: map-deep-get($type, "default", 'icon-size-external');
                align-items: center;
                svg {
                    height: map-deep-get($type, "default", 'icon-size-external');
                }
            }
            .icon--right {
                left:  map-deep-get($type, "default", 'icon-size-external') + 0.5em;
                margin-left: -  map-deep-get($type, "default", 'icon-size-external');
            }
            .icon--left {
                right:  map-deep-get($type, "default", 'icon-size-external') + 0.5em;
                margin-right: -  map-deep-get($type, "default", 'icon-size-external');
            }

            &.link--icon-right {
                padding-right: 1.5em;
            }

            &.link--icon-left {
                padding-left: 1.5em;

            }
        }

        @if map-deep-get($type, "default", 'container') == 'mask' {

            transition: all $global-color-transition-duration $global-color-timing-function;

            .link__inner {
                transition-property: box-shadow, margin, padding;
                transition-duration: $global-color-transition-duration;
                transition-timing-function: $global-color-timing-function;
                box-shadow: inset 0 -0.1em 0 map-deep-get($type, "default", 'border-color');
                -webkit-box-decoration-break: clone;

                padding-bottom: 0.1em;
                padding-top: 0.2em;
            }
        }
        @if map-deep-get($type, "default", 'font-crop') {
            display: flex;

            .link__inner {
                display: inline-flex;
                align-items: center;
            }
        }

        .icon svg path{
            fill: map-deep-get($type, "default", 'icon-color');
        }

        .icon svg {
            will-change: transform;
            transition-property: fill, transform;
            transition-duration: .3s;
            transition-timing-function: ease-in-out;
            height: 100%;
            width: 100%;
        }
        .icon svg path{
            will-change: fill;
            transition-property: fill;
            transition-duration: .3s;
            transition-timing-function: ease-in-out;
        }

        //.teaser:hover &,
        //.teaser:focus &,
        &:hover,
        &:focus {
            color: map-deep-get($type, "hovered", 'label-color');

            @if map-deep-get($type, "hovered", 'container') == 'mask' {

                .link__inner {
                    box-shadow: inset 0 -1.35em 0 map-deep-get($type, "hovered", 'border-color');
                    margin-left: -0.2em;
                    margin-right: -0.2em;
                    padding-right: 0.2em;
                    padding-left: 0.2em;
                }

                &.link--softpage {
                    padding-left: 0;

                    .link__inner {
                        margin-left: 0;
                        padding-left: 0;

                    }
                    .icon--left {
                        right: 0;
                        margin-right: 0.4em;
                        margin-left: 0.2em;
                    }
                }
                &.link--arrow-external {
                    padding-right: 0;

                    .icon--right {
                        left: 0;
                        margin-left: 0.5em;
                        margin-right: 0.3em;
                    }
                }
            }

            .icon svg path{
                fill: map-deep-get($type, "hovered", 'icon-color');
            }
        }

        .plugin--inverted & {
            color: map-deep-get($type, "default", 'inverted', 'label-color');


            @if map-deep-get($type, "default", 'inverted', 'container') == 'mask' {
                transition: all $global-color-transition-duration $global-color-timing-function;


                .link__inner {
                    transition-property: box-shadow, margin, padding;
                    transition-duration: $global-color-transition-duration;
                    transition-timing-function: $global-color-timing-function;
                    box-shadow: inset 0 -0.1em 0 map-deep-get($type, "default", 'inverted', 'border-color');
                    -webkit-box-decoration-break: clone;

                    padding-bottom: 0.1em;
                    padding-top: 0.2em;
                }
            }

            .icon svg path{
                fill: map-deep-get($type, "default", 'inverted', 'icon-color');
                will-change: transform;
                transition-property: fill, transform;
                transition-duration: .3s;
                transition-timing-function: ease-in-out;
            }

            &:hover,
            &:focus,
            .teaser:hover &,
            .teaser:focus & {
                color: map-deep-get($type, "hovered", 'inverted', 'label-color');

                @if map-deep-get($type,'hovered', 'inverted', 'container') == 'mask' {
                    .link__inner {
                        box-shadow: inset 0 -1.3em 0 map-deep-get($type, "hovered", 'inverted', 'border-color');
                        margin-left: -0.2em;
                        margin-right: -0.2em;
                        padding-right: 0.2em;
                        padding-left: 0.2em;
                    }
                }

                .icon svg path{
                 fill:  map-deep-get($type, "hovered", 'inverted', 'icon-color');
                }
            }
        }
    }
}


// buttons

$button-variations: (
    // Default Button
    'default': (
        'default': (
            'label-color':                   $brand-white,
            'icon-color':                    $brand-white,
            'background-color':              $brand-primary-color-50,
            'border-color':                  transparent,

            'inverted': (
                'label-color':                   $brand-white,
                'icon-color':                    $brand-white,
                'background-color':              $brand-primary-color-50,
                'border-color':                  transparent,
            )
        ),
        'hovered': (
            'label-color':                   $brand-white,
            'icon-color':                    $brand-white,
            'background-color':              $brand-primary-color-40,
            'border-color':                  transparent,

            'inverted': (
                'label-color':                   $brand-white,
                'icon-color':                    $brand-white,
                'background-color':              $brand-primary-color-40,
                'border-color':                  transparent,
            )
        ),
    ),


    // transparent
    'transparent': (
      'default': (
            'label-color':                   $body-color,
            'icon-color':                    $body-color,
            'background-color':              $brand-gray-85,
            'border-color':                  transparent,

            'inverted': (
                'label-color':                   $body-color,
                'icon-color':                    $body-color,
                'background-color':              $brand-gray-85,
                'border-color':                  transparent,
            )
        ),

        'hovered': (
            'label-color':                   $body-color,
            'icon-color':                    $body-color,
            'background-color':              $brand-gray-93,
            'border-color':                  transparent,

            'inverted': (
                'label-color':                   $body-color,
                'icon-color':                    $body-color,
                'background-color':              $brand-gray-93,
                'border-color':                  transparent,
            )
        ),
    ),

    // Primary Button (Outline)
    'primary': (
        'default': (
            'label-color':                   $body-color,
            'icon-color':                    $body-color,
            'background-color':              transparent,
            'border-color':                  $body-color,

            'inverted': (
                'label-color':                   $brand-white,
                'icon-color':                    $brand-white,
                'background-color':              transparent,
                'border-color':                  $brand-white,
            )
        ),

        'hovered': (
            'label-color':                   $body-color,
            'icon-color':                    $body-color,
            'background-color':              $brand-gray-93,
            'border-color':                  $body-color,

            'inverted': (
                'label-color':                   $brand-white,
                'icon-color':                    $brand-white,
                'background-color':              rgba(255, 255, 255, 0.1),
                'border-color':                  $brand-white,
            )
        ),
    ),
);



@each $link, $type in $button-variations {
    .button--#{$link} {
        color: map-deep-get($type, "default", 'label-color');
        background: map-deep-get($type, "default", 'background-color');
        outline: map-deep-get($type, "default", 'border-color') solid 1px;

        // FIXME
        &.button--default {
            .teaser--background &, .plugin--semi-inverted &, .hero & {
                outline: none;
            }
        }


        .icon svg {
            will-change: transform;
            transition-property: transform;
            transition-duration: .3s;
            transition-timing-function: ease-in-out;
        }


        .button-mask {
            background:  map-deep-get($type, "hovered", 'background-color');
            //border: 1px solid map-deep-get($type, "hovered", 'border-color');

            .icon svg path{
                fill: map-deep-get($type, "hovered", 'icon-color');
            }
        }

        .button-mask__label {
            color:  map-deep-get($type, "hovered", 'label-color');
        }


        .icon svg path{
            fill: map-deep-get($type, "default", 'icon-color');
        }

        .plugin--inverted & {
            color: map-deep-get($type, "default", 'inverted', 'label-color');
            background:  map-deep-get($type, "default", 'inverted', 'background-color');
            outline: map-deep-get($type, "default", 'inverted', 'border-color') solid 1px;

            .button-mask {
                background:  map-deep-get($type, "hovered", 'inverted', 'background-color');
                .icon svg path{
                    fill: map-deep-get($type, "hovered", 'inverted', 'icon-color');
                }
            }

            .button-mask__label {
                color:  map-deep-get($type, "hovered", 'inverted', 'label-color');
            }

            .icon svg path{
                fill: map-deep-get($type, "default", 'inverted', 'icon-color');
            }
        }
    }
}



/* Buttons */

.button-list {
    display: flex;
    align-items: center;
    align-content: center;
    align-self: center;
    flex-wrap: wrap;
    @include spacings-size('y-scale-5', margin-top);
    margin-bottom: 0;
    width: 100%;

    @include breakpoint(medium) {
        width: auto;
    }

    .footer-details & {
        margin-top: 0;
    }

    &.menu-large,
    &.menu-small {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: flex-start;
        flex-wrap: nowrap;
        margin: 0;
        width: auto;
    }

    &.button-list--left {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: flex-start;
    }

    &.button-list--center {
        justify-content: center;
    }


    li {
        &:not(:last-child) {
            @include spacings-size('y-equal-3', margin-right);
        }
    }

    &.no-spacing {
        &,
        li {
            margin: 0;
        }
    }

    &.meta-small {
        margin: 0;
        width: auto;
        flex-direction: column;
        flex-wrap: nowrap;

        @include breakpoint(large) {
            flex-wrap: wrap;
            flex-direction: row;
        }
    }

    &.unstyled-links-vertical {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;

        li {
            @include spacings-size('y-equal-3', margin-bottom);
        }
    }

}

.button-list__item {

    &.button-list__item--small {
        width: auto;
    }

    @include breakpoint(medium) {
        width: auto;
    }

    .menu-large & {
        @include spacings-size('y-scale-3', margin-bottom);

        &:last-child {
            margin-bottom: 0;
        }

        * {
            @extend .text--h2;
        }
    }

    .menu-small & {
        @include spacings-size('y-scale-4', margin-bottom);

        &:last-child {
            margin-bottom: 0;
        }

        * {
            @extend .text--h4;
        }
    }

    .meta-small & {
        @include breakpoint(medium down) {
            //text-align: center;
            margin-right: 0 !important;
            @include spacings-size('y-equal-5', margin-bottom);
        }

        * {
            @extend .text--small-2;
            font-family: $font-family-primary;
            color: $brand-gray-60;
            transition-property: $link-transition-property;
            transition-duration: $link-transition-duration;
            transition-timing-function: $link-transition-timing-function;

            &:hover,
            &:focus {
                color: $brand-gray-24;
            }
        }

        @include breakpoint(small only) {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include breakpoint(medium) {
        margin-bottom: 0;
    }
}

.file-links {
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;

    li {
        width: 100%;
        margin: 0 !important;

        &:last-of-type {
            border-bottom: 1px solid $brand-gray-80;
        }
    }
}

.file-link {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid $brand-gray-80;
    transition: color $global-color-transition-duration
        $global-color-timing-function;

    .icon {
        height: to-rem(19px);
        width: to-rem(12px);

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &:hover,
    &:focus {
        color: $brand-gray-60;
    }
}

.button,
button,
input[type='submit'] {
    text-align: center;
    justify-content: center;
    @include fluid-size(
        (
            min: $button-width,
            max: $button-width,
        ),
        'min-width'
    );
    padding: $button-padding / 2 $button-padding * 2;
    min-height: to-rem(50px);
    transition: color 0.4s ease;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    text-transform: $font-btn-default-text-transform;
    letter-spacing: $font-btn-large-letter-spacing;
    font-family:$font-btn-default-font-family;
    @include font-size($font-btn-large-font-sizes);

    .text-container & {
        width: fit-content;
       @include spacings-size(y-scale-5, margin-top);
    }

    &.button--large {
    text-transform: $font-btn-large-text-transform;
    font-family:$font-btn-large-font-family;

        @include fluid-size(
                (
                    min: 240px,
                    max: 240px,
                ),
                'min-width'
        );
    }

    &.button--normal {
        @include fluid-size(
                (
                    min: 240px,
                    max: 240px,
                ),
                'min-width'
        );
    }

    &.button--small {
        text-transform: $font-btn-small-text-transform;
        letter-spacing: $font-btn-small-letter-spacing;
        font-family:$font-btn-small-font-family;
        @include font-size($font-btn-small-font-sizes);
        min-height: to-rem(40px);
        @include fluid-size(
            (
                min: $button-small-width,
                max: $button-small-width,
            ),
            'min-width'
        );
        padding: $button-padding / 2 $button-padding * 2;
    }

    &:hover .button-mask__label,
    &:hover .button-mask,
    &:focus .button-mask__label,
    &:focus .button-mask {
        transform: translate(0);
    }

    &.button--transparent {
        &:hover .button-mask,
        &:focus .button-mask {
            transform: translateY(-100%);
        }
        &:hover .button-mask__label,
        &:focus .button-mask__label {
            transform: translateY(100%);
        }
    }
    .content-plugin & {
        @include breakpoint(small only) {
            width: 100%;
        }
    }

}

.link--softpage,
.button--softpage {
    &:hover,
    &:focus {
        .icon svg {
            transform: rotate(90deg);
        }
    }
}

//.teaser {
//    &:hover,
//    &:focus {
//        .link--softpage,
//        .button--softpage {
//            .icon svg {
//                transform: rotate(90deg);
//            }
//        }
//    }
//}

.link--arrow-external,
.button--arrow-external {
    &:hover,
    &:focus {
        .icon svg {
            transform: rotate(45deg);
        }
    }
}
//
//.teaser {
//    &:hover,
//    &:focus {
//        .link--arrow-external,
//        .button--arrow-external {
//            .icon svg {
//                transform: rotate(45deg);
//            }
//        }
//    }
//}

.button-label {
    .link--plugin & {
        @include line-height-crop(
            map-get($font-btn-default-crop, 'top'),
            map-get($font-btn-default-crop, 'bottom'),
            nth(map-get($font-btn-large-font-sizes, 'min'), 2)
        );
    }

    .button & {
        @include line-height-crop(
            map-get($font-btn-default-crop, 'top'),
            map-get($font-btn-default-crop, 'bottom'),
            nth(map-get($font-btn-large-font-sizes, 'min'), 2)
        );
    }

    .button--small & {
        @include line-height-crop(
            map-get($font-btn-default-crop, 'top'),
            map-get($font-btn-default-crop, 'bottom'),
            nth(map-get($font-btn-small-font-sizes, 'min'), 2)
        );
    }

    /*@media all and (min--moz-device-pixel-ratio: 0) {
        .link--plugin & {
            @include line-height-crop(
                    map-get($font-capital-fx-letter-secondary, 'top'),
                    map-get($font-capital-fx-letter-secondary, 'bottom'),
                    nth(map-get($font-btn-large-font-sizes, 'min'), 2)
            );
        }

        .button & {
            @include line-height-crop(
                    map-get($font-capital-fx-letter-secondary, 'top'),
                    map-get($font-capital-fx-letter-secondary, 'bottom'),
                    nth(map-get($font-btn-large-font-sizes, 'min'), 2)
            );
        }

        .button--small & {
            @include line-height-crop(
                    map-get($font-capital-fx-letter-secondary, 'top'),
                    map-get($font-capital-fx-letter-secondary, 'bottom'),
                    nth(map-get($font-btn-small-font-sizes, 'min'), 2)
            );
        }
    }*/

    .button--icon-left & {
        order: 2;
    }

    .button--icon-right & {
        order: 1;
    }


    @include breakpoint(small only) {
        .button & {
            white-space: nowrap;
        }
    }
}

.icon {
    .button--icon-left & {
        order: 1;
    }

    .button--icon-right & {
        order: 2;
    }
}

.button-mask {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    transition: transform 0.4s ease;
    overflow: hidden;
    z-index: 1;

    .softpage-visible & {
        will-change: transform;
    }

    .button--transparent & {
        transform: translateY(0);
    }
}

.button-mask__label {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-100%);
    transition: transform 0.4s ease;
    padding: 0.5rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: $font-btn-large-letter-spacing;
    @include font-size($font-btn-large-font-sizes);

    .softpage-visible & {
        will-change: transform;
    }

    .button--small & {
        letter-spacing: $font-btn-default-letter-spacing;
       @include font-size($font-btn-small-font-sizes);
    }

    .button--tiny & {
        letter-spacing: $font-btn-small-letter-spacing;
        @include font-size($font-btn-small-font-sizes);
    }

    &:before {
        position: relative;
        content: attr(data-mask-label);
        order: 2;
        margin-top: calc(
            (
                    #{map-get($font-btn-default-crop, 'top')} - #{nth(
                            map-get($font-btn-large-font-sizes, 'min'),
                            2
                        )}
                ) * 0.5em
        );
        margin-bottom: calc(
            (
                    #{map-get($font-btn-default-crop, 'bottom')} - #{nth(
                            map-get($font-btn-large-font-sizes, 'min'),
                            2
                        )}
                ) * 0.5em
        );

        .button--small & {
            margin-top: calc(
                (
                        #{map-get($font-btn-default-crop, 'top')} - #{nth(
                                map-get($font-btn-default-font-sizes, 'min'),
                                2
                            )}
                    ) * 0.5em
            );
            margin-bottom: calc(
                (
                        #{map-get($font-btn-default-crop, 'bottom')} - #{nth(
                                map-get($font-btn-default-font-sizes, 'min'),
                                2
                            )}
                    ) * 0.5em
            );
        }
    }
 /*@media all and (min--moz-device-pixel-ratio: 0) {

    &:before {
        position: relative;
        content: attr(data-mask-label);
        order: 2;
        margin-top: calc(
            (
                    #{map-get($font-capital-fx-letter-secondary, 'top')} - #{nth(
                            map-get($font-btn-large-font-sizes, 'min'),
                            2
                        )}
                ) * 0.5em
        );
        margin-bottom: calc(
            (
                    #{map-get($font-capital-fx-letter-secondary, 'bottom')} - #{nth(
                            map-get($font-btn-large-font-sizes, 'min'),
                            2
                        )}
                ) * 0.5em
        );

        .button--small & {
            margin-top: calc(
                (
                        #{map-get($font-capital-fx-letter-secondary, 'top')} - #{nth(
                                map-get($font-btn-default-font-sizes, 'min'),
                                2
                            )}
                    ) * 0.5em
            );
            margin-bottom: calc(
                (
                        #{map-get($font-capital-fx-letter-secondary, 'bottom')} - #{nth(
                                map-get($font-btn-default-font-sizes, 'min'),
                                2
                            )}
                    ) * 0.5em
            );
        }
        }
    }*/

    .button--transparent & {
        transform: translateY(0);
    }
}

.link--disabled {
}


.button--form {
    border: none;
    cursor: pointer;
    margin: 0 auto;
}

.teaser {
    p a {
        color: $brand-gray-60;
        transition-property: $link-transition-property;
        transition-duration: $link-transition-duration;
        transition-timing-function: $link-transition-timing-function;

        &:hover,
        &:focus {
            color: $brand-gray-40;
        }
    }
}
