.copyright {
    display: flex;
    justify-content: center;
    @include breakpoint(medium) {
        justify-content: flex-start;
    }
}

.footer {
    @include spacings-size('y-section-1', margin-top);

    .footer-spacing-half &{
        @include spacings-size('y-section-3', margin-top);
    }

    .tpl-products & {
        @include spacings-size('y-scale-2', margin-top);
    }
    .footer-spacing-zero & {
        margin-top: 0;
    }


}
