
// Feedback

$brand-green:                #12BB06;
$brand-red:                  #ee2323;
$brand-yellow:               #FFFFF4;
$brand-blue:                 #5bc0de;
$brand-orange:               #f0ad4e;

$color-success:              $brand-green;
$color-danger:               $brand-red;
$color-info:                 $brand-blue;
$color-warning:              $brand-orange;


$color-info-light:           lighten($color-info, 10%);
$color-info-dark:            darken($color-info, 10%);

$color-warning-light:        lighten($color-warning, 10%);
$color-warning-dark:         darken($color-warning, 10%);

$color-danger-light:         lighten($color-danger, 10%);
$color-danger-dark:          darken($color-danger, 10%);

$color-success-light:        lighten($color-success, 10%);
$color-success-dark:         darken($color-success, 10%);
