.gallery-plugin {
    position: relative;
}

.gallery-slide {
    &__image-container {
        &--default {
            display: block;

            .swiper-fullscreen-container & {
                display: none;
            }
        }

        &--fullscreen {
            display: none;

            .swiper-fullscreen-container & {
                display: block;
            }
        }
    }
}

// Variables
$swiper-content-container-color: $white !default;
$swiper-button-fullscreen-close-color: $white !default;
$swiper-button-fullscreen-close-color-hover: $white !default;
$gallery-plugin-slide-overlay-styles-enabled: true;
$gallery-plugin-slide-overlay-gradient-height: 66%;
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Gallery Plugin

*/

.gallery-plugin {
    // when counter is enabled, hide bullet pagination
    &.counter-enabled {
        .swiper-pagination {
            display: none;
        }
    }
}

.gallery-slide {
    &____image-container {
        position: relative;
        z-index: 1;
    }

    &__content-container {
        position: absolute;

        @include content-section-spacing-position(bottom);
        @include content-section-spacing-position(left);
        @include content-section-spacing-position(right);

        z-index: 10;

        @include media-breakpoint-up(lg) {
            max-width: 66.66%;
        }
    }

    &__heading {
        @include font-h2();
    }

    @if $gallery-plugin-slide-overlay-styles-enabled == true {
        &__overlay {
            // modifier class for a swiper instance (set on container)
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 5;

            @include gradient-overlay(
                $gallery-plugin-slide-overlay-gradient-height
            );
        }

        &__heading {
            color: $overlay-heading-color;
        }

        &__text-container {
            > * {
                color: $overlay-text-color;
            }
        }
    }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Fullscreen Gallery

*/

.swiper-button-fullscreen {
    float: right;
    // hide within fullscreen container
    .swiper-fullscreen-container & {
        display: none;
    }
}

.swiper-fullscreen-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transition: all $site-overlay-transition-duration
        $site-overlay-transition-timing-function;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: z('fullscreen-gallery');

    .swiper-fullscreen {
        flex-shrink: 1;
        align-self: center;
        justify-content: center;
        width: 100%;
        margin: auto;
    }

    &.active {
        opacity: 1;
        visibility: visible;
    }

    .cms-toolbar-expanded & {
        // top: $grid-gutter-width + $cms-toolbar-height-expanded;
    }
    .swiper-content-container,
    .swiper-counter {
        position: relative;
        color: $swiper-content-container-color;

        * {
            color: $swiper-content-container-color;
        }
    }

    .swiper-content-container {
        opacity: 0;
    }

    .swiper-slide {
        background-size: cover;
    }

    .swiper-slide-active {
        .swiper-content-container {
            opacity: 1;
        }
    }
}

.swiper-button-fullscreen-close {
    &.tingle-modal__close {
        &::before {
            color: $swiper-button-fullscreen-close-color;
        }

        &:hover,
        &:focus {
            &::before {
                color: $swiper-button-fullscreen-close-color-hover;
            }
        }
    }
}
